
/**
 * Automatic loader for EVERYTHING (vue files) in the private view folder
 * Home.vue defaults to '/'
 * Include a custom function certaintyMenu(link){} in the vue files to populate the sidebar
 * 
 */

const context = require.context('../views/PrivateRoutes', true, /\.(vue)$/);
const files={};

context.keys().forEach((filename)=>{
  files[filename] = context(filename);
});

const output = [];

for(let fn in files){
    let filename = fn.substring(2,fn.length - 4);
    let path

    // skip anything with :id
    if(filename.indexOf(":") >= 0) { continue; }

    switch(filename){
        case 'Home':
            path = '/'
            break;
        default:
            path = '/'+filename.toLowerCase();
    }

    output.push({
        path,
        name:files[fn].default.name||filename,
        component: files[fn].default
    })
}

// push everything with :id
for(let fn in files){
    let filename = fn.substring(2,fn.length - 4);
    
    if(filename.indexOf(":") < 0) { continue; }

    let path = '/'+filename.toLowerCase();

    output.push({
        path,
        name:files[fn].default.name||filename,
        component: files[fn].default
    })
}

export default output