<template lang="pug">
  div
    div.d-flex.justify-content-between.align-items-end.flex-wrap.mb-3
        h4 Audit Log
        b-form(inline)
            b-form.ml-3(inline)
                label.mr-1 Filter by:
                b-form-select(v-model="filterBy" @change="updateTable()")
                    b-form-select-option(:value="0") None
                    b-form-select-option(:value="1") Month
                    b-form-select-option(:value="2") Date

                b-form-input(v-if="filterBy === 1" type="month" pattern="[0-9]{4}-[0-9]{2}" v-model="filterByMonth" @change="updateTable()")
                b-form-datepicker(v-if="filterBy === 2" v-model="filterByDate" @input="updateTable()")
    
    div.d-flex.justify-content-center.align-items-center(v-if="loading" style="min-height:10em")
        b-spinner
    div(v-else)
        b-overlay(:show="loadingTable" variant="white")
            b-table.w-100(:items="fetchedAuditLogs" :fields="fields" ref="table" :per-page="perPage" :current-page="currentPage" responsive sort-by="date_created" sort-desc show-empty)
                template(#cell(user)="data")
                    div(v-if="data.item.user")
                        div {{data.item.user.graph_display_name}}
                        div {{data.item.user.graph_user_principal_name}}
            
            b-pagination.m-0(
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
            )
</template>

<script>
import dayjs from 'dayjs';

export default {
    name: 'AuditLogs',
    data() {
        return {
            loading: false,
            loadingTable: true,
            totalRows: 0,
            currentPage: 1,
            perPage: 20,
            filterBy: 0,
            filterByMonth: dayjs().format('YYYY-MM'),
            filterByDate: dayjs().format('YYYY-MM-DD'),
            fields:[
                { key: 'date_created', name: 'date_created', sortable: true },
                { key: 'url', name: 'url', sortable: false },
                { key: 'method', name: 'method', sortable: true },
                { key: 'status', name: 'status', sortable: true },
                { key: 'user', name: 'user_id', sortable: false },
                { key: 'payload', name: 'payload', sortable: false },
            ]
        }
    },
    computed: {
        user(){
            return this.$store.state.user;
        },
        filterStartDateTime() {
            // filter by month
            if(this.filterBy === 1) {
                return this.filterByMonth ? this.filterByMonth+"-01T00:00:00" : null;
            }
            // filter by date
            if(this.filterBy === 2) {
                return this.filterByDate ? this.filterByDate+"T00:00:00" : null;
            }

            return null;
        },
        filterEndDateTime() {
            // filter by month
            if(this.filterBy === 1) {
                if(!this.filterByMonth) { return null; }
                let addMonth = dayjs(this.filterByMonth+"-01T23:59:00").add(1, 'month');
                return addMonth.subtract(1, 'day').format("YYYY-MM-DDTHH:mm:ss"); // return last day of the month
            }
            // filter by date
            if(this.filterBy === 2) {
                return this.filterByDate ? this.filterByDate+"T23:59:00" : null;
            }

            return null;
        },
    },
    async mounted() {
    },
    methods: {
        dayjs,
        updateTable() {
            if(this.loading) { return; }
            this.$refs.table.refresh();
            this.currentPage = 1;
        },
        async fetchedAuditLogs(ctx) {
            this.loadingTable = true;

            // prepare url params
            let params = {
                top: ctx.perPage,
                skip: ctx.perPage*(ctx.currentPage-1),
                orderby: ctx.sortBy,
                order: ctx.sortDesc ? 'desc' : 'asc',
            };
            // filter by date/month
            if(this.filterStartDateTime && this.filterEndDateTime) {
                params = {
                    ...params,
                    start: this.filterStartDateTime,
                    end: this.filterEndDateTime
                }
            }

            console.log(params);

            // request
            let items = await this.$store.dispatch("fetchAuditLogs",params).then((result)=>{
                this.totalRows = result.count;
                return result.value;
            }).catch(()=>{
                return [];
            });

            this.loadingTable = false;

            return items;
        },
    }
}
</script>

<style>

</style>