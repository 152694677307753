<template lang="pug">
div
    b-checkbox(v-model="testmode" switch) Test mode
    b-modal(title="Assign Followup Task" v-model="assign_task_show" hide-footer no-close-on-backdrop)
        assign_task(:id="assign_task_case_id" @close="assign_task_show=false" @add_task="add_task($event)" )
    b-modal(title="Update ACP Date" v-model="update_acp_show" hide-footer no-close-on-backdrop)
        update_acp(:id="update_acp_show_id" @close="update_acp_show=false" @update_acp="update_acp($event)" :current_acp="update_acp_current")
    b-modal(title="Update Notes" v-model="update_notes_show" hide-footer no-close-on-backdrop)
        update_notes(:id="update_notes_show_id" @close="update_notes_show=false" @update_notes="update_notes($event)" :current_notes="update_notes_current")

    b-overlay(:show="loading")
      hr
      template(v-if="!individual")
          template(v-if="centreList.length > 0")
              b-select(:options="centreList" v-model="selectedCentre")
          hr
          b-checkbox(v-model="limitByCaseWorker") Limit displayed count to the selected case workers
          b-checkbox(v-model="groupByCaseWorker") Show breakdown by case worker
          hr
      b-card-group(deck)
          b-card.text-center
              h2 {{centre_cases.length}}
              p Cases total
              b-button(variant="dark" size="sm" block v-if="mode.all" ) Showing all cases
              b-button(variant="outline-dark" size="sm" block v-else @click="resetmode") Reset Filters
              template(v-if="!individual")
                  hr
                  p.font-weight-bold Filter by Case Worker :
                  //Check All box
                  b-checkbox-group(:options="caseworkers"  v-model="selectedCaseworkers" stacked)

          template(v-if="!groupByCaseWorker")
              b-card.text-center
                  h2 {{flagCount}}
                  p Flagged Cases
                  b-button(variant="dark" size="sm" block v-if="mode.flagged" @click="setMode('flagged')") Showing only flagged cases
                  b-button(variant="outline-dark" size="sm" block v-else @click="setMode('flagged')") Filter by flagged cases

                  hr
                  h2 {{vulCount}}
                  p Cases with Vulnerable Individuals
                  b-button(variant="dark" size="sm" block v-if="mode.vulnerable" @click="setMode('vulnerable')") Showing only vulnerable cases
                  b-button(variant="outline-dark" size="sm" block v-else @click="setMode('vulnerable')") Filter by vulnerable cases
              b-card.text-center
                  h2 {{overdueAcpCount}}
                  p Cases with overdue ACP / Reviews
                  b-button(variant="dark" size="sm" block v-if="mode.acpOverdue" @click="setMode('acpOverdue')") Showing only overdue ACP/reviews
                  b-button(variant="outline-dark" size="sm" block v-else @click="setMode('acpOverdue')") Filter by overdue ACP/reviews
                  hr
                  p.font-weight-bold Breakdown by Month<br/>(Next ACP Due) :
                  b-checkbox-group(:options="overdueAcpFilterList"  v-model="overdueAcpFilter" stacked)

              b-card.text-center
                  h2 {{overdueEngageCount}}
                  p Cases with overdue Sessions
                  b-button(variant="dark" size="sm" block v-if="mode.engageOverdue" @click="setMode('engageOverdue')") Showing only overdue sessions
                  b-button(variant="outline-dark" size="sm" block v-else @click="setMode('engageOverdue')") Filter by overdue sessions
                  hr
                  p.font-weight-bold Breakdown by Month<br/>(Next Session Due):
                  b-checkbox-group(:options="overdueEngageFilterList"  v-model="overdueEngageFilter" stacked)
              b-card.text-center
                  h2 {{overdueTaskCount}}
                  p Cases with overdue Tasks
                  b-button(variant="dark" size="sm" block v-if="mode.taskOverdue" @click="setMode('taskOverdue')") Showing only overdue tasks
                  b-button(variant="outline-dark" size="sm" block v-else @click="setMode('taskOverdue')") Filter by overdue tasks
                  hr
                  p.font-weight-bold Breakdown by Month<br/>(Tasks Due) :
                  b-checkbox-group(:options="overdueTaskFilterList"  v-model="overdueTaskFilter" stacked)
          b-card.text-center.w-75(v-else style="flex:4 0 0%")
              h3 Breakdown by Case Worker
              b-table-lite(:items="caseworker_breakdowns" :fields="caseworker_fields")              
      
      b-card.mt-3(v-if="groupByCaseWorker")
          .d-flex.align-items-center
              .label.font-weight-bold Filters:
              b-button.ml-3(size="sm" variant="dark"   v-if="mode.flagged" @click="setMode('flagged')") Showing only flagged cases
              b-button.ml-3(size="sm" variant="outline-dark"   v-else @click="setMode('flagged')") Filter by flagged cases
              b-button.ml-3(size="sm" variant="dark"  v-if="mode.vulnerable" @click="setMode('vulnerable')") Showing only vulnerable cases
              b-button.ml-3(size="sm" variant="outline-dark"  v-else @click="setMode('vulnerable')") Filter by vulnerable cases
              b-button.ml-3(size="sm" variant="dark"  v-if="mode.acpOverdue" @click="setMode('acpOverdue')") Showing only overdue ACP/reviews
              b-button.ml-3(size="sm" variant="outline-dark"  v-else @click="setMode('acpOverdue')") Filter by overdue ACP/reviews
              b-button.ml-3(size="sm" variant="dark"  v-if="mode.engageOverdue" @click="setMode('engageOverdue')") Showing only overdue session
              b-button.ml-3(size="sm" variant="outline-dark"  v-else @click="setMode('engageOverdue')") Filter by overdue sessions
              b-button.ml-3(size="sm" variant="dark"  v-if="mode.taskOverdue" @click="setMode('taskOverdue')") Showing only overdue tasks
              b-button.ml-3(size="sm" variant="outline-dark"  v-else @click="setMode('taskOverdue')") Filter by overdue tasks
      b-table(:items="centre_cases_filtered" :current-page="currentPage" :per-page="perPage" :fields="fields")
          template(#cell(caseInformation)="{item}")
              code {{item.OTP}}
              .small Case Worker: {{item.caseWorker.name}}
              .small Notes: {{item.notes}}
          template(#cell(lastContact)="{item}")

              .small.font-weight-bold Case Opened on {{item.caseOpened.format("DD-MMM-YYYY")}}
              hr.my-1
              .small(:class="item.lastACP.add(180,`day`).isAfter(today) ? 'text-dark':'text-danger font-weight-bold'") Next ACP due: {{item.lastACP.add(180,"day").format("DD-MMM-YYYY")}}
              .small(v-if="item.noACP") (Last ACP: N/A)
              .small(v-else) (Last ACP: {{item.lastACP.format("DD-MMM-YYYY")}})
              
              hr.my-1
              .small(:class="item.lastEnagement.add(2,`month`).isAfter(today) ? 'text-dark':'text-danger font-weight-bold '") Next Engagement due: {{item.lastEnagement.add(2,"month").format("DD-MMM-YYYY")}}
              .small(v-if="item.noEngagement") (Last Session: N/A)
              .small(v-else) (Last Session: {{item.lastEnagement.format("DD-MMM-YYYY")}})
          
          template(#cell(followupTasks)="{item}")
              div(v-for="(task,tdex) in item.tasks")
                  .d-flex
                      b-button.rounded-circle.p-1(size="sm" :variant="task.dateCompleted? `success`:`outline-success`" @click="mark_done(item,tdex)") 
                          .d-flex.align-items-center.justify-content-center(style="width:28px;height:28px;")
                              b-icon-check
                      b-button.rounded-circle.p-1(size="sm" :variant="task.dateCompleted? `danger`:`outline-danger`" @click="mark_done(item,tdex)") 
                          .d-flex.align-items-center.justify-content-center(style="width:28px;height:28px;")
                              b-icon-x
                      .flex-grow-1.ml-3
                          // Task remarks  (Tick + cross (Done/Not Done)) + edit button
                          .small {{task.title}}
                          .small( v-if="!task.dateCompleted" :class="(task.due.isAfter(today))? 'text-dark':'text-danger font-weight-bold '") (Due: {{task.due.format("DD-MMM-YYYY")}})
                          .small.text-success(v-else) (Completed: {{dayjs(task.dateCompleted).format("DD-MMM-YYYY")}})
                      b-button.rounded-circle.p-1(size="sm" variant="light" @click="mark_delete(item,tdex)") 
                          .d-flex.align-items-center.justify-content-center(style="width:28px;height:28px;")
                              b-icon-pencil
                  hr
              b-button(block size="sm" variant="light") + Task

          template(#cell(tags)="{item}")
              div
                  b-badge.mx-1(variant="info" v-if="item.hasVulnerable") Vulnerable
                  b-badge.mx-1(variant="warning" v-if="item.isFlagged") Flagged

          template(#cell(actions)="{item}")
              .d-flex.flex-column
                  b-button.mb-1(size="sm" @click="assign_task(item.id)") + Task
                  b-button.mb-1(size="sm" @click="trigger_update_acp(item.id,item.lastACP)") ACP Date
                  b-button.mb-1(size="sm" @click="trigger_update_notes(item.id,item.notes)") Supervision Notes
                  b-button.mb-1(size="sm" @click="update_flag(item,false)" v-if="item.isFlagged") Unflag
                  b-button.mb-1(size="sm" @click="update_flag(item,true)" v-else) Flag

                  b-button.mb-1(size="sm" @click="schedule_engagement(item.id)") + Appointment
                  b-button.mb-1(variant="dark" size="sm" @click="view_case(item.id)") Edit Case
                  b-button(variant="dark" size="sm" @click="view_case(item.id)") History

          .d-flex.justify-content-center
              b-pagination(:total-rows="rows" :per-page="perPage" v-model="currentPage")

          
</template>
<script>
import dayjs from "dayjs";
import assign_task from "./assign_task.vue";
import update_acp from "./update_acp.vue";
import update_notes from "./update_notes.vue";
export default {
  name: 'risk_monitoring',
  data(){
    return {
        assign_task_show:false,
        assign_task_case_id:null,
        update_acp_show:false,
        update_acp_show_id:null,
        update_acp_current:null,
        update_notes_show:false,
        update_notes_show_id:null,
        update_notes_current:null,
        today:dayjs(),
        testmode:false,
        loading: true,
        mode:{
            all:true,
            flagged:false,
            vulnerable:false,
            acpOverdue:false,
            engageOverdue:false,
            taskOverdue:false
        },
        limitByCaseWorker: true,
        groupByCaseWorker: false,
        currentPage:1,
        perPage:10,
        selectedCaseworkers:[],
        centre_cases:[],
        overdueAcpFilter:[],
        overdueEngageFilter:[],
        overdueTaskFilter:[],
    }
  },
  mounted(){
    this.testmode ? this.run_test_mode():this.run_live_mode();
  },
  components:{
    assign_task,update_acp,update_notes
  },
  props:{
        individual:{
            type:Boolean,
            default:false
        },
        supervisor:{
            type:Boolean,
            default:false
        },
        centreList:{
            type:Array,
            default:()=>[]
        },
        value:{
            type:Number,
            default: null
        }
    },
  methods:{
    dayjs,
    assign_task(id){
        this.assign_task_case_id = id
        this.assign_task_show =true;
    },
    add_task(payload){
        let id = this.assign_task_case_id;
        let cse = this.centre_cases.find(cs=>cs.id===id);
        cse.tasks.push(payload);
        this.assign_task_show =false;
        this.update_metadata(cse);
    },
    trigger_update_acp(id, current){
        this.update_acp_show_id = id
        this.update_acp_show = true;
        this.update_acp_current =current;
    },
    update_acp(date){
        let id = this.update_acp_show_id;
        let cse = this.centre_cases.find(cs=>cs.id===id);
        cse.lastACP = date;
        this.update_acp_show = false;
        this.update_metadata(cse);
    },
    trigger_update_notes(id, current){
        this.update_notes_show_id = id
        this.update_notes_show = true;
        this.update_notes_current =current;
    },
    update_flag(cse,v){
      cse.isFlagged = v;
      console.log(cse);
      this.update_metadata(cse);
    },
    update_notes(notes){
        let id = this.update_notes_show_id;
        let cse = this.centre_cases.find(cs=>cs.id===id);
        cse.notes = notes;
        this.update_notes_show = false;
        this.update_metadata(cse);
    },
    parse_metadata(cse){
      return {lastACP:cse.lastACP.format("YYYY-MM-DD"),isFlagged:cse.isFlagged,notes:cse.notes, tasks:cse.tasks}
    },
    update_metadata(cse){
      return this.$store.dispatch("patchClientGroup",
          {
            payload:{
              meta_data:this.parse_metadata(cse)
            }, 
            client_group_id:cse.id 
          }
        ).catch(()=>{
          alert("We encountered an error while saving. Please reload and check if changes were made.")
        })
    },
    schedule_engagement(id){
      if(this.testmode){
        alert("Since test mode is on, sessions cannot be scheduled for this client.\n\nIn Production mode, a new tab will be opened to create a appointment.")
      }else{
        window.open(`/appointments/create/?group=${id}`)
      }
    },
    view_case(id){
      if(this.testmode){
        alert("Since test mode is on, this client cannot be viewed.\n\nIn Production mode, a new tab will be opened to view the client.")
      }else{
        window.open(`/client-group/${id}/edit`)
      }
        
    },
    mark_done(item, tdex){
        if(item.tasks[tdex].dateCompleted){
            item.tasks[tdex].dateCompleted = null;
            return;
        }else{
          item.tasks[tdex].dateCompleted = dayjs().format("YYYY-MM-DD");
        }
        this.update_metadata(item);
    },
    mark_delete(item, tdex){
        let r = confirm("Confirm to remove task from list?");
        if(!r){
          return;
        }
        item.tasks.splice(tdex,1);
        this.update_metadata(item);
    },
    async run_test_mode(){
        this.loading = true;
        await new Promise(rs=>setTimeout(rs, 1000));
        let dummyCW = this.individual?[{id:1,name:"Tan Eu Beng"}]:[{id:1,name:"Tan Eu Beng"},{id:2,name:"Chen Li Lian"}, {id:3,name:"Shyarina Binti Yusof"},{id:5,name:"Muthuramen S/O Ganesh"}]
        this.centre_cases = this.generate_dummy_data(dummyCW);
        this.selectedCaseworkers =this.caseWorkerList.map(cw=>cw.id);
        this.loading = false;
    },
    async run_live_mode(){
        this.loading = true;
        this.centre_cases = [];
        let pl = this.supervisor ? {supervisor_group_id:this.selectedCentre}:{group_id:this.selectedCentre}; 
        let raw_cases = this.individual ? await this.$store.dispatch("fetchUserClientGroups") : await this.$store.dispatch("fetchAllClientGroups",pl);
        this.centre_cases = raw_cases.value.filter(c=>c.active===1).map(c=>{
            return {
              id:c.id,
              OTP: c.OTP||c.name,
              caseWorker:{id:c.user_id,name:c.owner},
              caseOpened:dayjs(c.date_open||c.date_created),
              noACP: !c.meta_data?.lastACP,
              noEngagement:!c.ta?.lastEnagement,
              lastACP:c.meta_data?.lastACP ? dayjs(c.meta_data?.lastACP) : dayjs(c.date_open||c.date_created).subtract(120,"days"),
              lastEnagement:c.ta?.lastEnagement ? dayjs(c.meta_data?.lastEnagement) : dayjs(c.date_open||c.date_created),
              hasVulnerable:c.ta?.hasVulnerable||false,
              isFlagged:c.meta_data?.isFlagged||false,
              tasks:c.meta_data?.tasks?.map(task=>{
                task.due = dayjs(task.due);
                task.dateCompleted = task.dateCompleted ? dayjs(task.dateCompleted) : null;
                return task;
              })||[],
              notes:c.meta_data?.notes||""
            }
        });
        this.selectedCaseworkers =this.caseWorkerList.map(cw=>cw.id);
        this.loading = false;
    },
    resetmode(){
      for(let m in this.mode){
        this.mode[m] = false;
      }
      this.overdueAcpFilter=[];
      this.overdueEngageFilter=[];
      this.overdueTaskFilter=[];
      this.selectedCaseworkers =this.caseWorkerList.map(cw=>cw.id);
      
      this.mode.all = true;
    },
    setMode(key){
      this.mode[key] = !this.mode[key];
      this.checkMode();
      
    },
    checkMode(){
      for(let m in this.mode){
        if(this.mode[m] && m!='all'){
           this.mode.all = false;
          return;
        }
      }
      if(
        this.overdueAcpFilter.length > 0||
        this.overdueEngageFilter.length > 0||
        this.overdueTaskFilter.length > 0
      ){
          this.mode.all = false;
          return;
      }
      if(this.selectedCaseworkers.length < this.caseWorkerList.length){
          this.mode.all = false;
          return;
      }
      this.mode.all = true;
    },
    generate_dummy_data(caseWorkerList){
      let caseData = {
            id:0,
            OTP:"OTP123456",
            caseWorker:"Tan Eu Bing",
            caseOpened:dayjs().subtract(1,"year"),
            lastACP:dayjs("01-Nov-2022"),
            lastEnagement:dayjs("15-Oct-2022"),
            hasVulnerable:true,
            isFlagged:true,
            tasks:[
                {
                    title:"Sample Task",
                    due: dayjs("01-Nov-2022"),
                    dateCompleted:null
                }
            ],
            notes:""
        };
      let output = [];
      output.length =  this.individual? 35: 150;
      return output.fill(caseData,0,output.length).map((r,id)=>{
        let output = JSON.parse(JSON.stringify(r));
        output.id =id+1;
        output.lastACP = dayjs().subtract(Math.ceil(Math.random()*250),"day")
        output.lastEnagement = dayjs().subtract(Math.ceil(Math.random()*90),"day")
        output.isFlagged = Boolean(Math.round(Math.random()));
        output.hasVulnerable = Boolean(Math.round(Math.random()));
        output.OTP = `OTP${Math.ceil(Math.random() * 999999)}`;
        output.caseWorker = caseWorkerList[Math.floor(Math.random() * caseWorkerList.length)]
        output.tasks.length = Math.round(Math.random()*2);
        output.tasks = output.tasks.fill({title:"Sample Task",due:"", dateCompleted:null},0, output.tasks.length).map((t,i)=>{
            let task = JSON.parse(JSON.stringify(t));
            task.due = dayjs().add(Math.ceil(Math.random()*90) - 45,"day")
            task.title = `Sample Task ${i+1}`

            return task;

        })
        return output;
      });
    },
    hasOverdueTasks(cs){
      return this.getOverdueTaskCount(cs) >0;
    },
    getOverdueTaskCount(cs){
      return cs.tasks.filter(task=>!task.due.isAfter(this.today) && !task.dateCompleted).length
    }
  },
  computed:{
    selectedCentre:{
        get(){
            return this.value;
        },
        set(v){
            this.$emit("input",v);
        }
    },
    caseWorkerList(){
      let cases = this.centre_cases;
      let unique = new Set();
      let outputArr =[];
      for(let cs of cases){
        if(!unique.has(cs.caseWorker.id)){
            unique.add(cs.caseWorker.id);
            outputArr.push(cs.caseWorker);
        }
      }
      return outputArr;

    },
    caseworkers(){
      let cases = this.centre_cases;
      let output = {};
      let outputArr = [];

      for(let cs of cases){
        if(!output[cs.caseWorker.id]){
          output[cs.caseWorker.id] = {
            count:1,
            name: cs.caseWorker.name
          }
          outputArr.push(cs.caseWorker.id);
        }else{
          output[cs.caseWorker.id].count++;
        }
      }
      

      return outputArr.map(id=>{
        return {
          text: `${output[id].name} (${output[id].count})`,
          value: id
        }
      })// Need to .sort()
    },
    caseworker_breakdowns(){
      let cases = this.centre_cases;
      let output = {};
      let outputArr = [];

      for(let cs of cases){
        if(!output[cs.caseWorker.id]){
          output[cs.caseWorker.id] = {
            caseWorker: cs.caseWorker.name,
            totalCases:1,
            flaggedCases:0,
            vulnerableCases:0,
            overdueAcpCount:0,
            overdueEngageCount:0,
            overdueTaskCount:0
          }
          outputArr.push(cs.caseWorker.id);
        }else{
          output[cs.caseWorker.id].totalCases++;
        }
        if(cs.isFlagged){
          output[cs.caseWorker.id].flaggedCases++
        }
        if(cs.hasVulnerable){
          output[cs.caseWorker.id].vulnerableCases++
        }
        if(!cs.lastACP.add(180,`day`).isAfter(this.today)){
          output[cs.caseWorker.id].overdueAcpCount++
        }
        if(!cs.lastEnagement.add(2,`month`).isAfter(this.today)){
          output[cs.caseWorker.id].overdueEngageCount++
        }
        if(this.hasOverdueTasks(cs)){
          output[cs.caseWorker.id].overdueTaskCount += this.getOverdueTaskCount(cs);
        }
      }

      return outputArr.filter(id=>this.selectedCaseworkers.includes(id)).map(id=>{
        let finalOutput = {id};
        Object.assign(finalOutput,output[id]);
        return finalOutput;
      })
    },
    caseworker_fields(){
      return["caseWorker","totalCases","flaggedCases","vulnerableCases",{label:"Overdue ACP Count",key:"overdueAcpCount"},"overdueEngageCount","overdueTaskCount"]
    },
    fields(){
      return ["caseInformation", {key:"lastContact",label:"ACP / Sessions"},"followupTasks","tags", "actions"]
    },
    rows(){
      return this.centre_cases_filtered.length;
    },
    flagCount(){
      return this.centre_cases.filter(cs=>cs.isFlagged).filter(cs=>{
        if(!this.limitByCaseWorker){
          return true;
        }
        return this.selectedCaseworkers.includes(cs.caseWorker.id);
 
      }).length;
    },
    vulCount(){
      return this.centre_cases.filter(cs=>cs.hasVulnerable).filter(cs=>{
        if(!this.limitByCaseWorker){
          return true;
        }
        return this.selectedCaseworkers.includes(cs.caseWorker.id);
 
      }).length;
    },
    overdueAcpCount(){
      return this.centre_cases.filter(cs=>!cs.lastACP.add(180,`day`).isAfter(this.today)).filter(cs=>{
        if(!this.limitByCaseWorker){
          return true;
        }
        return this.selectedCaseworkers.includes(cs.caseWorker.id);
 
      }).length;
    },
    overdueAcpFilterList(){
      let overDueAcps = this.centre_cases/*.filter(cs=>!cs.lastACP.add(180,`day`).isAfter(this.today))*/.filter(cs=>{
        if(!this.limitByCaseWorker){
          return true;
        }
        return this.selectedCaseworkers.includes(cs.caseWorker.id);
 
      });
      let output = {};
      let outputArr = []
      for(let cs of overDueAcps){
        let monthYear = cs.lastACP.add(180,`day`).format("MMM YYYY");
        let monthYearStrict = cs.lastACP.add(180,`day`).format("YYYY-MM");
        if(!output[monthYearStrict]){
          output[monthYearStrict] = {
            count:1,
            name:monthYear
          },
          outputArr.push(monthYearStrict);
        }
        else{
          output[monthYearStrict].count++;
        }
      }
      return outputArr.sort().reverse().map(id=>{
        return {
          text: `${output[id].name} (${output[id].count})`,
          value:id
        }
      })

    },
    overdueEngageCount(){
      return this.centre_cases.filter(cs=>!cs.lastEnagement.add(2,`month`).isAfter(this.today)).filter(cs=>{
        if(!this.limitByCaseWorker){
          return true;
        }
        return this.selectedCaseworkers.includes(cs.caseWorker.id);
 
      }).length;

    },
    overdueEngageFilterList(){
      let overDueEngage = this.centre_cases/*.filter(cs=>!cs.lastEnagement.add(2,`month`).isAfter(this.today))*/.filter(cs=>{
        if(!this.limitByCaseWorker){
          return true;
        }
        return this.selectedCaseworkers.includes(cs.caseWorker.id);
 
      });
      let output = {};
      let outputArr = []
      for(let cs of overDueEngage){
        let monthYear = cs.lastEnagement.add(2,`month`).format("MMM YYYY");
        let monthYearStrict = cs.lastEnagement.add(2,`month`).format("YYYY-MM");
        if(!output[monthYearStrict]){
          output[monthYearStrict] = {
            count:1,
            name:monthYear
          },
          outputArr.push(monthYearStrict);
        }
        else{
          output[monthYearStrict].count++;
        }
      }
      return outputArr.sort().reverse().map(id=>{
        return {
          text: `${output[id].name} (${output[id].count})`,
          value:id
        }
      })
    },
    overdueTaskCount(){
      return this.centre_cases.filter(this.hasOverdueTasks).filter(cs=>{
        if(!this.limitByCaseWorker){
          return true;
        }
        return this.selectedCaseworkers.includes(cs.caseWorker.id);
 
      }).length;

    },
    overdueTaskFilterList(){
      let overDueTask = this.centre_cases/*.filter(this.hasOverdueTasks)*/.filter(cs=>{
        if(!this.limitByCaseWorker){
          return true;
        }
        return this.selectedCaseworkers.includes(cs.caseWorker.id);
 
      });
      let output = {};
      let outputArr = []
      for(let cs of overDueTask){
        for(let task of cs.tasks){
          let monthYear = task.due.format("MMM YYYY");
          let monthYearStrict = task.due.format("YYYY-MM");
          if(!output[monthYearStrict]){
            output[monthYearStrict] = {
              count:1,
              name:monthYear
            },
            outputArr.push(monthYearStrict);
          }
          else{
            output[monthYearStrict].count++;
          }
        }
      }
      return outputArr.sort().reverse().map(id=>{
        return {
          text: `${output[id].name} (${output[id].count})`,
          value:id
        }
      })
    },
    centre_cases_filtered(){
      return this.centre_cases
      //caseworker
      .filter(cs=>{
        return this.selectedCaseworkers.includes(cs.caseWorker.id);
      })
      //flaggedOnly
      .filter(cs=>{
        if(!this.mode.flagged){
          return true;
        }
        return cs.isFlagged;
      })
      //Vul only
      .filter(cs=>{
        if(!this.mode.vulnerable){
          return true;
        }
        return cs.hasVulnerable;
      })
      //overdueACPs
      .filter(cs=>{
        if(!this.mode.acpOverdue){
          return true;
        }
        return !cs.lastACP.add(180,`day`).isAfter(this.today);
      })
      //overdueFollowups
      .filter(cs=>{
        if(!this.mode.engageOverdue){
          return true;
        }
        return !cs.lastEnagement.add(2,`month`).isAfter(this.today);
      })
      //overdueTasks
      .filter(cs=>{
        if(!this.mode.taskOverdue){
          return true;
        }
        return this.hasOverdueTasks(cs);
      })
      //ACP by month
      .filter(cs=>{
        if(this.overdueAcpFilter.length < 1){
          return true;
        }
        let month = cs.lastACP.add(180,`day`).format("YYYY-MM");

        return this.overdueAcpFilter.includes(month);
      })
      //engagement by month
      .filter(cs=>{
        if(this.overdueEngageFilter.length < 1){
          return true;
        }
        let month = cs.lastEnagement.add(2,`month`).format("YYYY-MM");

        return this.overdueEngageFilter.includes(month);
      })//task due by month
      .filter(cs=>{
        if(this.overdueTaskFilter.length < 1){
          return true;
        }
        for(let task of cs.tasks){
          let month = task.due.format("YYYY-MM");
          if(this.overdueTaskFilter.includes(month)){
            return true;
          }
        }
        return false


      })
      ;
    }
  },
  watch:{
    selectedCentre(){
      if(this.testmode){
        this.run_test_mode();
      }else{
        this.run_live_mode();
      }
    },
    groupByCaseWorker(v){
      if(v){
        this.limitByCaseWorker = true;
        this.overdueAcpFilter=[];
        this.overdueEngageFilter=[];
        this.overdueTaskFilter=[];
      }
    },
    limitByCaseWorker(v){
      if(!v){
        this.groupByCaseWorker = false;
      }
    },
    overdueAcpFilter(){
      this.checkMode();
    },
    overdueEngageFilter(){
      this.checkMode();
    },
    overdueTaskFilter(){
      this.checkMode();
    },
    selectedCaseworkers(){
      this.checkMode();
    },
    testmode(v){
      if(v){
        this.run_test_mode();
      }else{
        this.run_live_mode();
      }
    },
    assign_task_show(v){
        if(!v){this.assign_task_case_id =null;}
    },
    update_acp_show(v){
        if(!v){this.update_acp_show_id = this.update_acp_current = null;}
    },
    update_notes_show(v){
        if(!v){this.update_notes_show_id = this.update_notes_current = null;}
    }

  }
}
/**
 * 3 and 6 months frequency
 * DOB estimation
 * move everything to the supervisor dashboard
 * Traffic lights for engagement (1, 2 month)
 */
</script>