<template lang="pug">
  div
    div(v-if="loading")
      Loading
    div(v-else)
      h2 Edit Profile

      b-card.border-0(style="max-width: 1280px")
        b-card.mb-4
          h4 Microsoft Account Information
          p Display Name: <br>{{ user_info.graph_display_name }}
          p User Principal Name: <br>{{ user_info.graph_user_principal_name }}
          p Mobile Phone: <br>{{ user_info.graph_mobile_phone }}
          p Business Phone: <br>{{ user_info.graph_business_phones }}

        h4.mb-3 {{appName}} Profile
        b-form(@submit.prevent="editUser")
          b-form-group(label="Display Name" label-for="nickname")
            b-form-input(id="nickname" v-model="profile.c_display_name" type="text" placeholder="Enter a display name" required)

          b-button(type="submit") Save
</template>
<script>
import Loading from '@/components/loader/Loading.vue';

export default {
  name: 'Profile',
  data() {
    return {
      profile: {
        id: null,
        c_display_name: null
      },
      loading: true
    }
  },
  components: {
    Loading
  },
  computed: {
    user_info(){
      return this.$store.state.user_info;
    },
    appName() {
      return process.env.VUE_APP_NAME ? process.env.VUE_APP_NAME : 'Certainty';
    }
  },
  mounted() {
    this.getUserInfo();
    
  },
  methods: {
    async getUserInfo() {
      await this.$store.dispatch('getUserInfo').then(result=>{
        this.profile.id = result.id;
        this.profile.c_display_name = result.c_display_name;

        this.loading = false;
      });
    },
    editUser() {
      this.$store.dispatch('patchUserInfo',{payload: this.profile}).then(()=>{
        window.location.href = `/profile`;
      }).catch(()=>{
          
        // todo: error message
      })
    }
  }
}
</script>