import axios from 'axios';

const $ = axios.create({
    baseURL:window.location.origin+'/api/'
});

export default {
    state: {
        appointment: {},
        appointments: [],
        schedule:{},
        place:{},
        places:[],
        zoom:[]
    },
    mutations: {
        appointment(state, appointment) {
            state.appointment = appointment;
        },
        appointments(state, appointments) {
            state.appointments = appointments;
        },
        schedule(state, schedule) {
            state.schedule = schedule;
        },
        place(state, place) {
            state.place = place;
        },
        places(state, places) {
            state.places = places;
        },
        zoom(state, zoom) {
            state.zoom = zoom;
        }
    },
    actions: {
        // get user own appointment
        async fetchMyAppointments(store,payload) {
            const pl = new URLSearchParams(payload);
            const {data:{result}} = await $.get(`user/appointment?${pl.toString()}`);
            store.commit('appointments',result);
            return result;
        },
        // get user own appointment + group appointment + supervisee appointment
        async fetchGroupAppointments(store, payload) {
            const pl = new URLSearchParams(payload);
            const {data:{result}} = await $.get(`appointment?${pl.toString()}`);
            store.commit('appointments',result);
            return result;
        },
        async getAppointment(store, appointmentId) {
            const {data:{result}} = await $.get(`appointment/${appointmentId}`);
            store.commit('appointment',result);
            return result;
        },
        async getSchedule(store, {payload}) {
            const {data: {result}} = await $.post(`ms_graph/calendar`, payload);
            await store.commit('schedule',result);
            return result;
        },
        async createAppointment(store, {payload}) {
            const {data: {result}} = await $.post(`user/appointment`, payload);
            
            return result;
        },
        async patchAppointment(store, { payload, appointment_id }){
            const {data:{result}} = await $.patch(`user/appointment/${appointment_id}`, payload);
            store.commit('appointment', result);
            return result;

        },
        async declineAppointment(store, { payload }){
            const {data:{result}} = await $.post(`appointment/response`, payload);

            return result;
        },
        async deleteAppointment(store, appointmentId) {
            const {data:{result}} = await $.delete(`user/appointment/${appointmentId}`);

            return result;
        },
        async sendNotification(store, { payload, appointment_id }){
            const {data:{result}} = await $.post(`user/appointment/${appointment_id}/send_notification`, payload);
            return result;
        },
        async fetchAllPlaces(store) {
            const {data:{result}} = await $.get(`ms_graph/places`);
            store.commit('places',result);
            return result;
        },
        async getPlace(store, roomId) {
            const {data:{result}} = await $.get(`room/${roomId}`);
            store.commit('place',result);
            return result;
        },
        async fetchAllZoom(store) {
            const {data:{result}} = await $.get(`zoom/?get_accounts=1`);
            store.commit('zoom',result);
            return result;
        },
        async updateAttendance(store, { payload, appointment_id }){
            const {data:{result}} = await $.post(`appointment/${appointment_id}/attendance`, payload);
            return result;
        },
    }
}