<template lang="pug">
  div {{ renderPreview }}
</template>

<script>
import dayjs from 'dayjs';

export default {
    props: [ 'rawReminderTemplate', 'appointmentData' ],
    data() {
        return {
            previewData: {
                name: "John Doe",
                nickname: this.$store.state.enable_client_nickname ? "Joe" : "",
                contact_number: "+65 0000 0000",
                subject: "Example Appointment",
                organiser: "",
                date: "",
                time: "",
                start_time: "",
                end_time: "",
                location: "Meeting Room A",
                description: "This is an example description."
            }
        }
    },
    computed: {
        renderPreview() {
            let template = this.rawReminderTemplate || "";
            let data = this.previewData || "";

            return template.replace(/{{([^}]+)}}/g, function (match, string) {
                let fallback = " ";
                
                return data[string] ? data[string] : fallback;
            });
        },
    },
    mounted() {
        this.fillPreviewData();
    },
    methods: {
        dayjs,
        async fillPreviewData() {
            if(this.appointmentData) {
                this.previewData = this.appointmentData;
            }
            else {
                let start = dayjs();
                let end = dayjs().add(2, 'hours');

                this.previewData.start_time = start.format('h:mm A');
                this.previewData.end_time = end.format('h:mm A');
                this.previewData.time = start.format('h:mm A') + ' to ' + end.format('h:mm A');
                this.previewData.date = start.format('DD/MM/YYYY')
            }

            await this.$store.dispatch('getUserInfo').then(result=>{
                this.previewData.organiser = result.c_display_name ? result.c_display_name : result.graph_display_name;
            });
        },
        
    }
}
</script>

<style>

</style>