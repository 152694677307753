<template lang="pug">
  .about
    h2 Calendar
    hr
    CalendarComponent
</template>
<script>
import CalendarComponent from '@/components/appointments/calendar.vue';

export default {
  name: 'Calendar',
  //certaintyMenu(link){
  //  return {iconType: 'fas', icon: 'calendar-alt', link, description: 'Calendar', order:5}
  //},
  components: {
    CalendarComponent
  }
}
</script>

<style>

</style>