<template lang="pug">
  div
    .error(v-if="!$store.state.risk_monitoring")
      h1 Feature Not Available
    .about(v-else)
      h1 Monitoring Dashboard
      b-overlay(:show="!ready")
        b-tabs(pills justified v-if="ready")
          b-tab(title="Centre Cases" v-if="centreList.length >0") 
            risk_monitoring(:centre-list="centreList" v-model="currentCentre")
          b-tab(title="Supervisee Cases" v-if="supervisorList.length >0")
            risk_monitoring(:centre-list="supervisorList" v-model="currentSupervisor" supervisor)
          b-tab(title="My Cases")
            risk_monitoring(individual)

</template>
<script>
import risk_monitoring from "@/components/dashboard/risk_monitoring.vue";
export default {
  name: 'Dashboard',
  certaintyMenu(link,$store){
    if(!$store?.state?.risk_monitoring){
      return false;
    }
    return {iconType: 'fas', icon: 'chart-area', link, description: 'Dashboard', order:0}
  },
  components:{
    risk_monitoring
  },
  data(){
    return{
      ready:false,
      centreList:[],
      supervisorList:[],
      currentCentre:"",
      currentSupervisor:""
    }
  },
  mounted(){
    let user_groups = this.$store?.state?.user?.user_owned_groups_w_details||[]
    let mapFn = (({group_name,id})=>{
      return {
        value:id,
        text:group_name
      }
    })
    this.centreList = user_groups.filter(g=>g.group_type == "centre").map(mapFn)
    this.supervisorList = user_groups.filter(g=>g.group_type == "clinical_supervisor").map(mapFn)
    this.currentCentre = this.centreList[0]?.value ||"";
    this.currentSupervisor = this.supervisorList[0]?.value ||"";
    this.ready = true;
  }
}
/**
 * 3 and 6 months frequency
 * DOB estimation
 * move everything to the supervisor dashboard
 * Traffic lights for engagement (1, 2 month)
 */
</script>