<template lang="pug">
    div
        div(style="color:grey")
            router-link(to="/clients") Clients
            span.mx-1 ›
            router-link(to="/client-group") {{$.clientGroup}}
            span.mx-1 ›
            span(v-if="!isLoading")
                router-link(:to="'/client-group/'+$route.params.client_group_id") {{clientGroup.name}}
                span.mx-1 ›
                span Edit {{$.clientGroup}}

        div.d-flex.justify-content-between.align-items-center.mb-1(style="max-width: 1280px")
            h2.m-0 Edit {{$.clientGroup}}
            div
                b-button.ml-2.my-1(v-b-modal.transfer-modal variant='outline-secondary' v-if="$store.state.allow_reassign_client" :disabled="isLoading") 
                    b-icon.mr-2(icon="arrow-left-right")
                    | Transfer {{$.clientGroup}}
                //b-button.ml-2.my-1(v-b-modal.delete-modal variant="danger" :disabled="isLoading") 
                    b-icon.mr-2(icon="trash")
                    | Delete {{$.clientGroup}}

        b-card.border-0(style="max-width: 1280px")
            b-overlay(:show="isLoading" variant="white")
                b-form(@submit.prevent="editClientGroup")
                    b-tabs
                        b-tab.mt-3.px-3.border-bottom.mb-3(title="General Information")
                            b-form-group(:label="$.clientGroup" label-for="name")
                                b-form-input(id="name" v-model="clientGroup.name" type="text" required)
                            b-form-group(label="Date Opened" label-for="date-open" v-if="$store.state.risk_monitoring")
                                b-form-input(id="date-open" v-model="clientGroup.date_open" type="date" required)
                            b-form-group(:label="`${$.clientGroup} Members`" label-for="clients")
                                b-form-radio-group(v-model="showClosedClientMode")
                                    b-form-radio(:value="0") Show All Clients
                                    b-form-radio(:value="1") Show Open Clients
                                    b-form-radio(:value="2") Show Closed Clients
                                b-overlay(:show="loadingClients" no-center rounded opacity="0" spinner-small)
                                    template(#overlay)
                                        div.d-flex.h-100.justify-content-end.p-2
                                            b-spinner(small)
                                    v-select.my-1(multiple v-model="selectedClient" @search="searchClient" :filterable="false"  :options="filteredClientOptions" :components="{OpenIndicator}")
                                        template(#search="{attributes, events}")
                                            input(class="vs__search" v-bind="attributes" v-on="events" :placeholder="selectedClient.length ? '' : 'Start typing to search for clients...'")
                                        template(v-slot:option="option")
                                            span {{option.label}}
                                            span.ml-1(v-if="!option.active")
                                                b-badge(variant="danger") {{$.inactive}}
                                            span.ml-1(v-if="!option.owned")
                                                b-badge.border(variant="light") Not Owned
                                        template(#list-footer)
                                            b-button.border-top(v-b-modal.new-client block variant="light" size="sm")
                                                b-icon(icon="plus")
                                                | Create New Client
                                b-button(v-b-modal.new-client variant="outline-secondary" size="sm")
                                    b-icon(icon="plus")
                                    | Create new client



                            b-form-group(v-if="$store.state.groups_enabled" label="Group" label-for="group")
                                b-form-select(id="group" v-model="clientGroup.group_id" :options="groupOptions" required)

                            b-form-group(v-if="$store.state.allow_inactive_client_group" label="Status")
                                b-form-select(v-model="clientGroup.active" required)
                                    b-form-select-option(:value="1") {{$.active}}
                                    b-form-select-option(:value="0") {{$.inactive}}
                            b-form-group(v-if="clientGroup.active === 0 && $store.state.allow_inactive_client_group")
                                b-form-checkbox(v-model="clientGroup.deactivate_client") Also update client(s) to {{$.inactive}}
                            b-form-group(v-if="clientGroup.active === 1 && $store.state.allow_inactive_client_group")
                                b-form-checkbox(v-model="clientGroup.activate_client") Also update client(s) to {{$.active}}

                            b-form-group(label="Date Closed" label-for="dateclosed" v-if="clientGroup.active === 0")
                                b-form-input(id="dateclosed" v-model="clientGroup.date_closed" type="date" required)
                        b-tab.mt-3.px-3.border-bottom.mb-3(title="Flagging / ACP Details / Notes" v-if="$store.state.risk_monitoring")    
                            b-form-group(label="Flagged Status" label-for="flagged")
                                b-form-checkbox(id="flagged" v-model="clientGroup.meta_data.isFlagged") Mark as Flagged
                            b-form-group(label="Last ACP" label-for="lastACP" description="Indicate when the last ACP/Review was done")
                                b-form-input(id="flagged" v-model="clientGroup.meta_data.lastACP" type="date")
                            b-form-group(label="Notes" label-for="notes") 
                                b-form-textarea(id="notes" v-model="clientGroup.meta_data.notes" )

                    b-button.ml-3(type="submit" :disabled="isSubmitting") Save

                    <!-- Create client modal -->
                    b-modal(id="new-client" title="Create New Client" hide-footer)
                        CreateClientForm(@client="addClient($event)" :hide-client-group="true")

                hr
                div.d-flex.align-items-center.my-4.ml-3
                    b-button.mr-2(v-b-modal.delete-modal variant="outline-danger" :disabled="isLoading") 
                        b-icon.mr-2(icon="trash")
                        | Delete {{$.clientGroup}}
                    span Caution! This will permanently delete this {{$.clientGroup}}.
                        span(v-if="$store.state.allow_inactive_client_group")  Alternatively, you can set the {{$.clientGroup}} status to {{$.inactive}} and keep the {{$.clientGroup}} history.

                b-modal(id="delete-modal" :title="`Delete ${$.clientGroup}?`" @ok="deleteClientGroup" cancel-variant="outline-secondary" ok-variant="danger" ok-title="Delete")
                    p This will permanently delete this {{$.clientGroup}}.
                    p(v-if="$store.state.allow_inactive_client_group")  Alternatively, you can set the {{$.clientGroup}} status to {{$.inactive}} and keep the {{$.clientGroup}} history.

                b-modal(id="transfer-modal" :title="`Transfer ${$.clientGroup}`" v-if="$store.state.allow_reassign_client" @ok="transferClientGroup" cancel-variant="outline-secondary" ok-variant="secondary" ok-title="Transfer")
                    b-form-group(label="Current owner:")
                        b-form-input(:value="ownerName" readonly)
                    b-form-group(label="Transfer to:" label-for="owner")
                        b-overlay(:show="loadingUsers" no-center rounded opacity="0" spinner-small)
                            template(#overlay)
                                div.d-flex.h-100.justify-content-end.p-2
                                    b-spinner(small)
                            v-select.my-1(v-model="clientOwner" @search="searchUser" :filterable="false" :options="ownerOptions")
                                template(#search="{attributes, events}")
                                    input(class="vs__search" v-bind="attributes" v-on="events" :placeholder="clientOwner ? '' : 'Start typing to search for user...'")
                    b-form-group(label="Clients:" v-if="transferClients")
                        div.border.rounded.p-1.bg-light
                            b-badge.mr-1.border(v-for="client in selectedClient" :key="client.label" variant="light") {{client.label}}

                    b-form-checkbox(v-model="transferClients") Transfer all clients associated with this {{$.clientGroup}}

                        
            
    
</template>

<script>
import dayjs from 'dayjs';
import CreateClientForm from '@/components/clients/create.vue';

export default {
    name: 'EditClientGroup',
    data() {
        let clientGroup = {
                id: this.$route.params.client_group_id,
                name: "",
                clients: [], // only the client id
                group_id: "",
                active: null,
                date_closed: null,
                deactivate_client: this.$store.state.allow_inactive_client_group ? true : false, // if set true, system will automatically set client as inactive if client group is set inactive
                activate_client: true,
                
            };
        if(this.$store.state.risk_monitoring){
            Object.assign(clientGroup,{
                meta_data:{
                    lastACP: "",
                    isFlagged: false,
                    notes:""
                },
                date_open: null,


            })
        }
        return {
            isLoading: true,
            isSubmitting: false,
            loadingClients: false,
            loadingUsers: false,
            clientTimeout: null, //debounce timeout
            userTimeout: null,
            clientOptions: [],
            groupOptions: [{ value: "", text: "" }],
            ownerOptions: [],
            clientOwner: null,
            ownerName: "",
            transferClients: true, // do you want to also transfer client to other user when transferring client group
            clientGroup,
            selectedClient: [],
            OpenIndicator: {
                render: createElement => createElement('span', ''),
            },
            showClosedClientMode: 0 // 0 - All, 1 - open only, 2 - closed only
            
        };
    },
    components: {
        CreateClientForm
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        clients(){
            return this.$store.state.clients;
        },
        user_groups(){
            return this.$store.state.user_groups||[];
        },
        localisation(){
            return this.$store.state.localisation;
        },
        $(){
            let output ={
                "clientGroup": "Client Group",
                "active": "Active",
                "inactive": "Inactive"
            };
                    
            Object.assign(output,this.localisation);
            return output;
        },
        users(){
            return this.$store.state.users.value;
        },
        filteredClientOptions() {
            if(this.showClosedClientMode===1) {
                return this.clientOptions.filter(opt=>opt.active===true);
            }
            if(this.showClosedClientMode===2) {
                return this.clientOptions.filter(opt=>opt.active===false);
            }
            return this.clientOptions.filter(()=>true);
        }
    },
    async mounted() {
        let promises = [
            this.getClientGroup(),
            this.getUserGroups(),
        ];

        await Promise.all(promises);
        
        for(let group of this.user_groups) {
            let mainGroup = this.$store.state.group_main_types;
            if(mainGroup && !mainGroup.includes(group.group_type)) {
                continue;
            }
            this.groupOptions.push({
                value: group.id,
                text: group.group_name
            });
        }

        this.isLoading = false;
    },
    methods: {
        dayjs,
        async getClientGroup() {
            await this.$store.dispatch('getClientGroup',this.$route.params.client_group_id)
            .then(result=>{
                this.clientGroup.name = result.name;
                this.clientGroup.group_id = result.group_id;
                this.clientGroup.active = result.active;
                this.clientGroup.date_closed = result.date_closed ? dayjs(result.date_closed).format('YYYY-MM-DD') : "";
                if(this.$store.state.risk_monitoring){

                    this.clientGroup.date_open= result.date_open ? dayjs(result.date_open).format("YYYY-MM-DD") : "";
                    this.clientGroup.meta_data = result.meta_data? result.meta_data:{
                        lastACP: "",
                        isFlagged: false,
                        notes:""
                    }
                }
                
                this.ownerName = result.owner;

                // populate group members field
                for(let i=0; i<result.clients.length; i++) {
                    let value = {
                        label: result.clients[i].name + (result.clients[i].contact_number ? `(${result.clients[i].contact_number})` : ''),
                        active: result.clients[i].visibility===0 ? false : true,
                        owned: result.clients[i].user_id === this.user.user_id,
                        value: result.clients[i].id
                    };

                    this.selectedClient.push(value);
                }
            })
            .catch(()=>{
                // todo: display error 404

            });
        },
        async searchClient(search) {
            // debounce
            if (this.clientTimeout)
                clearTimeout(this.clientTimeout); 

            this.clientTimeout = setTimeout(async() => {
                // your action
                this.clientOptions = [];
                if(!search) {
                    return;
                }
                
                await this.fetchClients(search);
            }, 500); // delay
        },
        async fetchClients(filter=null) {
            this.loadingClients = true;

            let params = {}
            if(filter) {
                params = {
                    top: 10,
                    filterby: "name",
                    filter
                }
            }
            await this.$store.dispatch('fetchAllClients',params).then((result)=>{
                // Prepare option for attendees select
                for (var i=0; i<result.value.length; i++){
                    let option = {
                        label: result.value[i].name + (result.value[i].contact_number ? `(${result.value[i].contact_number})` : ''),
                        active: result.value[i].visibility===0 ? false : true,
                        owned: result.value[i].user_id === this.user.user_id,
                        value: result.value[i].id
                    };

                    this.clientOptions.push(option);
                }
            })
            .catch(()=>{
                // do something
            })
            this.loadingClients = false;
        },
        async editClientGroup(redirect=true) {
            this.isSubmitting=true;
            // Process selected clients
            for(let i=0; i<this.selectedClient.length; i++) {
                this.clientGroup.clients = this.clientGroup.clients.concat(this.selectedClient[i].value);
            }
            // remove duplicates in clients
            this.clientGroup.clients = [...new Set(this.clientGroup.clients)];
            
            this.clientGroup.date_closed = this.clientGroup.active === 0 ? this.clientGroup.date_closed : null; // remove date_closed if client group still active

            await this.$store.dispatch('patchClientGroup',{payload: this.clientGroup, client_group_id: this.$route.params.client_group_id}).then(result=>{
                if(redirect) { window.location.href = `/client-group/${result.id}`; }
            }).catch(()=>{
                
                // todo: error message
            });
            this.isSubmitting=false;
        },
        async transferClientGroup() {
            // Save any changes in client before transfer
            await this.editClientGroup(false);

            if(!this.clientOwner) {
                return;
            }

            let payload = {
                user_id: this.clientOwner.value
            }

            let promises = [
                this.$store.dispatch('patchClientGroup',{payload, client_group_id:this.$route.params.client_group_id})
            ];
            // transfer client endpoint
            if(this.transferClients) {
                for(let client of this.selectedClient) {
                    promises.push(this.$store.dispatch('patchClient',{payload, client_id:client.value}));
                }
            }

            await Promise.allSettled(promises).then((result)=>{
                window.location.href = `/client-group/${result[0].value.id}`;
            }).catch((err)=> {
                window.alert(err);
            });
        },
        async deleteClientGroup() {
            this.isSubmitting=true;
            this.$store.dispatch('deleteClientGroup', this.$route.params.client_group_id).then(()=>{
                window.location.href = `/client-group`;
            }).catch(()=>{
                this.isSubmitting=false;
                // todo: error message
            })
        },
        async getUserGroups() {
            await this.$store.dispatch('getUserGroups').then(()=>{
            })
            .catch(()=>{
                // do something
            });
        },
        async searchUser(search) {
            // debounce
            if (this.userTimeout)
                clearTimeout(this.userTimeout); 

            this.userTimeout = setTimeout(async() => {
                // your action
                this.ownerOptions = [];
                if(!search) {
                    return;
                }
                
                await this.getAllUser(search);
            }, 500); // delay
        },
        async getAllUser(filter=null) {
            this.loadingUsers = true;

            let params = {}
            if(filter) {
                params = {
                    top: 10,
                    filterby: "graph_display_name",
                    filter
                }
            }

            await this.$store.dispatch('getAllUser',params).then((result)=>{
                for (let cw of result.value){
                    let option = {
                        label: `${cw.graph_display_name} (${cw.graph_user_principal_name})`,
                        value: cw.id
                    };

                    this.ownerOptions.push(option);
                }
            })
            .catch(()=>{
                // do something
            });

            this.loadingUsers = false;
        },
        async addClient(client) {
            // map client group to ids
            let payload = { ...client }
            payload.client_groups = payload.client_groups ? payload.client_groups.map(cg=>cg.content) : [];

            await this.$store.dispatch('addClient',{payload}).then((result)=>{
                let option = {
                    label: result.name + (result.contact_number ? `(${result.contact_number})` : ''),
                    value: result.id,
                };

                this.clientOptions.push(option);
                this.selectedClient.push(option);

                this.$bvModal.hide('new-client');
            }).catch(()=>{
                // show error message
            })
        },
    }
}
</script>

<style>

</style>