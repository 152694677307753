import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import privateRoutes from './private.js' ;
import publicRoutes from './public.js' ;

import otherRoutes from './other.js'

const routes = [...privateRoutes,...publicRoutes, ...otherRoutes];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
