<template lang="pug">
    div
        //Breadcrumb navigation
        div(style="color:grey")
            router-link(to="/clients") Clients
            span.mx-1 ›
            router-link(to="/client-group") {{$.clientGroup}}
            span.mx-1 ›
            span Create New {{$.clientGroup}}
        //router-link(to="/client-group") Back to {{$.clientGroup}} List
        h2 Create New {{$.clientGroup}}

        b-card.border-0(style="max-width: 1280px")
            b-form(@submit.prevent="createClientGroup")
                b-form-group(:label="$.clientGroup" label-for="name")
                    b-form-input(id="name" v-model="clientGroup.name" type="text" required)
                b-form-group(label="Date Opened" label-for="date-open" v-if="$store.state.risk_monitoring")
                    b-form-input(id="date-open" v-model="clientGroup.date_open" type="date" required)
                b-form-group(:label="`${$.clientGroup} Members`" label-for="clients")
                    b-form-radio-group(v-model="showClosedClientMode")
                        b-form-radio(:value="0") Show All Clients
                        b-form-radio(:value="1") Show Open Clients
                        b-form-radio(:value="2") Show Closed Clients
                    b-overlay(:show="loadingClients" no-center rounded opacity="0" spinner-small)
                        template(#overlay)
                            div.d-flex.h-100.justify-content-end.p-2
                                b-spinner(small)
                        v-select.my-1(multiple v-model="selectedClient" @search="searchClient" :filterable="false" :options="filteredClientOptions" :components="{OpenIndicator}")
                            template(#search="{attributes, events}")
                                input(class="vs__search" v-bind="attributes" v-on="events" :placeholder="selectedClient.length ? '' : 'Start typing to search for clients...'")
                            template(v-slot:option="option")
                                span {{option.label}}
                                span.ml-1(v-if="!option.active")
                                    b-badge(variant="danger") {{$.inactive}}
                                span.ml-1(v-if="!option.owned")
                                    b-badge.border(variant="light") Not Owned                                
                            template(v-slot:no-options="{ search, searching }")
                                template(v-if="searching")
                                    template(v-if="loadingClients") Searching for clients named <em>{{ search }}</em>...
                                    template(v-else) No results found for <em>{{ search }}</em>.
                                em(v-else style="opacity: 0.5") Start typing to search for clients...
                            template(#list-footer)
                                b-button.border-top(v-b-modal.new-client block variant="light" size="sm")
                                    b-icon(icon="plus")
                                    | Create New Client
                    b-button.mt-1(v-b-modal.new-client variant="outline-secondary" size="sm")
                        b-icon(icon="plus")
                        | Create new client



                b-form-group(v-if="$store.state.groups_enabled" label="Group" label-for="group")
                    b-form-select(id="group" v-model="clientGroup.group_id" :options="groupOptions" required)
                
                template(v-if="false")
                    hr
                    h5 Task Automation Extras (optional)
                    p Fill up this section to track Cases via Task Automation Module
                    b-form-group(label="Flagged Status" label-for="flagged")
                        b-form-checkbox(id="flagged" v-model="clientGroup.flagged") Mark as Flagged
                    b-form-group(label="Last ACP" label-for="lastACP" description="Indicate when the last ACP/Review was done")
                        b-form-input(id="flagged" v-model="clientGroup.lastACP" type="date")
                    b-form-group(label="Notes" label-for="notes") 
                        b-form-textarea(id="notes" v-model="clientGroup.notes" )

                    hr
                b-button(type="submit" :disabled="isSubmitting") Save

                <!-- Create client modal -->
                b-modal(id="new-client" title="Create New Client" hide-footer)
                    CreateClientForm(@client="addClient($event)" :hide-client-group="true")
    
</template>

<script>
import CreateClientForm from '@/components/clients/create.vue';
import dayjs from "dayjs";
export default {
    name: 'NewClientGroup',
    data() {
        let clientGroup ={
                name: "",
                clients: [], // only the client id
                group_id: "",
            };
        if(this.$store.state.risk_monitoring){
            Object.assign(clientGroup,{
                date_open:dayjs().format("YYYY-MM-DD")
            })
        }
        return {
            loadingClients: false,
            isSubmitting: false,
            clientTimeout: null, //debounce timeout
            clientOptions: [],
            groupOptions: [{ value: "", text: "" }],
            clientGroup,
            selectedClient: [],
            OpenIndicator: {
                render: createElement => createElement('span', ''),
            },
            showClosedClientMode: 0 // 0 - All, 1 - open only, 2 - closed only
        };
    },
    components: {
        CreateClientForm
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        clients(){
            return this.$store.state.clients;
        },
        user_groups(){
            return this.$store.state.user_groups||[];
        },
        localisation(){
            return this.$store.state.localisation;
        },
        $(){
            let output ={
                "clientGroup": "Client Group",
                "active": "Active",
                "inactive": "Inactive"
            };
                    
            Object.assign(output,this.localisation);
            return output;
        },
        filteredClientOptions() {
            if(this.showClosedClientMode===1) {
                return this.clientOptions.filter(opt=>opt.active===true);
            }
            if(this.showClosedClientMode===2) {
                return this.clientOptions.filter(opt=>opt.active===false);
            }
            return this.clientOptions.filter(()=>true);

        }
    },
    created() {
    },
    async mounted() {
        await this.getUserGroups();
        for(let group of this.user_groups) {
            let mainGroup = this.$store.state.group_main_types;
            if(mainGroup && !mainGroup.includes(group.group_type)) {
                continue;
            }
            this.groupOptions.push({
                value: group.id,
                text: group.group_name
            });
        }
    },
    methods: {
        async searchClient(search) {
            // debounce
            if (this.clientTimeout)
                clearTimeout(this.clientTimeout); 

            this.clientTimeout = setTimeout(async() => {
                // your action
                this.clientOptions = [];
                if(!search) {
                    return;
                }
                
                await this.fetchClients(search);
            }, 500); // delay
        },
        async fetchClients(filter=null) {
            this.loadingClients = true;

            let params = {}
            if(filter) {
                params = {
                    top: 10,
                    filterby: "name",
                    filter
                }
            }
            await this.$store.dispatch('fetchAllClients',params).then((result)=>{
                // Prepare option for attendees select
                for (var i=0; i<result.value.length; i++){
                    let option = {
                        label: result.value[i].name + (result.value[i].contact_number ? `(${result.value[i].contact_number})` : ''),
                        active: result.value[i].visibility===0 ? false : true,
                        owned: result.value[i].user_id === this.user.user_id,
                        value: result.value[i].id
                    };

                    this.clientOptions.push(option);
                }
            })
            .catch(()=>{
                // do something
            })

            this.loadingClients = false;
        },
        async createClientGroup() {
            this.isSubmitting=true;
            // Process selected clients
            for(let i=0; i<this.selectedClient.length; i++) {
                this.clientGroup.clients = this.clientGroup.clients.concat(this.selectedClient[i].value);
            }
            // remove duplicates in clients
            this.clientGroup.clients = [...new Set(this.clientGroup.clients)];


            await this.$store.dispatch('addClientGroup',{payload: this.clientGroup}).then((result)=>{
                window.location.href = `/client-group/${result.id}`;
            }).catch(()=>{
                // todo: show error message
                this.isSubmitting=false;
            });
        },
        async getUserGroups() {
            await this.$store.dispatch('getUserGroups').then(()=>{
            })
            .catch(()=>{
                // do something
            });
        },
        async addClient(client) {
            // map client group to ids
            let payload = { ...client }
            payload.client_groups = payload.client_groups ? payload.client_groups.map(cg=>cg.content) : [];

            await this.$store.dispatch('addClient',{payload}).then((result)=>{
                let option = {
                    label: result.name + (result.contact_number ? `(${result.contact_number})` : ''),
                    value: result.id,
                };

                this.clientOptions.push(option);
                this.selectedClient.push(option);

                this.$bvModal.hide('new-client');
            }).catch(()=>{
                // show error message
            })
        },
    }
}
</script>

<style>

</style>