<template lang="pug">
  .about
    div(style="color:grey")
      router-link(to="/clients") Clients
      span.mx-1 ›
      router-link(to="/clients?tab=client-group") {{$.clientGroup}}
      span.mx-1 ›
      span {{client_group.name}}
    //router-link(to="/clients?tab=client-group") Back to {{$.clientGroup}} List
    div.d-flex.justify-content-between.align-items-center.flex-wrap
      h2 {{ client_group.name }}

      router-link(v-if="client_group.user_id == user.user_id || (userGroups.includes(client_group.group_id) && $store.state.group_member_can_edit_client)" :to="{ name: 'EditClientGroup', params: { client_group_id: $route.params.client_group_id } }" tag="b-button")
        b-icon.mr-2(icon="pencil-square")
        | Edit {{$.clientGroup}}

    
    b-card.border-0
      b-overlay(:show="loading" variant="white")
        div.mb-4(v-if="$store.state.groups_enabled")
          b-icon.mr-2(icon="people" title="Group")
          | {{ client_group.group ? client_group.group.group_name : '-' }}

        h6.font-weight-bold.mb-3 Members

        b-button.mb-2(v-if="$store.state.sms_broadcast" v-b-modal.send-sms-broadcast size="sm") Send SMS Broadcast

        router-link.bg-light.rounded.border.px-3.py-2.mb-2(v-for="client in client_group.clients" :key="client.id" :to="{ name: 'SingleClient', params: { client_id: client.id }}" tag="div" style="cursor:pointer")
          div.font-weight-bold {{ client.name }}
          div.text-muted
            b-icon.mr-2(icon="telephone" title="Contact Number")
            | {{ client.contact_number }}

    b-modal(v-if="$store.state.sms_broadcast" id="send-sms-broadcast" title="Send SMS Broadcast" hide-footer)
      b-form(@submit.prevent="sendBroadcast")
        b-form-group(label="Client")
          v-select(multiple v-model="smsBroadcast.clients" :options="clientOptions" :reduce="client => client.value")
            template(#search="{attributes, events}")
              input(class="vs__search" :required="smsBroadcast.clients.length < 1" v-bind="attributes" v-on="events")
        b-form-group(label="Message")
          b-button.mr-1.mb-1(v-html="`{{name}}`" variant="outline-primary" size="sm" @click="smsBroadcast.message += '{{name}}'")
          b-button.mr-1.mb-1(v-html="`{{contact_number}}`" variant="outline-primary" size="sm" @click="smsBroadcast.message += '{{contact_number}}'")
          b-form-textarea(v-model="smsBroadcast.message" placeholder="Add a message" required)
          b-alert.mt-1(variant="primary" :show="smsBroadcast.message != ''")
            TemplatePreview(:raw-reminder-template="smsBroadcast.message")
        hr
        b-button.mr-1(type="submit" :disabled="saving") Send
        b-button(@click="$bvModal.hide('send-sms-broadcast')" variant="outline-danger" :disabled="saving") Cancel
      
      
</template>
<script>
import dayjs from 'dayjs';
import TemplatePreview from '@/components/reminders/reminder_template_preview.vue';

export default {
  name: 'SingleClientGroup',
  data() {
    return {
      loading: true,
      saving: false,
      clientIds: [],// to use for reset form
      smsBroadcast: {
        clients: [],
        message: ""
      }
    }
  },
  components: {
    TemplatePreview
  },
  computed: {
    client_group(){
      return this.$store.state.client_group;
    },
    localisation(){
      return this.$store.state.localisation;
    },
    $(){
      let output ={
        "clientGroup": "Client Group",
      };
              
      Object.assign(output,this.localisation);
      return output;
    },
    clientOptions() {
      if(!this.client_group.clients) {
        return [];
      }
      let output = [];
      for(let client of this.client_group.clients) {
        output.push({
          label: client.name + (client.contact_number ? ` (${client.contact_number})` : ''),
          value: client.id
        });
      }
      return output;
    },
    userGroups() {
      if(this.user.user_groups_w_details && this.user.user_groups_w_details.length) {
          let groups = this.user.user_groups_w_details;
          if(this.$store.state.group_main_types && this.$store.state.group_main_types.length) {
            groups = this.user.user_groups_w_details.filter(grp=>this.$store.state.group_main_types.includes(grp.group_type));
          }
          return groups.map(grp=>grp.id);
      }
      return [];
    }
  },
  async mounted() {
    await this.getClientGroup();
    this.loading = false;
  },
  methods: {
    dayjs,
    async getClientGroup() {
      await this.$store.dispatch('getClientGroup',this.$route.params.client_group_id)
      .then((result)=>{
        // autofill sms broadcast fields
        if(result.clients) {
          for(let client of result.clients) {
            this.smsBroadcast.clients.push(client.id);
            this.clientIds.push(client.id); // to use for reset form
          }
        }
      })
      .catch((err)=>{
        if(err.response.status == 404) {

          console.log(err);

          // todo: display error 404

        }
      });
    },
    async sendBroadcast() {
      this.saving = true;
      await this.$store.dispatch('sendBroadcast',{payload:this.smsBroadcast}).then(()=>{
        this.$bvModal.hide('send-sms-broadcast');

        // clear fields
        this.smsBroadcast = {
          clients: this.clientIds,
          message: ""
        }
      }).catch((err)=>{
        window.alert(err);
      });
      
      this.saving = false;
    }
  }
}
</script>

<style>

</style>