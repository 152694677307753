<template lang="pug">
  div
    //Breadcrumb navigation
    div(v-if="!isLoading" style="color:grey")
      router-link(:to="{ name: 'Appointments'}") Appointments
      span.mx-1 ›
      span {{appointment.subject}}

    div(v-if="isLoading")
      Loader
    div(v-else-if="isError")
      b-alert(variant="danger" show) {{errorMessage}}
    div(v-else)
      div.mb-2
        h2.m-0 {{appointment.subject}}

      b-overlay(:show="isUpdating")
        b-card.border-0
          div.row
            div.col-9.d-flex.flex-column.mb-4
              span(v-if="appointment.cancelled")
                b-badge(variant="danger" aria-label="Appointment is cancelled") Cancelled
              span(v-if="appointment.no_show")
                b-badge(variant="warning") No-show
                                
              span(aria-label="Date")
                b-icon.mr-2(icon="calendar" title="Date" aria-hidden="true")
                | {{ dayjs(appointment.start).format('DD/MM/YYYY') }}
              span(aria-label="Time")
                b-icon.mr-2(icon="clock" title="Time" aria-hidden="true")
                | {{ dayjs(appointment.start).format('h:mm A') }} to {{ dayjs(appointment.start).isSame(appointment.end, 'day') ? dayjs(appointment.end).format('h:mm A') : dayjs(appointment.end).format('DD/MM/YYYY [at] h:mm A') }}
              span(v-if="appointment.location" aria-label="Location")
                b-icon.mr-2(icon="geo-alt" title="Location" aria-hidden="true")
                | {{appointment.location}}
              span(v-if="$store.state.roomlist_mode && getPlace(appointment.room_id)" aria-label="Room")
                b-icon.mr-2(icon="building" aria-hidden="true")
                | {{ getPlace(appointment.room_id).roomListInfo.name }}
              span(v-if="appointment.group" aria-label="Group")
                b-icon.mr-2(icon="folder" title="Group" aria-hidden="true")
                | {{appointment.group}}
              span(v-if="appointment.user_id" aria-label="Organiser")
                b-icon.mr-2(icon="person" title="Organiser" aria-hidden="true")
                | {{appointment.organiser.graph_display_name}}
              span(v-if="attendees.length > 0" aria-label="Attendees")
                b-icon.mr-2(icon="people" title="Attendees" aria-hidden="true")
                span(v-if="appointment.clients.length <= 0") No client specified
                b-badge.mr-1.border(v-for="attendee in attendees" :key="attendee" variant="light") {{attendee}}
              span.text-capitalize(v-if="appointment.mode_of_contact && $store.state.mode_of_contact" aria-label="Mode of Contact")
                b-icon.mr-2(icon="chat-right-dots" title="Mode of Contact" aria-hidden="true")
                | {{ (appointment.mode_of_contact).replace(/_/g, ' ') }}
              span.my-4(v-if="appointment.description" aria-label="Description")
                | {{appointment.description}}

              div(v-if="appointment.cancelled && appointment.cancel_remark")
                h6.mt-3.font-weight-bold Cancel Remarks
                | {{appointment.cancel_remark}}
                

              div.d-flex.justify-content-between.align-items-center.flex-wrap.mt-5
                h5.font-weight-bold
                  b-icon.mr-2(icon="bell" title="Reminder" aria-hidden="true")
                  | Reminder
                div(v-if="appointment.user_id === user.user_id")
                  b-button.mr-1(@click="sendCall()" size="sm" v-if="!$store.state.disable_phone_reminder") Send Call Reminder
                  b-button.mr-1(@click="sendNotification()" size="sm") Send SMS Reminder  
                  b-button(@click="sendWA()" size="sm" v-if="$store.state.wa_enabled") Send WhatsApp Reminder  
              span.text-muted(v-if="!appointment.reminder_schedule && !appointment.reminder_template") No reminder set. Client's default reminder used.
              div(v-else)
                h6.mt-3.font-weight-bold Schedule
                b-card(v-if="appointment.reminder_schedule")
                  div <b>SMS: </b>{{ appointment.reminder_schedule.trigger_schedule.sms.join(", ") }} days before the appointment
                  div <b>Phone: </b>{{ appointment.reminder_schedule.trigger_schedule.phone.join(", ") }} days before the appointment
                span.text-muted(v-else) No reminder schedule set. Client's default schedule used.
                h6.mt-3.font-weight-bold Template
                b-card(v-if="appointment.reminder_template")
                  | {{ appointment.reminder_template.content.reminder }}
                span.text-muted(v-else) No reminder template set. Client's default template used.

            div.col
              div.mb-4(aria-label="Client List")
                h6.font-weight-bold.mb-2(aria-hidden="true") Client(s)
                p.text-muted(v-if="appointment.clients && appointment.clients.length <= 0") No client specified
                div.bg-light.rounded.border.mb-2(v-for="client in appointment.clients" :key="client.id" :aria-label="`Client: ${client.name}`")
                  router-link.px-3.py-2(:to="{ name: 'SingleClient', params: { client_id: client.id }}" tag="div" style="cursor:pointer" role="button" :aria-label="`Name: ${client.name}, Contact Number: ${client.contact_number}`")
                    span.font-weight-bold.mr-1(aria-label="name") {{ client.name }}
                    span(v-if="$store.state.mark_attendance && [0,1,2].includes(client.attendance)")
                      b-badge(v-if="client.attendance === 1" variant="success")
                        b-icon(icon="check")
                        | Attended
                      b-badge(v-if="client.attendance === 0" variant="danger")
                        b-icon(icon="x")
                        | Absent
                      b-badge(v-if="client.attendance === 2" variant="info")
                        b-icon(icon="question")
                        | Indeterminate
                    b-badge.ml-1(v-if="client.declined" variant="danger") Declined
                    div.text-muted(aria-label="Contact Number")
                      b-icon.mr-2(icon="telephone" title="Contact Number" aria-hidden="true")
                      | {{ client.contact_number }}
                  div.mt-1.px-3.pb-2(v-if="($store.state.mark_attendance && !appointment.cancelled && appointment.user_id === user.user_id && ![0,1,2].includes(client.attendance)) && (dayjs().diff(appointment.start) > 0 || $store.state.mark_attendance_anytime)")
                    b-button.mr-1.mb-1(@click="showModal('attended-modal', client)" size="sm" title="Attended" aria-label="Mark as Attended")
                      b-icon(icon="check" aria-hidden="true")
                    b-button.mr-1.mb-1(@click="showModal('absent-modal', client)" size="sm" title="Absent" aria-label="Mark as Absent")
                      b-icon(icon="x" aria-hidden="true")
                    b-button.mr-1.mb-1(@click="showModal('indeterminate-modal', client)" size="sm" title="Indeterminate" aria-label="Mark as Indeterminate")
                      b-icon(icon="question" aria-hidden="true")

              div.mb-4
                b-button(v-b-modal.cancel-modal v-if="(!appointment.cancelled && !appointment.no_show && appointment.user_id === user.user_id) && (dayjs().diff(appointment.end) < 0 || $store.state.cancel_anytime)" block)
                  b-icon.mr-2(icon="calendar-x" aria-hidden="true")
                  | Cancel Appointment
                b-button(v-b-modal.noshow-modal block v-if="(!$store.state.mark_attendance && !appointment.cancelled && !appointment.no_show && appointment.user_id === user.user_id) && (dayjs().diff(appointment.start) > 0 || $store.state.mark_attendance_anytime)")
                  b-icon.mr-2(icon="person-x" aria-hidden="true")
                  | No Show
                router-link.btn-block(v-if="appointment.user_id === user.user_id" :to="{ name: 'EditAppointment', params: { appointment_id: $route.params.appointment_id } }" tag="b-button" role="button")
                  b-icon.mr-2(icon="pencil-square" aria-hidden="true")
                  | Edit Appointment
                b-button(:to="'create?duplicate='+$route.params.appointment_id" block role="button")
                  b-icon.mr-2(icon="files" aria-hidden="true")
                  | Duplicate Appointment
                  
      b-modal(id="cancel-modal" title="Cancel Appointment?" @ok="patchAppointment({cancelled:true,cancel_remark:cancelRemark,notify:cancelAppointmentNotify})" cancel-variant="outline-secondary" ok-variant="danger" ok-title="Yes" cancel-title="No")
        p Are you sure you want to cancel this appointment?
        b-form-group(label="Remarks (optional)")
          b-form-textarea(v-model="cancelRemark")
        b-form-group
          b-form-checkbox(v-model="cancelAppointmentNotify") Notify client of appointment cancellation

      b-modal(id="noshow-modal" title="Mark as No-show?" @ok="patchAppointment({no_show:true,notify:false})" cancel-variant="outline-secondary" ok-variant="secondary" ok-title="Yes, mark as no-show" cancel-title="No")
        p Are you sure you want to set this appointment as no-show. This action cannot be undone.

      b-modal(id="attended-modal" v-if="$store.state.mark_attendance" :title="`Mark ${modalClient.name} as Attended?`" @ok="updateAttendance({attendance:1,client_id:modalClient.id})" cancel-variant="outline-secondary" ok-variant="secondary" ok-title="Yes, mark as attended" cancel-title="No")
        p Are you sure you want to mark the attendance for {{modalClient.name}} as attended. This action cannot be undone.

      b-modal(id="absent-modal" v-if="$store.state.mark_attendance" :title="`Mark ${modalClient.name} as Absent?`" @ok="updateAttendance({attendance:0,client_id:modalClient.id})" cancel-variant="outline-secondary" ok-variant="secondary" ok-title="Yes, mark as absent" cancel-title="No")
        p Are you sure you want to mark the attendance for {{modalClient.name}} as absent. This action cannot be undone.

      b-modal(id="indeterminate-modal" v-if="$store.state.mark_attendance" :title="`Mark ${modalClient.name} as Indeterminate?`" @ok="updateAttendance({attendance:2,client_id:modalClient.id})" cancel-variant="outline-secondary" ok-variant="secondary" ok-title="Yes, mark as indeterminate" cancel-title="No")
        p Are you sure you want to mark the attendance for {{modalClient.name}} as indeterminate. This action cannot be undone.
</template>
<script>
import dayjs from 'dayjs';
import Loader from '@/components/loader/Loading.vue';

export default {
  name: 'SingleAppointment',
  components: {
    Loader,
  },
  data() {
    return {
      isLoading: true,
      isUpdating: false,
      isError: false,
      cancelRemark: "",
      cancelAppointmentNotify: true,
      errorMessage: '',
      modalClient: {
        name: '',
        id: null,
      }
    }
  },
  computed: {
    user(){
      return this.$store.state.user;
    },
    appointment(){
      return this.$store.state.appointment;
    },
    places(){
      return this.$store.state.places;
    },
    attendees() {
      let output = [];
      if(!this.appointment.attendees || this.appointment.attendees.length < 1) {
        return output;
      }
      for(let attendee of this.appointment.attendees) {
        if(attendee.type==='resource') {continue;}
        output.push(attendee.emailAddress.address);
      }
      return output;
    }
  },
  async mounted() {
    await Promise.all([
      this.getAppointment(),
      this.fetchPlaces()
    ]);

    this.isLoading = false;
  },
  methods: {
    dayjs,
    async getAppointment() {
      await this.$store.dispatch('getAppointment',this.$route.params.appointment_id).catch((err)=>{
        this.isError = true;
        this.errorMessage = err ? err : 'Error';
      })
    },
    async sendNotification() {
      this.isUpdating = true;
      await this.$store.dispatch('sendNotification',{payload: { type: 'sms' }, appointment_id: this.$route.params.appointment_id}).then(()=>{
        //change this to modal
        window.alert("SMS sent successfully");
      }).catch((err)=>{
        window.alert(err);
        // todo: error message
      })
      this.isUpdating = false;
    },
    async sendWA() {
      this.isUpdating = true;
      await this.$store.dispatch('sendNotification',{payload: { type: 'wa' }, appointment_id: this.$route.params.appointment_id}).then(()=>{
        //change this to modal
        window.alert("SMS sent successfully");
      }).catch((err)=>{
        window.alert(err);
        // todo: error message
      })
      this.isUpdating = false;
    },
    async sendCall() {
      this.isUpdating = true;
      await this.$store.dispatch('sendNotification',{payload: { type: 'phone' }, appointment_id: this.$route.params.appointment_id}).then(()=>{
        //change this to modal
        window.alert("Call sent successfully");
      }).catch((err)=>{
        window.alert(err);
        // todo: error message
      })
      this.isUpdating = false;
    },
    async patchAppointment(payload) {
      this.isUpdating = true;
      await this.$store.dispatch('patchAppointment',{payload: payload, appointment_id: this.$route.params.appointment_id}).then(()=>{
        //window.location.href = `/appointments/${result.id}`;
        this.getAppointment();
      }).catch(()=>{
          
        // todo: error message
      })
      this.isUpdating = false;
    },
    async fetchPlaces() {
      await this.$store.dispatch('fetchAllPlaces');
    },
    getPlace(roomId) {
      if(!this.places) { return null }
      return this.places.find(room => room.id === roomId);
    },
    showModal(modalId, {id,name}) {
      this.modalClient = {
        id,
        name
      };

      this.$bvModal.show(modalId);
    },
    async updateAttendance(payload) {
      this.isUpdating = true;
      await this.$store.dispatch('updateAttendance',{payload: payload, appointment_id: this.$route.params.appointment_id}).then(async ()=>{
        await this.getAppointment();
      }).catch((err)=>{
        window.alert(err);
      });

      this.$bvModal.hide('attended-modal');
      this.$bvModal.hide('absent-modal');
      this.$bvModal.hide('indeterminate-modal');
      //reset
      this.modalClient = {
        name: '',
        id: null,
      }
      this.isUpdating = false;
    },
  }
}
</script>