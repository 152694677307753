<template lang="pug">
  .d-flex.flex-column.align-items-center.justify-content-center.text-center(
      style="min-height:100vh"
      class='animate__animated animate__shakeX'
    )
    fa-icon(icon="unlink" size="6x")
    h1 404 Not Found
    p We're sorry, but you seemed to have found a broken link :(
    p 
      router-link(to="/") Back to Home

</template>
<script>

export default {
  name: 'NotFound',
  mounted(){
    this.$store.dispatch('notfound', true);
  },
  beforeDestroy(){
    this.$store.dispatch('notfound', false);
  }
}
</script>