<template>
    <svg version="1.1" id="svg-logo-no-text" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 974.55 1000.06" style="enable-background:new 0 0 974.55 1000.06;" xml:space="preserve">
        <g>
            <polyline class="st0 logo-the-rest" points="745.37,667.8 496.31,815.48 353.95,732.38      "/>
            <polygon class="st0 human human-body" points="783.41,482.43 640.73,566.75 497.44,482.43 341.23,573.62 341.22,573.62 194.22,487.59 
                493.05,312.93"/>
            <polyline class="st0 logo-the-rest" points="341.23,573.62 484.13,657.24 353.95,732.38 204.21,818.8 496.5,979.8 783.41,814.13 644.89,732.34 
                        "/>
            <line class="st0 logo-the-rest the-line" x1="225.38" y1="641.24" x2="341.22" y2="573.62"/>
            <path class="st0 logo-the-rest" d="M61.77,635.43"/>
            <polygon class="st0 boxes box-left" points="226.33,595.51 124.55,536.75 22.77,595.51 22.77,713.04 124.55,771.8 226.33,713.04       "/>
            <circle class="st0 human human-head" cx="491.1" cy="149.67" r="125.86"/>
            <polygon class="st0 boxes box-right" points="954.47,589.46 852.69,530.69 750.9,589.46 750.9,706.98 852.69,765.75 954.47,706.98      "/>
        </g>
    </svg>
</template>

<script>
export default {
        name: 'LogoWithoutText'
}
</script>

<style lang="scss">
    .st0{
        fill:none;
        stroke: $primarytext;
        stroke-width:10;
        stroke-miterlimit:10;
        stroke-linecap: round;
        }
    
    .the-line {
        stroke-linecap: butt;
    }
</style>