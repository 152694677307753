<template lang="pug">
div
    //Breadcrumb navigation
    div(style="color:grey")
        router-link(to="/reminders") Reminders
        span.mx-1 ›
        router-link(to="/reminders?tab=schedule") Schedule
        span.mx-1 ›
        span {{readonly ? 'View' : 'Edit'}} Reminder schedule

    h2 {{readonly ? 'View' : 'Edit'}} Reminder Schedule

    b-card.border-0(style="max-width: 1280px")
        b-form(@submit.prevent="editReminderSchedule")
            b-form-group
                <label for="sms">
                    b-icon.mr-1(icon="phone")
                    | SMS
                </label>
                div.form-inline.mb-2
                    <div class="input-group mr-2" v-if="!readonly">
                        //Ensures that the SMS days only accept a number & minimum of 0 day for reminder
                        b-form-input(id="sms" v-model.number="smsField" min="0" type="number" oninput="validity.valid||(value='');" style="width:5em")
                        <div class="input-group-append">
                            <div class="input-group-text">day</div>
                        </div>
                    </div>
                    b-button(@click="addSMS"  v-if="!readonly")
                        b-icon.mr-1(icon="plus-circle-fill" title="Add reminder day")
                        | Add

                div.border.rounded.px-2.my-1(v-for="(day, index) in reminder_schedule.trigger_schedule.sms" style="width:8em")
                    div.d-flex.justify-content-between.align-items-center
                        | {{day}} day
                        b-button.px-0(@click="removeSMS(index)" variant="link")
                            b-icon(icon="x-circle" title="Remove reminder day"  v-if="!readonly")

            b-form-group(v-if="!$store.state.disable_phone_schedule")
                <label for="phone">
                    b-icon.mr-1(icon="telephone")
                    | Phone
                </label>
                div.form-inline.mb-2
                    <div class="input-group mr-2"  v-if="!readonly">
                        b-form-input(id="phone" v-model="phoneField" type="number" style="width:5em")
                        <div class="input-group-append">
                            <div class="input-group-text">day</div>
                        </div>
                    </div>
                    b-button(@click="addPhone"  v-if="!readonly")
                        b-icon.mr-1(icon="plus-circle-fill" title="Add reminder day")
                        | Add

                div.border.rounded.px-2.my-1(v-for="(day, index) in reminder_schedule.trigger_schedule.phone" style="width:8em")
                    div.d-flex.justify-content-between.align-items-center
                        | {{day}} day
                        b-button.px-0(@click="removePhone(index)" variant="link"  v-if="!readonly")
                            b-icon(icon="x-circle" title="Remove reminder day")

            b-form-group(label="Name (Optional)" label-for="name")
                b-form-input(id="name" v-model="reminder_schedule.name" type="text" placeholder="Add a name" :readonly="readonly")

            b-form-group(v-if="$store.state.groups_enabled && (!this.$store.state.group_based_reminder_permission || userIsMainGroupOwner)" label="Group" label-for="group")
                b-form-select(id="group" v-model="reminder_schedule.group_id" :options="groupOptions" :disabled="readonly")

            b-form-group(label="Visibility" label-for="visibility" v-if="!this.$store.state.group_based_reminder_permission || user.is_admin")
                b-form-select(id="visibility" v-model="reminder_schedule.visibility" :disabled="readonly")
                    option(value="1") Private
                    option(value="2") Public
                    
            b-button(type="submit" v-if="!readonly") Save
</template>

<script>
export default {
    name: 'EditReminderSchedule',
    data() {
        return {
            readonly:true,
            reminder_schedule: {
                trigger_schedule: {
                    sms: [],
                    phone: []
                },
                name: "",
                visibility: null,
                group_id: ""
            },
            smsField: null,
            phoneField: null,
            groupOptions: [{ value: "", text: "" }],
        };
    },
    computed: {
        user(){
            return this.$store.state.user;
        },
        userIsMainGroupOwner() {
            //return if logged in user is a centre group owner (centre head)
            if(this.user.user_owned_groups_w_details && this.user.user_owned_groups_w_details.length) {
                let groups = this.user.user_owned_groups_w_details;
                if(this.$store.state.group_main_types && this.$store.state.group_main_types.length) {
                    groups = this.user.user_owned_groups_w_details.filter(grp=>this.$store.state.group_main_types.includes(grp.group_type));
                }
                
                if(groups.length) {
                    return true;
                }
            }
            return false;
        },
        user_groups(){
            return this.$store.state.user_groups||[];
        },
    },
    async mounted() {
        this.getReminderSchedule();

        await this.getUserGroups();
        for(let group of this.user_groups) {
            let mainGroup = this.$store.state.group_main_types;
            if(mainGroup && !mainGroup.includes(group.group_type)) {
                continue;
            }
            if(this.$store.state.group_based_reminder_permission && !group.owner) {
                continue;
            }
            this.groupOptions.push({
                value: group.id,
                text: group.group_name
            });
        }
    },
    methods: {
        addSMS() {
            //console.log(this.smsField);
            if(parseInt(this.smsField) >= 0) {
                this.reminder_schedule.trigger_schedule.sms.push(this.smsField);
                this.smsField = null;
            }
        },
        removeSMS(index) {
            this.reminder_schedule.trigger_schedule.sms.splice(index, 1);
        },
        addPhone() {
            if(parseInt(this.phoneField) >= 0) {
                this.reminder_schedule.trigger_schedule.phone.push(this.phoneField);
                this.phoneField = null;
            }
        },
        removePhone(index) {
            this.reminder_schedule.trigger_schedule.phone.splice(index, 1);
        },
        async getReminderSchedule() {
            await this.$store.dispatch('getReminderSchedule',this.$route.params.reminder_schedule_id)
            .then(result=>{
                this.reminder_schedule.trigger_schedule.sms = result.trigger_schedule.sms;
                this.reminder_schedule.trigger_schedule.phone = result.trigger_schedule.phone;
                this.reminder_schedule.name = result.name;
                this.reminder_schedule.visibility = result.visibility;
                this.reminder_schedule.group_id = result.group_id;
                
                if(result.user_id == this.user.user_id || this.user.is_admin || (this.userIsMainGroupOwner && this.user.user_owned_groups_w_details.map(grp=>grp.id).includes(result.group_id))){
                    this.readonly= false;
                }
            })
            .catch((err)=>{
                console.log(err);

                // todo: display error 404
            });
        },
        async editReminderSchedule() {
            this.$store.dispatch('patchReminderSchedule',{payload: this.reminder_schedule, reminder_schedule_id: this.$route.params.reminder_schedule_id}).then(()=>{
                window.location.href = `/reminders`;
            }).catch((err)=>{
                console.log(err);
                window.alert(err?.response?.data?.error||err);
                // todo: error message
            })
        },
        async getUserGroups() {
            await this.$store.dispatch('getUserGroups');
        },
    }
}
</script>

<style>

</style>