<template lang="pug">
div
  .d-flex.justify-content-between.align-items-end.flex-wrap.mb-3
    b-button(to="/client-group/create")
      b-icon(icon="plus")
      | New {{ $.clientGroup }}
    b-form(inline, @submit.prevent)
      b-form.ml-3(inline, @submit.prevent)
        label.mr-1 List:
        b-form-select(
          v-model="listBy",
          :options="listByOptions",
          @change="updateTable",
          style="max-width: 10em"
        )

      b-form.ml-3(inline, @submit.prevent)
        label.mr-1(for="status") Status:
        b-form-select#status(v-model="isListActive", @change="updateTable")
          option(value="") All
          option(:value="true") {{$.active}}
          option(:value="false") {{$.inactive}}

      b-form.ml-3(inline, @submit.prevent="updateTable")
        //label.mr-1(for="status") Status:
        b-form-select.border-right-0(
          v-model="filterby",
          style="border-radius: 0.25rem 0 0 0.25rem"
        )
          option(value="name") {{ $.clientGroup }}
          option(value="clients") Clients
          option(value="case_id") Case ID
        b-input-group
          b-form-input.rounded-0.border-right-0(
            v-if="filterby === 'name'",
            type="text",
            v-model="filter",
            placeholder="Search"
            )
          b-input-group-append
            b-button#clearsearch(type="submit" @click="filter = ''")
              b-icon#clearicon(icon="x-circle")
        b-form-input.rounded-0.border-0(
          v-if="filterby === 'clients'",
          type="text",
          v-model="filter",
          placeholder="Search"
        )
        b-form-input.rounded-0.border-0(
          v-if="filterby === 'case_id'",
          type="text",
          v-model="filter",
          placeholder="Search"
        )
        
        b-button(type="submit", style="border-radius: 0 0.25rem 0.25rem 0" @click="hidealert = !hidealert")
          b-icon(icon="search")
  b-alert(
    :show="!hidealert"
    @dismissed="hidealert = false"
    fade
    dismissible
  ) Results: {{arraycount}} entry&#40;s&#41; found

  .d-flex.justify-content-center.align-items-center(
    v-if="loading",
    style="min-height: 10em"
  )
    b-spinner
  div(v-else)
    b-overlay(:show="this.loadingTable", variant="white")
      b-table(
        :items="fetchedClientGroups",
        :fields="fields",
        ref="table",
        :per-page="perPage",
        :current-page="currentPage",
        show-empty
      )
        template(#cell(name)="data")
          span {{ data.item.name }}
            b-badge.ml-1(variant="danger", v-if="data.item.active === 0") {{ $.inactive }}
        template(#cell(date_created)="data")
          | {{ dayjs(data.item.date_created).format("DD/MM/YYYY") }}
        template(#cell(clients)="data")
          span.text-muted.font-italic(v-if="data.item.clients.length <= 0") No client specified
          b-badge.border.mr-1.mb-1.p-1(
            v-for="client in data.item.clients",
            :key="client.id",
            variant="light"
          ) {{ client.name }}
        template(#cell(case_id)="data")
          b-badge.border.mr-1.mb-1.p-1(
            v-for="client in data.item.clients",
            :key="client.id",
            variant="light"
          ) {{ client.case_id }}
        template(#cell(actions)="data")
          b-button(class="mr-1" :to="'/client-group/'+(data.item.id)") More Info
          b-button(v-if="data.item.user_id == user.user_id || (listBy.type==='group' && $store.state.group_member_can_edit_client)" class="mr-1" :to="'/client-group/'+(data.item.id)+'/edit'" variant="outline-secondary")
            b-icon.mr-1(icon="pencil-square")
            | Edit
          </b-button>

      b-pagination.m-0(
        v-model="currentPage",
        :total-rows="totalRows",
        :per-page="perPage"
      )
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "ClientGroupList",
  data() {
    let fields = [
      { key: "name", name: "Name", sortable: true },
      { key: "clients", name: "Clients", sortable: false },
      { key: "case_id", name: "Case ID", sortable: true },
      { key: "owner", name: "Owner", sortable: false },
      { key: "date_created", name: "Created On", sortable: true },
      "actions",
    ];
    if (this.$store.state.groups_enabled) {
      fields.splice(3, 0, {
        key: "group",
        name: "Group",
        sortable: false,
        formatter: (v) => v?.group_name || "-",
      });
    }
    return {
      arraycount:0,
      hidealert: true,
      loading: false,
      loadingTable: true,
      totalRows: 0,
      currentPage: 1,
      perPage: 20,
      listBy: 1,
      isListActive: true,
      filterby: "name",
      filter: "",
      fields,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    localisation() {
      return this.$store.state.localisation;
    },
    $() {
      let output = {
        clientGroup: "Client Group",
        active: "Active",
        inactive: "Inactive",
        //"clientCase": "Case ID"
      };

      Object.assign(output, this.localisation);
      return output;
    },
    listByOptions() {
      let output = [
        { value: 0, text: "All" },
        { value: 1, text: "My" },
      ];

      let userOwnedGroups = this.$store.state.group_view_all_client
        ? this.user.user_groups_w_details
        : this.user.user_owned_groups_w_details;
      if (userOwnedGroups && userOwnedGroups.length) {
        let allowedGroups = userOwnedGroups;
        let mainGroup = this.$store.state.group_main_types;
        let supervisorGroup = this.$store.state.group_supervisor_types;
        let allowedGroupTypes = [];

        if (supervisorGroup && supervisorGroup.length > 0) {
          let groups = userOwnedGroups.filter(
            (group) => supervisorGroup.includes(group.group_type) && group.owner
          );
          if (groups.length) {
            output.push({ value: 2, text: "Supervisee" });
          }
        }

        if (mainGroup && mainGroup.length > 0) {
          allowedGroupTypes.push(...mainGroup);
        }

        if (allowedGroupTypes.length) {
          allowedGroups = userOwnedGroups.filter((group) =>
            allowedGroupTypes.includes(group.group_type)
          );
        }

        if (allowedGroups.length) {
          output.push({
            label: "Groups / Teams",
            options: allowedGroups.map((grp) => {
              return {
                value: { type: "group", id: grp.id },
                text: grp.group_name,
              };
            }),
          });
        }
      }

      return output;
    },
  },
  created() {
    //this.fields.splice(0,0,{key: "case_id", label: this.$.clientCase, sortable:true});
    this.fields.splice(0, 0, {
      key: "name",
      label: this.$.clientGroup,
      sortable: true,
    });
  },
  mounted() {
    // let clientdata = this.$store.state.clients.value;
    // let result = clientdata.map((a) => a.case_id);
    // console.log(result);
  },
  methods: {
    dayjs,
    banner(){

    },
    updateTable() {
      if (this.loading) {
        return;
      }
      this.$refs.table.refresh();
      this.currentPage = 1;
    },
    async fetchedClientGroups(ctx) {
      this.loadingTable = true;

      // fetchAllClients - fetch all client including groups members client
      // fetchUserClients - fetch all logged in user client, exclude groups members client
      let action =
        this.listBy === 1 ? "fetchUserClientGroups" : "fetchAllClientGroups";

      // prepare url params
      let params = {
        top: ctx.perPage,
        skip: ctx.perPage * (ctx.currentPage - 1),
        orderby: ctx.sortBy,
        order: ctx.sortDesc ? "desc" : "asc",
        active: this.isListActive,
      };
      // list by supervisor group
      if (this.listBy === 2) {
        params = { ...params, grouptype: "supervisor" };
      }
      // filter by group
      if (this.listBy?.type == "group") {
        params = { ...params, group_id: this.listBy.id };
      }
      // search function
      if (this.filterby && this.filter) {
        params = { ...params, filterby: this.filterby, filter: this.filter };
      }

      // request

      let items = await this.$store
        .dispatch(action, params)
        .then((result) => {
          let res = null;
          if (this.filterby == "case_id" && this.filter) {
            res = result.value.filter((r) => {
              return r.clients.filter((c) => {
                this.arraycount = (c.case_id + "").includes(this.filter);
                return (c.case_id + "").includes(this.filter)
              }).length
            }
            ) || [];
          }


          if (this.filterby == "clients" && this.filter) {
            res =
              result.value.filter((r) =>
                r.clients.filter((c) => {
                  this.arraycount = c.name.toLowerCase().includes(this.filter.toLowerCase().length);
                  return c.name.toLowerCase().includes(this.filter.toLowerCase())
          }).length
              ) || [];
          }

          this.totalRows = result.count;
          result.value = res ? res : result.value;
          this.arraycount = result.value.length;
          return result.value;
        })
        .catch(() => {
          return [];
        });

      this.loadingTable = false;

      return items;
    },
  },
};
</script>

<style>
#clearsearch{
  background-color: transparent !important;
  border:1px solid #ced4da !important;
  border-left: transparent !important;
}
#clearicon{
    color: #383874;
  }

</style>