<template lang="pug">
.hello
  h1 {{ msg }}
  p {{submsg}}

</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
    submsg :String
  }
}
</script>
