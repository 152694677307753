import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

import appointment from './appointment';
import client from './client';
import clientGroup from './client_group';
import reminderSchedule from './reminder_schedule';
import reminderTemplate from './reminder_template';
import admin from './admin';
const {VUE_APP_IDLE_EXPIRY} = process.env;

Vue.use(Vuex)

Vue.mixin({
  computed:{
    user(){
      return this.$store.state.user
    },
    login(){
      return !!this.$store.state.user
    },
    loaded(){
      return !!this.$store.state.loaded
    },
    offline(){
      return !!this.$store.state.offline
    }
  }
});


const $ = axios.create({
  baseURL:window.location.origin+'/api/'
});

export default new Vuex.Store({
  state: {
    loaded: false,
    offline: false,
    user: null,
    users: null,
    user_groups:[],
    login_enabled:{},
    wa_enabled: false,
    zoom_enabled:false,
    zoom_global:false,
    groups_enabled: false,
    group_main_types: false,
    group_supervisor_types: false,
    roomlist_mode: false,
    no_public_clients: false,
    optional_client_contact_number: false,
    allow_reassign_client: false,
    mode_of_contact: false,
    no_case_id: false,
    allow_graph_attendees: false,
    allow_inactive_client_group: false,
    client_require_client_group: false,
    appointment_default_view: null,
    group_based_reminder_permission: false,
    group_member_can_edit_client: false,
    group_view_all_appointment: false,
    group_view_all_client: false,
    mark_attendance: false,
    sms_broadcast: false,
    audit_log: false,
    mark_attendance_anytime: false,
    cancel_anytime: false,
    disable_phone_reminder: false,
    disable_phone_schedule: false,
    optional_appointment_client: false,
    enable_client_nickname: false,
    enable_recurring: false,
    notfound: false,
    user_info: null,
    placefinder: false,
    localisation: {},
    risk_monitoring:false,
    ...appointment.state,
    ...client.state,
    ...clientGroup.state,
    ...reminderSchedule.state,
    ...reminderTemplate.state,
    ...admin.state,
  },
  mutations: {
    user_groups(state,user_groups){
      state.user_groups = user_groups;
    },
    no_public_clients(state,no_public_clients){
      state.no_public_clients = no_public_clients;
    },
    optional_client_contact_number(state,optional_client_contact_number){
      state.optional_client_contact_number = optional_client_contact_number;
    },
    allow_reassign_client(state,allow_reassign_client){
      state.allow_reassign_client = allow_reassign_client;
    },
    mode_of_contact(state,mode_of_contact){
      state.mode_of_contact = mode_of_contact;
    },
    no_case_id(state,no_case_id){
      state.no_case_id = no_case_id;
    },
    allow_graph_attendees(state,allow_graph_attendees){
      state.allow_graph_attendees = allow_graph_attendees;
    },
    allow_inactive_client_group(state,allow_inactive_client_group){
      state.allow_inactive_client_group = allow_inactive_client_group;
    },
    client_require_client_group(state,client_require_client_group){
      state.client_require_client_group = client_require_client_group;
    },
    appointment_default_view(state,appointment_default_view){
      state.appointment_default_view = appointment_default_view;
    },
    group_based_reminder_permission(state,group_based_reminder_permission){
      state.group_based_reminder_permission = group_based_reminder_permission;
    },
    group_member_can_edit_client(state,group_member_can_edit_client){
      state.group_member_can_edit_client = group_member_can_edit_client;
    },
    group_view_all_appointment(state,group_view_all_appointment){
      state.group_view_all_appointment = group_view_all_appointment;
    },
    group_view_all_client(state,group_view_all_client){
      state.group_view_all_client = group_view_all_client;
    },
    mark_attendance(state,mark_attendance){
      state.mark_attendance = mark_attendance;
    },
    sms_broadcast(state,sms_broadcast){
      state.sms_broadcast = sms_broadcast;
    },
    audit_log(state,audit_log){
      state.audit_log = audit_log;
    },
    mark_attendance_anytime(state,mark_attendance_anytime){
      state.mark_attendance_anytime = mark_attendance_anytime;
    },
    cancel_anytime(state,cancel_anytime){
      state.cancel_anytime = cancel_anytime;
    },
    disable_phone_reminder(state,disable_phone_reminder){
      state.disable_phone_reminder = disable_phone_reminder;
    },
    disable_phone_schedule(state,disable_phone_schedule){
      state.disable_phone_schedule = disable_phone_schedule;
    },
    optional_appointment_client(state,optional_appointment_client){
      state.optional_appointment_client = optional_appointment_client;
    },
    enable_client_nickname(state,enable_client_nickname){
      state.enable_client_nickname = enable_client_nickname;
    },
    enable_recurring(state,enable_recurring){
      state.enable_recurring = enable_recurring;
    },
    login_enabled(state,login_enabled){
      state.login_enabled = login_enabled;
    },
    wa_enabled(state,wa_enabled){
      state.wa_enabled = wa_enabled;
    },
    groups_enabled(state,groups_enabled){
      state.groups_enabled = groups_enabled;
    },
    group_main_types(state,group_main_types){
      state.group_main_types = group_main_types;
    },
    group_supervisor_types(state,group_supervisor_types){
      state.group_supervisor_types = group_supervisor_types;
    },
    zoom_global(state,zoom_global){
      state.zoom_global = zoom_global;
    },
    zoom_enabled(state,zoom_enabled){
      state.zoom_enabled = zoom_enabled;
    },
    roomlist_mode(state,roomlist_mode){
      state.roomlist_mode = roomlist_mode;
    },
    placefinder(state,placefinder){
      state.placefinder = placefinder;
    },
    localisation(state,localisation){
      state.localisation = localisation;
    },
    user(state,user){
      state.user = user;
    },
    users(state,users){
      state.users = users;
    },
    loaded(state, load = true){
        state.loaded = load;
    },
    offline(state, offline = true){
      state.offline = offline;
    },
    notfound(state, notfound = true){
      state.notfound = notfound;
    },
    risk_monitoring(stats, risk_monitoring=false){
      stats.risk_monitoring =risk_monitoring;
    },
    user_info(state, user_info){
      state.user_info = user_info;
    },
    ...appointment.mutations,
    ...client.mutations,
    ...clientGroup.mutations,
    ...reminderSchedule.mutations,
    ...reminderTemplate.mutations,
    ...admin.mutations,
  },
  actions: {
    async init(store){
      const {data:{result}} = await $.get('login?check=1');
      // load localisation
      const localisationRes = await $.get('localisation').catch(()=>{return false;});
      if(localisationRes) {
        store.commit('localisation',localisationRes.data.result);
      }
      store.commit('loaded');
      if(result.login){
        store.commit('user',result.user);
        store.dispatch('autoLogout');
      }
      if(result.login_enabled){
        store.commit('login_enabled',result.login_enabled);
      }
      if(result.wa_enabled){
        store.commit('wa_enabled',result.wa_enabled);
      }
      if(result.zoom_enabled){
        store.commit('zoom_enabled',result.zoom_enabled);
      }
      if(result.zoom_global){
        store.commit('zoom_global',result.zoom_global);
      }
      if(result.groups_enabled){
        store.commit('groups_enabled',result.groups_enabled);
      }
      if(result.group_main_types){
        store.commit('group_main_types',result.group_main_types);
      }
      if(result.group_supervisor_types){
        store.commit('group_supervisor_types',result.group_supervisor_types);
      }
      if(result.roomlist_mode){
        store.commit('roomlist_mode',result.roomlist_mode);
      }
      if(result.placefinder){
        store.commit('placefinder',result.placefinder);
      }
      if(result.no_public_clients){
        store.commit('no_public_clients',result.no_public_clients);
      }
      if(result.optional_client_contact_number){
        store.commit('optional_client_contact_number',result.optional_client_contact_number);
      }
      if(result.allow_reassign_client){
        store.commit('allow_reassign_client',result.allow_reassign_client);
      }
      if(result.mode_of_contact){
        store.commit('mode_of_contact',result.mode_of_contact);
      }
      if(result.no_case_id){
        store.commit('no_case_id',result.no_case_id);
      }
      if(result.allow_graph_attendees){
        store.commit('allow_graph_attendees',result.allow_graph_attendees);
      }
      if(result.allow_inactive_client_group){
        store.commit('allow_inactive_client_group',result.allow_inactive_client_group);
      }
      if(result.client_require_client_group){
        store.commit('client_require_client_group',result.client_require_client_group);
      }
      if(result.appointment_default_view){
        store.commit('appointment_default_view',result.appointment_default_view);
      }
      if(result.group_based_reminder_permission){
        store.commit('group_based_reminder_permission',result.group_based_reminder_permission);
      }
      if(result.group_member_can_edit_client){
        store.commit('group_member_can_edit_client',result.group_member_can_edit_client);
      }
      if(result.group_view_all_appointment){
        store.commit('group_view_all_appointment',result.group_view_all_appointment);
      }
      if(result.group_view_all_client){
        store.commit('group_view_all_client',result.group_view_all_client);
      }
      if(result.mark_attendance){
        store.commit('mark_attendance',result.mark_attendance);
      }
      if(result.sms_broadcast){
        store.commit('sms_broadcast',result.sms_broadcast);
      }
      if(result.audit_log){
        store.commit('audit_log',result.audit_log);
      }
      if(result.mark_attendance_anytime){
        store.commit('mark_attendance_anytime',result.mark_attendance_anytime);
      }
      if(result.cancel_anytime){
        store.commit('cancel_anytime',result.cancel_anytime);
      }
      if(result.enable_client_nickname){
        store.commit('enable_client_nickname',result.enable_client_nickname);
      }
      if(result.enable_recurring){
        store.commit('enable_recurring',result.enable_recurring);
      }
      if(result.disable_phone_reminder){
        store.commit('disable_phone_reminder',result.disable_phone_reminder);
      }
      if(result.optional_appointment_client){
        store.commit('optional_appointment_client',result.optional_appointment_client);
      }
      if(result.disable_phone_schedule){
        store.commit('disable_phone_schedule',result.disable_phone_schedule);
      }
      if(result.risk_monitoring){
        store.commit('risk_monitoring',result.risk_monitoring);
      }
    },
    autoLogout(){
      if(!VUE_APP_IDLE_EXPIRY){
        return;
      }
      
      const bc = BroadcastChannel ? new BroadcastChannel('inter_tab') : null ;
      bc?.addEventListener?.('message', ev=>{
        let {data} = ev;
        if(data =='SessionExpire'){
          doLogout();
        }
      })


      const expiry = Number(VUE_APP_IDLE_EXPIRY) * 60000 //timeout expiry in minutes
      let t;
      let exited = false
      function doLogout(){
        if(exited){
          return;
        }
        exited = true;
        alert('Your session has expired due to inactivity. Please login again.\n\nFor security reasons, we log users out after '+VUE_APP_IDLE_EXPIRY+'min of inactivity');
        window.location.href="/api/logout";

      }
      function logout(){
        let check = Number(window.localStorage.getItem('timeout'));

        if(check > Date.now()){
          return;
        }
        bc?.postMessage?.('SessionExpire');
        doLogout();
      }
      
      function resetTimer(){
        if(t){
          clearTimeout(t);
        }
        t = setTimeout(logout, expiry);
        window.localStorage.setItem('timeout', String(Date.now()+expiry));
      }

      resetTimer();
        window.addEventListener('click',resetTimer);
        window.addEventListener('scroll',resetTimer);
        window.addEventListener('keypress',resetTimer);
        window.addEventListener('mousemove',resetTimer);
        window.addEventListener('mousedown',resetTimer);


    },
    async notfound(store,notfound){
      store.commit('notfound', notfound);
    },
    async getUserGroups(store){
      const {data:{result}} = await $.get(`groups`);
      store.commit('user_groups',result);
      return result;
    },
    async getAllUser(store,payload={}) {
      let queryParams = new URLSearchParams(payload);

      const {data:{result}} = await $.get(`user?${queryParams.toString()}`);
      store.commit('users',result);
      return result;
    },
    async getUserInfo(store) {
      if(!this.state.user){
        return;
      }

      const {data:{result}} = await $.get(`user/me`);
      store.commit('user_info',result);
      return result;
    },
    async patchUserInfo(store, { payload }){
      if(!this.state.user){
        return;
      }

      const {data:{result}} = await $.patch(`user/me`, payload);
      store.commit('user_info', result);
      return result;
    },
    ...appointment.actions,
    ...client.actions,
    ...clientGroup.actions,
    ...reminderSchedule.actions,
    ...reminderTemplate.actions,
    ...admin.actions,
  },
  modules: {
  }
})
