import axios from 'axios';

const $ = axios.create({
    baseURL:window.location.origin+'/api/'
});

export default {
    state: {
        reminder_schedule: {},
        reminder_schedules: []
    },
    mutations: {
        reminder_schedule(state, reminder_schedule) {
            state.reminder_schedule = reminder_schedule;
        },
        reminder_schedules(state, reminder_schedules) {
            state.reminder_schedules = reminder_schedules;
        }
    },
    actions: {
        async fetchAllReminderSchedule(store) {
            const {data:{result}} = await $.get(`reminder_schedule`);
            store.commit('reminder_schedules',result);
            return result;
        },
        async fetchGroupReminderSchedule(store) {
            const {data:{result}} = await $.get(`reminder_schedule?group=1`);
            store.commit('reminder_schedules',result);
            return result;
        },
        async getReminderSchedule(store, reminderScheduleId) {
            const {data:{result}} = await $.get(`reminder_schedule/${reminderScheduleId}`);
            store.commit('reminder_schedule',result);
            return result;
        },
        async addReminderSchedule(store, {payload}) {
            const {data: {result}} = await $.post(`reminder_schedule`, payload);
            
            return result;
        },
        async patchReminderSchedule(store, { payload, reminder_schedule_id }){
            const {data:{result}} = await $.patch(`reminder_schedule/${reminder_schedule_id}`, payload);
            store.commit('reminder_schedule', result);
            return result;

        },
        async deleteReminderSchedule(store, reminderScheduleId) {
            const {data:{result}} = await $.delete(`reminder_schedule/${reminderScheduleId}`);

            return result;
        }

    }
}