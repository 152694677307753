<template lang="pug">
    b-overlay(:show="isLoading" variant="white")
        b-form(@submit.prevent="emitClient")
            b-form-group(:label="`${$.clientName}`" label-for="name")
                b-form-input(id="name" v-model="client.name" type="text" :placeholder="`Enter a ` + `${$.clientName}`" required)
            b-form-group(v-if="$store.state.enable_client_nickname" label="Nickname (Optional)" label-for="nickname")
                b-form-input(id="nickname" v-model="client.nickname" type="text" placeholder="Enter a nickname")
            b-form-group(:label="`Contact Number ${$store.state.optional_client_contact_number ? ' (Optional)' : ''}`" label-for="contact_number")
                vue-tel-input(id="contact_number" v-model="client.contact_number" :inputOptions="{required:!$store.state.optional_client_contact_number,maxlength:25}")
            b-form-group(label="Case ID (Optional)" label-for="case_id" v-if="!$store.state.no_case_id")
                b-form-input(id="case_id" v-model="client.case_id" type="text" placeholder="Enter a Case ID")
            b-form-group(v-if="!hideClientGroup" :label="`${$.clientGroup}${$store.state.client_require_client_group ? '' : ' (Optional)'}`")
                b-overlay(:show="loadingClientGroups" no-center rounded opacity="0" spinner-small)
                    template(#overlay)
                        div.d-flex.h-100.justify-content-end.p-2
                            b-spinner(small)
                    v-select(multiple v-model="client.client_groups" @search="searchClientGroup" :options="clientGroupOptions" :filterable="false" :placeholder="client.client_groups.length ? '' : `Start typing to search for ${$.clientGroup}...`")
                        template(v-if="$store.state.client_require_client_group" #search="{attributes, events}")
                            input(class="vs__search" :required="$store.state.client_require_client_group && (client.client_groups.length < 1)" v-bind="attributes" v-on="events")
            b-form-group(label="Default Reminder Schedule (Optional)" label-for="reminder_schedule")
                v-select(id="reminder_schedule" v-model="client.default_schedule" :options="reminderScheduleOptions" :reduce="reminderSchedule => reminderSchedule.content" label="label" )
            b-form-group(label="Default Reminder Template (Optional)" label-for="reminder_template")
                v-select(id="reminder_template" v-model="client.default_reminder_template" :options="reminderTemplateOptions" :reduce="reminderTemplate => reminderTemplate.content" label="name" )
                b-alert.mt-1(variant="primary" :show="client.default_reminder_template != null" )
                    | <b>Preview: </b>
                    div(v-if="client.default_reminder_template != null && reminderTemplateOptions.length") {{ reminderTemplateOptions.find(rt => rt.content == client.default_reminder_template).label }}
                    
            //Need to change the client_require_client_group. Incorrect usage
            b-form-group(v-if="$store.state.groups_enabled" :label="`Group`" label-for="group")
                b-form-select(id="group" v-model="client.group_id" :options="groupOptions" required)
            b-form-group(label="Visibility" label-for="visibility" v-if="!$store.state.no_public_clients")
                b-form-select(id="visibility" v-model="client.visibility")
                    option(value="1") Private
                    option(value="2") Public
            template(v-if="$store.state.risk_monitoring")
                hr
                b-form-group(:label="!client.dob_unsure ? `Date Of Birth`:`Approximate Age`" label-for="dob" )
                    b-form-input.mb-2(id="dob" v-model="dob_composite" :type="!client.dob_unsure ? `date`:`number`")
                    b-checkbox(switch v-model="client.dob_unsure") Unsure of exact date of birth
                b-form-group(label="Flag as Vulnerable" label-for="isVulnerable")
                    b-form-radio-group(id="isVulnerable" v-model="client.isVulnerable" :options="vul_options" stacked)
                b-form-group(label="Remarks" label-for="remarks")
                    b-textarea#remarks(v-model="client.remarks")
                hr
            

            b-button(type="submit" :disabled="isSubmitting") Save
</template>

<script>
import dayjs from "dayjs";

export default {
    data() {
        let client = {
                name: "",
                nickname: "",
                contact_number: "",
                case_id: "",
                default_schedule: "",
                default_reminder_template: null,
                visibility: 1,
                group_id: "",
                client_groups: [],
            };
        if(this.$store.state.risk_monitoring){
            Object.assign(client,{
                dob:"",
                dob_unsure:false,
                isVulnerable:0,
                remarks:""
            })
        }
        return {
            isLoading: true,
            isSubmitting: false,
            loadingClientGroups: false,
            clientGroupTimeout: null, //debounce timeout
            clientGroupOptions: [],
            reminderScheduleOptions: [],
            reminderTemplateOptions: [],
            groupOptions: [{ value: "", text: "" }],
            client
        };
    },
    props: {
        hideClientGroup: Boolean,
    },
    computed: {
        dob_composite:{
            get(){
                return this.client.dob_unsure ? dayjs().diff(this.client.dob,"year") :this.client.dob;
            },
            set(v){
                if(this.client.dob_unsure){
                    if(Number(v) < 18){
                        this.client.dob= dayjs().endOf("Year").subtract(v,"year").format("YYYY-MM-DD")
                    }else{
                        this.client.dob= dayjs().startOf("Year").subtract(v,"year").format("YYYY-MM-DD")
                    }
                    return;
                }
                this.client.dob = v
            }
        },
        vul_options(){
            return[
                {value:0,text:`Default (by age cut off)`},
                {value:1,text:`Mark as vulnerable`},
                {value:2,text:`Mark as not vulnerable`}
            ]
        },
        localisation(){
            return this.$store.state.localisation;
        },
        $(){
            let output ={
                "clientGroup": "Client Group",
                "clientName": "Name"
            };
                    
            Object.assign(output,this.localisation);
            return output;
        },
        reminder_schedules(){
            return this.$store.state.reminder_schedules;
        },
        reminder_templates(){
            return this.$store.state.reminder_templates;
        },
        user_groups(){
            return this.$store.state.user_groups||[];
        },
    },
    async mounted() {
        await Promise.all([
            //this.fetchClientGroups(),
            this.fetchReminderSchedules(),
            this.fetchReminderTemplates(),
            this.getUserGroups()
        ]);

        for(let group of this.user_groups) {
            let mainGroup = this.$store.state.group_main_types;
            if(mainGroup && !mainGroup.includes(group.group_type)) {
                continue;
            }
            this.groupOptions.push({
                value: group.id,
                text: group.group_name
            });
        }

        this.isLoading = false;
    },
    methods: {
        emitClient() {
            this.isSubmitting = true;
            this.$emit('client', this.client);
        },
        async searchClientGroup(search) {
            // debounce
            if (this.clientGroupTimeout)
                clearTimeout(this.clientGroupTimeout); 

            this.clientGroupTimeout = setTimeout(async() => {
                // your action
                this.clientGroupOptions = [];
                if(!search) {
                    return;
                }
                
                await this.fetchClientGroups(search);
            }, 500); // delay
        },
        async fetchClientGroups(filter=null) {
            this.loadingClientGroups = true;

            let params = {}
            if(filter) {
                params = {
                    top: 10,
                    filterby: "name",
                    filter
                }
            }
            await this.$store.dispatch('fetchAllClientGroups', params).then((result)=>{
                // Prepare option for client group select
                for (let clientGroup of result.value){
                    let option = {
                        label: clientGroup.name,
                        content: clientGroup.id,
                    };

                    this.clientGroupOptions.push(option);
                }
            })
            .catch(()=>{
                // do something
            });

            this.loadingClientGroups = false;
        },
        async fetchReminderSchedules() {
            
            await this.$store.dispatch('fetchAllReminderSchedule').then((result)=>{
                for (var i=0; i<result.length; i++){
                    let option = {
                        //Remove the Phone from Add Client
                        label: 'SMS: '+ result[i].trigger_schedule.sms.join(", ") + ' days ' +  (!this.$store.state.disable_phone_reminder ? '| Phone: '+ result[i].trigger_schedule.phone.join(", ") +' days' : ''),
                        content: result[i].id
                    };

                    this.reminderScheduleOptions.push(option);
                }
                
            })
            .catch(()=>{
                // do something
            });
        },
        async fetchReminderTemplates() {
            await this.$store.dispatch('fetchAllReminderTemplate').then((result)=>{
                for (var i=0; i<result.length; i++){
                    let option = {
                        label: result[i].content.reminder,
                        name: result[i].name ? result[i].name : result[i].content.reminder,
                        content: result[i].id
                    };

                    this.reminderTemplateOptions.push(option);
                }
            })
            .catch(()=>{
                // do something
            });
        },
        async getUserGroups() {
            await this.$store.dispatch('getUserGroups').then(()=>{
            })
            .catch(()=>{
                // do something
            });
        },
    }
}
</script>

<style>

</style>