<template lang="pug">
transition(name="slide-fade")
  .sidebar-parent
    .sidebar-container
      .d-flex.align-items-center.justify-content-center.flex-column.flex-grow-1
        b-nav-item.py-2.sidebar-item(
          v-tooltip="{ placement: 'right' }",
          :content="item.description",
          :class="$route.path.includes(item.link) ? 'active' : ''",
          v-for="(item, i) in sidebarItems",
          :key="'sidebar_items_' + i",
          :to="item.link"
        )
          fa-icon.sidebar-icon(:icon="[item.iconType, item.icon]")
          // Accessible label for screen readers
          span.sr-only {{ item.description }}
      .divider
      .d-flex.align-items-center.justify-content-center.flex-column
        .my-3(@click="navigate('/profile')")
          b-avatar.link(
            badge-variant="info",
            src="/mysteryman.jpg",
            v-tooltip="{ placement: 'right' }",
            content="My Profile"
          )
            template(#badge="")
              b-icon(icon="star-fill")
        .py-4.px-3.sidebar-item(
          v-tooltip="{ placement: 'right' }",
          content="Notifications"
        )
          fa-icon.sidebar-icon(:icon="['fas', 'bell']")
</template>

<script>
import routes from "@/router/private.js";

export default {
  name: "sidebar",
  data() {
    let sidebarItems = [];

    return {
      sidebarItems,
    };
  },
  mounted() {
    this.sidebarItems = routes
      //Only include files with certaintyMenu function resolver
      .filter(
        (r) =>
          !!r.component.certaintyMenu &&
          !!r.component.certaintyMenu(r.path, this.$store)
      )
      //Loop and generate the Menu
      .map((r) => r.component.certaintyMenu(r.path, this.$store))
      //Sort by order
      .sort((a, b) => a.order - b.order);
  },
  methods: {
    navigate(link) {
      if (this.$route.path == link) {
        return;
      }
      this.$router.push(link);
    },
  },
};
</script>

<style lang="scss">
.sidebar-parent {
  position: fixed;
  z-index: 1;
  top: 0;
  height: 100%;
  background: #fff;
  animation: 1s ease-out 0s 1 slideInFromLeft;
  padding-top: 49px;
}

.sidebar-item {
  color: $secondary;
  font-size: 1.2rem;
  transition: all 0.1s ease;
  width: 100%;
  display: flex;
  justify-content: center;
}

.sidebar-item:hover .sidebar-icon {
  cursor: pointer;
  color: $primary;
  transform: scale(1.3);
}

.sidebar-icon {
  transition: all 0.1s ease;
}

.sidebar-item.active {
  border-left: $primary 4px solid;

  & .sidebar-icon {
    color: $primary;
  }
}

.divider {
  margin: 0 auto;
  width: 70%;
  border-top: 1px solid #edeef1;
}

.sidebar-container {
  cursor: pointer;
  top: 0;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  height: 100vh;
  background: #fff;
}
</style>

<style lang="scss" scoped>
.nav-link {
  color: $secondary;
  padding: 0;
}
</style>