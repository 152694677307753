<template lang="pug">
div
    //Breadcrumb navigation
    div(style="color:grey")
        router-link(to="/reminders") Reminders
        span.mx-1 ›
        router-link(to="/reminders?tab=template") Template
        span.mx-1 ›
        span {{readonly ? 'View' : 'Edit'}} Reminder Template
    
    h2 {{readonly ? 'View' : 'Edit'}} Reminder Template

    b-card.border-0(style="max-width: 1280px")
        b-form(@submit.prevent="editReminderTemplate")
            <b-alert variant="info" show>
                span
                b-icon.mr-2(icon="info-circle-fill" title="Info")
                | <b>Merge tags</b><br>
                span(v-html="`{{name}}`")
                |  - returns client name <br>
                span(v-html="`{{contact_number}}`")|
                |  - returns client contact number <br>
                span(v-html="`{{subject}}`")
                |  - returns appointment subject <br>
                span(v-html="`{{organiser}}`")
                |  - returns organiser display name <br>
                span(v-html="`{{date}}`")
                |  - returns appointment start date <br>
                span(v-html="`{{time}}`")
                |  - returns appointment time range (e.g. 01/01/2021 to 01/01/2021) <br>
                span(v-html="`{{start_time}}`")
                |  - returns appointment start time <br>
                span(v-html="`{{end_time}}`")
                |  - returns appointment end time <br>
                span(v-html="`{{location}}`")
                |  - returns appointment location <br>
                span(v-html="`{{description}}`")
                |  - returns appointment description <br>
                span(v-html="`{{teams_link}}`" v-if="teams_enabled")
                template(v-if="teams_enabled")  - returns appointment teams link <br>
                template(v-if="zoom_enabled")
                    span(v-html="`{{zoom_meeting_link}}`")
                    |  - returns Zoom join link <br>
                    span(v-html="`{{zoom_meeting_id}}`")
                    |  - returns Zoom meeting ID <br>
                    span(v-html="`{{zoom_meeting_passcode}}`")
                    |  - returns Zoom meeting Passcode <br>
                span(v-html="`{{rsvp_link}}`" v-if="hide_rsvp")
                template(v-if="hide_rsvp")  - returns appointment rsvp link <br>                
                <br>
                span <b>Example: </b><br>
                | Hello <span v-html="`{{name}}`"></span>. You have <span v-html="`{{subject}}`"></span> at <span v-html="`{{time}}`"></span> on <span v-html="`{{date}}`"></span>.
            </b-alert>

            b-form-group(label="Template Name (optional)")
                b-form-input(type="text" v-model="reminder_template.name")

            b-form-group(label="Reminder" label-for="reminder")
                div(v-if="!readonly")
                    b-button.mr-1.mb-1(v-html="`{{name}}`" variant="outline-primary" size="sm" @click="appendToTemplate('reminder', '{{name}}')")
                    b-button.mr-1.mb-1(v-if="$store.state.enable_client_nickname" v-html="`{{nickname}}`" variant="outline-primary" size="sm" @click="appendToTemplate('reminder', '{{nickname}}')")
                    b-button.mr-1.mb-1(v-html="`{{contact_number}}`" variant="outline-primary" size="sm" @click="appendToTemplate('reminder', '{{contact_number}}')")
                    b-button.mr-1.mb-1(v-html="`{{subject}}`" variant="outline-primary" size="sm" @click="appendToTemplate('reminder', '{{subject}}')")
                    b-button.mr-1.mb-1(v-html="`{{organiser}}`" variant="outline-primary" size="sm" @click="appendToTemplate('reminder', '{{organiser}}')")
                    b-button.mr-1.mb-1(v-html="`{{date}}`" variant="outline-primary" size="sm" @click="appendToTemplate('reminder', '{{date}}')")
                    b-button.mr-1.mb-1(v-html="`{{time}}`" variant="outline-primary" size="sm" @click="appendToTemplate('reminder', '{{time}}')")
                    b-button.mr-1.mb-1(v-html="`{{start_time}}`" variant="outline-primary" size="sm" @click="appendToTemplate('reminder', '{{start_time}}')")
                    b-button.mr-1.mb-1(v-html="`{{end_time}}`" variant="outline-primary" size="sm" @click="appendToTemplate('reminder', '{{end_time}}')")
                    b-button.mr-1.mb-1(v-html="`{{location}}`" variant="outline-primary" size="sm" @click="appendToTemplate('reminder', '{{location}}')")
                    b-button.mr-1.mb-1(v-html="`{{description}}`" variant="outline-primary" size="sm" @click="appendToTemplate('reminder', '{{description}}')")
                    b-button.mr-1.mb-1(v-html="`{{teams_link}}`" variant="outline-primary" size="sm" @click="appendToTemplate('reminder', '{{teams_link}}')" v-if="teams_enabled")
                    b-button.mr-1.mb-1(v-html="`{{rsvp_link}}`" variant="outline-primary" size="sm" @click="appendToTemplate('reminder', '{{rsvp_link}}')" v-if="hide_rsvp")
                    template(v-if="zoom_enabled")
                        b-button.mr-1.mb-1(v-html="`{{zoom_meeting_link}}`" variant="outline-primary" size="sm" @click="appendToTemplate('reminder', '{{zoom_meeting_link}}')")
                        b-button.mr-1.mb-1(v-html="`{{zoom_meeting_id}}`" variant="outline-primary" size="sm" @click="appendToTemplate('reminder', '{{zoom_meeting_id}}')")
                        b-button.mr-1.mb-1(v-html="`{{zoom_meeting_passcode}}`" variant="outline-primary" size="sm" @click="appendToTemplate('reminder', '{{zoom_meeting_passcode}}')")

                b-form-textarea(id="reminder"  v-model="reminder_template.content.reminder" placeholder="Add a message" required :readonly="readonly")
                b-alert.mt-1(variant="primary" :show="reminder_template.content.reminder != ''" )
                    | <b>Preview: </b><br>
                    TemplatePreview(:raw-reminder-template="reminder_template.content.reminder")

            b-form-group(label="On Create (Optional)" label-for="onCreate" v-if="usingExtraTemplate==='true'")
                div(v-if="!readonly")
                    b-button.mr-1.mb-1(v-html="`{{name}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCreate', '{{name}}')")
                    b-button.mr-1.mb-1(v-if="$store.state.enable_client_nickname" v-html="`{{nickname}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCreate', '{{nickname}}')")
                    b-button.mr-1.mb-1(v-html="`{{contact_number}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCreate', '{{contact_number}}')")
                    b-button.mr-1.mb-1(v-html="`{{subject}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCreate', '{{subject}}')")
                    b-button.mr-1.mb-1(v-html="`{{organiser}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCreate', '{{organiser}}')")
                    b-button.mr-1.mb-1(v-html="`{{date}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCreate', '{{date}}')")
                    b-button.mr-1.mb-1(v-html="`{{time}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCreate', '{{time}}')")
                    b-button.mr-1.mb-1(v-html="`{{start_time}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCreate', '{{start_time}}')")
                    b-button.mr-1.mb-1(v-html="`{{end_time}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCreate', '{{end_time}}')")
                    b-button.mr-1.mb-1(v-html="`{{location}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCreate', '{{location}}')")
                    b-button.mr-1.mb-1(v-html="`{{description}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCreate', '{{description}}')")
                    b-button.mr-1.mb-1(v-html="`{{teams_link}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCreate', '{{teams_link}}')" v-if="teams_enabled")
                    b-button.mr-1.mb-1(v-html="`{{rsvp_link}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCreate', '{{rsvp_link}}')" v-if="hide_rsvp")
                    template(v-if="zoom_enabled")
                        b-button.mr-1.mb-1(v-html="`{{zoom_meeting_link}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCreate', '{{zoom_meeting_link}}')")
                        b-button.mr-1.mb-1(v-html="`{{zoom_meeting_id}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCreate', '{{zoom_meeting_id}}')")
                        b-button.mr-1.mb-1(v-html="`{{zoom_meeting_passcode}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCreate', '{{zoom_meeting_passcode}}')")
                b-form-textarea(id="onCreate"  v-model="reminder_template.content.onCreate" placeholder="Add a message" :readonly="readonly")
                b-alert.mt-1(variant="primary" :show="reminder_template.content.onCreate != ''" )
                    | <b>Preview: </b><br>
                    TemplatePreview(:raw-reminder-template="reminder_template.content.onCreate")

            b-form-group(label="On Change (Optional)" label-for="onChange" v-if="usingExtraTemplate==='true'")
                div(v-if="!readonly")
                    b-button.mr-1.mb-1(v-html="`{{name}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onChange', '{{name}}')")
                    b-button.mr-1.mb-1(v-if="$store.state.enable_client_nickname" v-html="`{{nickname}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onChange', '{{nickname}}')")
                    b-button.mr-1.mb-1(v-html="`{{contact_number}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onChange', '{{contact_number}}')")
                    b-button.mr-1.mb-1(v-html="`{{subject}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onChange', '{{subject}}')")
                    b-button.mr-1.mb-1(v-html="`{{organiser}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onChange', '{{organiser}}')")
                    b-button.mr-1.mb-1(v-html="`{{date}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onChange', '{{date}}')")
                    b-button.mr-1.mb-1(v-html="`{{time}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onChange', '{{time}}')")
                    b-button.mr-1.mb-1(v-html="`{{start_time}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onChange', '{{start_time}}')")
                    b-button.mr-1.mb-1(v-html="`{{end_time}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onChange', '{{end_time}}')")
                    b-button.mr-1.mb-1(v-html="`{{location}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onChange', '{{location}}')")
                    b-button.mr-1.mb-1(v-html="`{{description}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onChange', '{{description}}')")
                    b-button.mr-1.mb-1(v-html="`{{teams_link}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onChange', '{{teams_link}}')" v-if="teams_enabled")
                    b-button.mr-1.mb-1(v-html="`{{rsvp_link}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onChange', '{{rsvp_link}}')" v-if="hide_rsvp")
                    template(v-if="zoom_enabled")
                        b-button.mr-1.mb-1(v-html="`{{zoom_meeting_link}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onChange', '{{zoom_meeting_link}}')")
                        b-button.mr-1.mb-1(v-html="`{{zoom_meeting_id}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onChange', '{{zoom_meeting_id}}')")
                        b-button.mr-1.mb-1(v-html="`{{zoom_meeting_passcode}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onChange', '{{zoom_meeting_passcode}}')")
                b-form-textarea(id="onChange"  v-model="reminder_template.content.onChange" placeholder="Add a message" :readonly="readonly")
                b-alert.mt-1(variant="primary" :show="reminder_template.content.onChange != ''" )
                    | <b>Preview: </b><br>
                    TemplatePreview(:raw-reminder-template="reminder_template.content.onChange")

            b-form-group(label="On Cancel/Delete (Optional)" label-for="onCancel" v-if="usingExtraTemplate==='true'")
                div(v-if="!readonly")
                    b-button.mr-1.mb-1(v-html="`{{name}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCancel', '{{name}}')")
                    b-button.mr-1.mb-1(v-if="$store.state.enable_client_nickname" v-html="`{{nickname}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCancel', '{{nickname}}')")
                    b-button.mr-1.mb-1(v-html="`{{contact_number}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCancel', '{{contact_number}}')")
                    b-button.mr-1.mb-1(v-html="`{{subject}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCancel', '{{subject}}')")
                    b-button.mr-1.mb-1(v-html="`{{organiser}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCancel', '{{organiser}}')")
                    b-button.mr-1.mb-1(v-html="`{{date}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCancel', '{{date}}')")
                    b-button.mr-1.mb-1(v-html="`{{time}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCancel', '{{time}}')")
                    b-button.mr-1.mb-1(v-html="`{{start_time}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCancel', '{{start_time}}')")
                    b-button.mr-1.mb-1(v-html="`{{end_time}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCancel', '{{end_time}}')")
                    b-button.mr-1.mb-1(v-html="`{{location}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCancel', '{{location}}')")
                    b-button.mr-1.mb-1(v-html="`{{description}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCancel', '{{description}}')")
                    b-button.mr-1.mb-1(v-html="`{{teams_link}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCancel', '{{teams_link}}')" v-if="teams_enabled")
                    b-button.mr-1.mb-1(v-html="`{{rsvp_link}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCancel', '{{rsvp_link}}')")
                    template(v-if="zoom_enabled")
                        b-button.mr-1.mb-1(v-html="`{{zoom_meeting_link}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCancel', '{{zoom_meeting_link}}')")
                        b-button.mr-1.mb-1(v-html="`{{zoom_meeting_id}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCancel', '{{zoom_meeting_id}}')")
                        b-button.mr-1.mb-1(v-html="`{{zoom_meeting_passcode}}`" variant="outline-primary" size="sm" @click="appendToTemplate('onCancel', '{{zoom_meeting_passcode}}')")
                b-form-textarea(id="onCancel"  v-model="reminder_template.content.onCancel" placeholder="Add a message" :readonly="readonly")
                b-alert.mt-1(variant="primary" :show="reminder_template.content.onCancel != ''" )
                    | <b>Preview: </b><br>
                    TemplatePreview(:raw-reminder-template="reminder_template.content.onCancel")

            b-form-group(v-if="$store.state.groups_enabled && (!this.$store.state.group_based_reminder_permission || userIsMainGroupOwner)" label="Group" label-for="group")
                b-form-select(id="group" v-model="reminder_template.group_id" :options="groupOptions"  :disabled="readonly")

            b-form-group(label="Visibility" label-for="visibility" v-if="!this.$store.state.group_based_reminder_permission || user.is_admin")
                b-form-select(id="visibility" v-model="reminder_template.visibility" :disabled="readonly")
                    option(value="1") Private
                    option(value="2") Public

            b-button(type="submit" v-if="!readonly") Save
            b-button.ml-1(type="submit" data-duplicate="true" variant="sec6")
                template(v-if="!readonly") Save and duplicate a copy
                template(v-else) Duplicate a copy
</template>

<script>
import TemplatePreview from '@/components/reminders/reminder_template_preview.vue';

export default {
    /** Merge tags:
     * {{name}} - returns client name
     * {{contact_number}} - returns client contact number
     * {{subject}} - returns appointment subject
     * {{organiser}} - returns organiser display name
     * {{date}} - returns appointment date
     * {{time}} - returns appointment time range (e.g. 01/01/2021 to 01/01/2021)
     * {{start_time}} - returns appointment start time
     * {{end_time}} - returns appointment end time
     * {{location}} - returns appointment location
     * {{description}} - returns appointment description
     */
    name: 'EditReminderTemplate',
    data() {
        return {
            readonly: true,
            teams_enabled:process.env.VUE_APP_TEAMS_INTEGRATION,     
            hide_rsvp:process.env.VUE_APP_HIDE_RSVP,
            zoom_enabled:this.$store.state.zoom_enabled,
            reminder_template: {
                name: "",
                content: {
                    reminder: "", //default reminder
                    onCreate: "",
                    onChange: "",
                    onCancel: "",
                },
                visibility: null,
                group_id: ""
            },
            usingExtraTemplate: process.env.VUE_APP_EXTRA_REMINDER_TEMPLATE,
            groupOptions: [{ value: "", text: "" }],
        };
    },
    components: {
        TemplatePreview
    },
    computed: {
        user(){
            return this.$store.state.user;
        },
        userIsMainGroupOwner() {
            //return if logged in user is a centre group owner (centre head)
            if(this.user.user_owned_groups_w_details && this.user.user_owned_groups_w_details.length) {
                let groups = this.user.user_owned_groups_w_details;
                if(this.$store.state.group_main_types && this.$store.state.group_main_types.length) {
                    groups = this.user.user_owned_groups_w_details.filter(grp=>this.$store.state.group_main_types.includes(grp.group_type));
                }
                
                if(groups.length) {
                    return true;
                }
            }
            return false;
        },
        user_groups(){
            return this.$store.state.user_groups||[];
        },
    },
    async mounted() {
        this.getReminderTemplate();

        await this.getUserGroups();
        for(let group of this.user_groups) {
            let mainGroup = this.$store.state.group_main_types;
            if(mainGroup && !mainGroup.includes(group.group_type)) {
                continue;
            }
            if(this.$store.state.group_based_reminder_permission && !group.owner) {
                continue;
            }
            this.groupOptions.push({
                value: group.id,
                text: group.group_name
            });
        }
    },
    methods: {
        async getReminderTemplate() {
            await this.$store.dispatch('getReminderTemplate',this.$route.params.reminder_template_id)
            .then(result=>{
                this.reminder_template.name = result.name;
                this.reminder_template.content.reminder = result.content.reminder;
                this.reminder_template.visibility = result.visibility;
                this.reminder_template.group_id = result.group_id;

                if(this.usingExtraTemplate) {
                    this.reminder_template.content.onCreate = result.content.onCreate;
                    this.reminder_template.content.onChange = result.content.onChange;
                    this.reminder_template.content.onCancel = result.content.onCancel;
                }
                if(result.user_id == this.user.user_id || this.user.is_admin || (this.userIsMainGroupOwner && this.user.user_owned_groups_w_details.map(grp=>grp.id).includes(result.group_id))){
                    this.readonly= false;
                }

                
            })
            .catch((err)=>{
                console.log(err);

                // todo: display error
            });
        },
        appendToTemplate(field, tagString) {
            switch(field) {
                case "reminder":
                    this.reminder_template.content.reminder += tagString;
                    break;
                case "onCreate":
                    this.reminder_template.content.onCreate += tagString;
                    break;
                case "onChange":
                    this.reminder_template.content.onChange += tagString;
                    break;
                case "onCancel":
                    this.reminder_template.content.onCancel += tagString;
                    break;
            }
        },
        async cloneReminderTemplate(){
            await this.$store.dispatch('addReminderTemplate',{payload: this.reminder_template}).then(({id})=>{
                window.location.href = `/reminders/template/${id}/edit`;
            }).catch((err)=>{
                console.log(err);
                // todo: show error message
            })

        },
        async editReminderTemplate(e) {
            if(e.submitter.dataset.duplicate){
                this.cloneReminderTemplate(e);
                return;
            }
            await this.$store.dispatch('patchReminderTemplate',{payload: this.reminder_template, reminder_template_id: this.$route.params.reminder_template_id}).then(()=>{
                window.location.href = `/reminders?tab=template`;
            }).catch((err)=>{
                console.log(err);
                window.alert(err?.response?.data?.error||err);
                // todo: show error message
            })
        },
        async getUserGroups() {
            await this.$store.dispatch('getUserGroups').then(()=>{
            })
            .catch(()=>{
                // do something
            });
        },
    }
}
</script>

<style>

</style>