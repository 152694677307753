<template lang="pug">
.loader-container.animate-loading
  LogoWithoutText.logo-text-loading(ref='loadingSvg')
  h1.loading-text.mt-3.text-secondary LOADING
</template>
<script>
import LogoWithoutText from "./LogoWithoutText.vue";
import anime from 'animejs/lib/anime.es.js';

export default {
  name: 'Loader',
  mounted() {

    if(this.$refs.loadingSvg){
      this.$refs.loadingSvg.$el.setAttribute('viewBox', '-35 -500 1050.55 1500.06');
    }

  const textWrapper = document.querySelector('.loading-text');
  textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

  anime.timeline({loop: true, duration: 1000})
    .add({
      targets: '.animate-loading .loading-text .letter',
      translateX: [40,0],
      translateZ: 0,
      opacity: [0,1],
      easing: "easeOutQuad",
      duration: 900,
      delay: (el, i) => 500 + 30 * i
    }).add({
      targets: '.animate-loading .loading-text .letter',
      translateX: [0,-30],
      opacity: [1,0],
      easing: "easeInExpo",
      duration: 1100,
      delay: (el, i) => 100 + 30 * i
    });

    let animTimeline = anime.timeline({
      easing: 'easeOutQuad',
      duration: 500,
      loop: true,
      direction: 'alternate'
    });

    animTimeline.add({
      targets: '.animate-loading .human',
      translateY: [-500, 0],
      duration: 1000,
      delay: 1000,
      opacity: [0, 1],
    })
    .add({
      targets: '.animate-loading .box-left',
      translateX: [-50, 0],
      translateY: [50, 0],
      opacity: [0, 1]
    })
    .add({
      targets: '.animate-loading .box-right',
      translateX: [50, 0],
      translateY: [50, 0],
      opacity: [0, 1]
    })
    .add({
      targets: '.animate-loading  #svg-logo-no-text .logo-the-rest',
      strokeDashoffset: [anime.setDashoffset, 0],
      duration: 1000,
      easing: 'easeInOutSine',
      delay: function(el, i) { return i * 250 }
    })
    .add({
      targets: ['.animate-loading .boxes'],
      fill: ['rgba(0,0,0,0)', '#'],
      duration: 1000,
      easing: 'easeInOutSine',
    })
  },
  components: {
    LogoWithoutText
  }
}
</script>
<style lang="scss">
.loader-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.loading-text {
  font-weight: 200;
}

.loading-text .letter{
  margin: 0px 5px;
}


.logo-text-loading {
  height: 150px;
}


</style>