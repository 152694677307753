<template lang="pug">
  .full-height
    transition(name='slide-fade' mode='out-in')
        transition(name='slide-fade' mode='out-in')
          .full-height.d-flex.flex-column
            b-navbar.link.main-navbar.px-3.py-0(toggleable='lg' variant='white')
              b-navbar-brand.text-secondary.pt-2.pb-0(tag='h1' @click="$router.push('/')")
                LogoWithoutText.mr-1.navbar-logo(:animate='false') 
                | {{appName}} 
                small(style='font-size:.5em') by TQ
              p(v-if="isTestEnv").mb-0 TEST ENVIRONMENT
              b-navbar-toggle(target='navbar-toggle-collapse' v-if='user')
                template(v-slot:default='{ expanded }')
                  b-icon(v-if='expanded' icon='chevron-bar-up')
                  b-icon(v-else='' icon='chevron-bar-down')
              b-collapse#navbar-toggle-collapse(is-nav='' v-if='user')
                b-navbar-nav.ml-auto
                  b-nav-item(:class="$root.inMobile || 'logout-navbar'" href='/api/logout' )
                    b-icon-power  
                    | Logout
            transition(name='slide-fade' mode='out-in')
              section.full-height.full-width.d-flex(:style='{"--sidebar-margin":hideSidebar?0:"49px"}')
                sidebar(v-if="!hideSidebar")
                .full-height.full-width.flex-grow-1.d-flex.flex-column.main-bg(:class="$store.state.user ? 'sidebar-margin' : ''")
                    transition(name='slide-fade' mode='out-in')
                        router-view.page-container
</template>

<script>
import sidebar from "./sidebar.vue";

import LogoWithoutText from "../loader/LogoWithoutText.vue";

export default {
    name: "baseTemplate",
    components:{
        sidebar,LogoWithoutText
    },
    props:{
      hideSidebar:Boolean
    },
    computed:{
      isTestEnv() {
        return process.env.VUE_APP_IS_DEV
      },
      appName() {
        return process.env.VUE_APP_NAME ? process.env.VUE_APP_NAME : 'CERTAINTY';
      }

    }
}
</script>

<style lang="scss">
    .main-navbar {
        z-index: 2
    }
    .sidebar-margin{
        margin-left: var(--sidebar-margin);
    }
</style>