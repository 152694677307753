import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Loader from  '@/components/loader/Loading.vue';
import VueTelInput from 'vue-tel-input'
import VueCal from 'vue-cal'

import '@/scss/colors.scss';
import '@/scss/style.scss';
import "animate.css/animate.min.css"
import 'vue2-animate/dist/vue2-animate.min.css';
import 'vue-select/dist/vue-select.css';
import 'vue-tel-input/dist/vue-tel-input.css'
import 'vue-cal/dist/vuecal.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

library.add(fas, far);

Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('v-select', vSelect)

Vue.component('vue-cal', VueCal);


// Install BootstrapVue
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueTelInput, {defaultCountry:'SG', mode:'international'})
Vue.component('loader',Loader);

import VueTippy, { TippyComponent } from "vue-tippy";

Vue.use(VueTippy, {
  directive: "tooltip",
  flipDuration: 1,
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: true
      }
    }
  }
});

Vue.component("tippy", TippyComponent);


Vue.config.productionTip = false



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
