<template lang="pug">
  .sp.sp-slices
</template>

<script>
export default {
    name: 'hourglass'
}
</script>

<style lang="scss">
$spCol: #fff;
$size: 10px;

.sp {
	clear: both;
}



/* Spinner Slices */
.sp.sp-slices {
	border-radius: 50%;
	border-top: $size rgba($spCol,0.75) solid;
	border-left: $size rgba($spCol,0.25) solid;
	border-bottom: $size rgba($spCol,0.25) solid;
	border-right: $size rgba($spCol,0.25) solid;
	-webkit-animation: spSlices 1s infinite linear;
	animation: spSlices 1s infinite linear;
}
@-webkit-keyframes spSlices {
	0% {
		border-top: $size rgba($spCol,0.75) solid;
		border-right: $size rgba($spCol,0.25) solid;
		border-bottom: $size rgba($spCol,0.25) solid;
		border-left: $size rgba($spCol,0.25) solid;
	}
	25% {
		border-top: $size rgba($spCol,0.25) solid;
		border-right: $size rgba($spCol,0.75) solid;
		border-bottom: $size rgba($spCol,0.25) solid;
		border-left: $size rgba($spCol,0.25) solid;
	}
	50% {
		border-top: $size rgba($spCol,0.25) solid;
		border-right: $size rgba($spCol,0.25) solid;
		border-bottom: $size rgba($spCol,0.75) solid;
		border-left: $size rgba($spCol,0.25) solid;
	}
	75% {
		border-top: $size rgba($spCol,0.25) solid;
		border-right: $size rgba($spCol,0.25) solid;
		border-bottom: $size rgba($spCol,0.25) solid;
		border-left: $size rgba($spCol,0.75) solid;
	}
	100% {
		border-top: $size rgba($spCol,0.75) solid;
		border-right: $size rgba($spCol,0.25) solid;
		border-bottom: $size rgba($spCol,0.25) solid;
		border-left: $size rgba($spCol,0.25) solid;
	}
}
@keyframes spSlices {
	0% {
		border-top: $size rgba($spCol,0.75) solid;
		border-right: $size rgba($spCol,0.25) solid;
		border-bottom: $size rgba($spCol,0.25) solid;
		border-left: $size rgba($spCol,0.25) solid;
	}
	25% {
		border-top: $size rgba($spCol,0.25) solid;
		border-right: $size rgba($spCol,0.75) solid;
		border-bottom: $size rgba($spCol,0.25) solid;
		border-left: $size rgba($spCol,0.25) solid;
	}
	50% {
		border-top: $size rgba($spCol,0.25) solid;
		border-right: $size rgba($spCol,0.25) solid;
		border-bottom: $size rgba($spCol,0.75) solid;
		border-left: $size rgba($spCol,0.25) solid;
	}
	75% {
		border-top: $size rgba($spCol,0.25) solid;
		border-right: $size rgba($spCol,0.25) solid;
		border-bottom: $size rgba($spCol,0.25) solid;
		border-left: $size rgba($spCol,0.75) solid;
	}
	100% {
		border-top: $size rgba($spCol,0.75) solid;
		border-right: $size rgba($spCol,0.25) solid;
		border-bottom: $size rgba($spCol,0.25) solid;
		border-left: $size rgba($spCol,0.25) solid;
	}
}


</style>