<template lang="pug">
div
    div.d-flex.justify-content-between.align-items-end.flex-wrap.mb-3
        b-button(to="/reminders/template/create")
                b-icon(icon="plus")
                | New Template
        b-form(inline)

            b-form.bg-light.border.rounded.p-1.mr-2(inline)
                b-form.mr-2.ml-1(inline)
                    label.mr-1(for="view") List:
                    b-form-select(id="view" v-model="isListAll" size="sm" @change="selectView()")
                        <option :value="0">All Template</option>
                        <option :value="1" v-if="$store.state.groups_enabled">Group's Template</option>
                b-overlay(v-if="isListAll==1" :show="isLoading" spinner-small)
                    b-form(inline v-if="isListAll==1")
                        label.mr-1(for="group") Groups:
                        b-form-select(id="group" v-model="selectedGroup" size="sm" :options="availableGroups")
            b-input-group
                b-form-input(id="filter-input" v-model="filter" type="search" placeholder="Search")
                <b-input-group-append is-text>
                    <b-icon icon="search"></b-icon>
                </b-input-group-append>
    
    div.d-flex.justify-content-center(v-if="isLoading")
        b-spinner
    div(v-else-if="filtered_reminder_list.length")
        b-table(:items="filtered_reminder_list", :fields="fields" :filter="filter" :per-page="perPage" :current-page="currentPage" stacked='md')
            template(#cell(content)="data")
                b-badge(variant="secondary" v-if="data.item.visibility==1") Private
                b-badge(variant="success" v-if="data.item.visibility==2") Public
                p {{ data.item.content.reminder }}

                b-button(v-b-toggle="'collapse-'+data.item.id" variant="outline-secondary" size="sm" v-if="data.item.content.onCreate || data.item.content.onChange || data.item.content.onCancel")
                    <span class="when-open"><b-icon icon="caret-up"></b-icon></span><span class="when-closed"><b-icon icon="caret-down"></b-icon></span> Extra Template

                b-collapse.mt-2(:id="'collapse-'+data.item.id")
                    b-card
                        div.mb-2(v-if="data.item.content.onCreate") <b>Send on Create: </b><br> {{ data.item.content.onCreate }}
                        div.mb-2(v-if="data.item.content.onChange") <b>Send on Change: </b><br> {{ data.item.content.onChange }}
                        div.mb-2(v-if="data.item.content.onCancel") <b>Send on Cancel: </b><br> {{ data.item.content.onCancel }}

                
                
            template(#cell(actions)="data")
                div(v-if="data.item.user_id == user.user_id || user.is_admin || userOwnedMainGroupType.includes(data.item.group_id)")
                    b-button.mr-1.mb-1(:to="'/reminders/template/'+(data.item.id)+'/edit'")
                        b-icon.mr-1(icon="pencil-square")
                        | Edit
                    b-button.mr-1.mb-1(v-b-modal="'delete-template-modal'+data.item.id" variant="outline-danger") Delete

                    b-modal(:id="'delete-template-modal'+data.item.id" title="Delete Reminder Template?" @ok="deleteReminderTemplate(data.item.id)" cancel-variant="outline-secondary" ok-variant="danger" ok-title="Delete")
                        p This will remove the following reminder template from your list.
                        p {{ data.item.content.reminder }}

                div(v-else)
                    b-button.mr-1.mb-1(:to="'/reminders/template/'+(data.item.id)+'/edit'")
                        b-icon.mr-1(icon="eye-fill")
                        | View

                    p.text-muted Read Only. <br>You're not the creator.

        b-pagination.m-0(
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
        )
    div(v-else)
        p No template found
</template>

<script>
export default {
    name: 'ReminderTemplateList',
    data(){
        let fields = [
                { key: 'content', name: 'Content', sortable: true },
                { key: 'owner', name: 'Owner', sortable: true },
                'actions'
            ];
            if(this.$store.state.groups_enabled){
                fields.splice(1,0,{ key: 'group', name: 'Group', sortable: true, sortByFormatted:true, filterByFormatted:true, formatter:v=>v?.group_name||"-"})
            }
        return{
            reminderTemplateList: null,
            isLoading: true,
            filter: "",
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            fields,
            isListAll: 0,
            selectedGroup:null
        }
    },
    computed: {
        user(){
            return this.$store.state.user;
        },
        reminder_templates(){
            return this.$store.state.reminder_templates;
        },
        filtered_reminder_list(){
            let output = this.reminderTemplateList;
            if(this.selectedGroup){
                output = this.reminderTemplateList.filter(c=>c.group_id == this.selectedGroup);
            }
            return output;
        },
        availableGroups(){
            let setCheck = new Set();
            return this.reminderTemplateList.filter(c=>{
                if(!c.group_id){
                    return false;
                }
                if(setCheck.has(c.group_id)){
                    return false;
                }
                setCheck.add(c.group_id);
                return true;
            }).map(c=>{
                return {
                   text: c.group.group_name,
                   value:c.group_id
                }
            })
        },
        userOwnedMainGroupType() {
            if(this.user.user_owned_groups_w_details && this.user.user_owned_groups_w_details.length) {
                let groups = this.user.user_owned_groups_w_details;
                if(this.$store.state.group_main_types && this.$store.state.group_main_types.length) {
                    groups = this.user.user_owned_groups_w_details.filter(grp=>this.$store.state.group_main_types.includes(grp.group_type));
                }
                return groups.map(grp=>grp.id);
            }
            return [];
        }
    },
    mounted() {
        this.fetchReminderTemplates();
        
    },
    methods: {
        selectView() {
            this.isListAll === 0 ? this.fetchReminderTemplates() : this.fetchGroupReminderTemplate();
        },
        async fetchReminderTemplates() {
            this.isLoading = true;
            await this.$store.dispatch('fetchAllReminderTemplate').then((result)=>{
                this.reminderTemplateList = result;
                this.totalRows = this.reminder_templates.length;
                this.isListAll = 0;
                this.selectedGroup =null;
            })
            .catch(()=>{
                // do something
            });
            this.isLoading = false;
        },
        async fetchGroupReminderTemplate() {
            this.isLoading = true;
            await this.$store.dispatch('fetchGroupReminderTemplate').then((result)=>{
                this.reminderTemplateList = result;
                this.totalRows = this.reminder_templates.length;
                this.isListAll = 1;
                this.selectedGroup =null;
            })
            .catch(()=>{
                // do something
            });
            this.isLoading = false;
        },
        deleteReminderTemplate(reminderTemplateId) {
            this.$store.dispatch('deleteReminderTemplate', reminderTemplateId).then(()=>{
                this.fetchReminderTemplates();
            }).catch(()=>{
                
                // todo: error message
            })
        }
    }
}
</script>

<style>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>