<template lang="pug">
    b-form(@submit.prevent="submitData" @reset.prevent="rawdata=''")
        b-form-group(label="Notes" )
            b-textarea(v-model="notes")
        hr
        b-button(type="submit") Save
        b-button.ml-3(type="reset" variant="outline-dark") Undo
</template>
<script>
export default {
    name:"update_acp",
    data(){
        return {
            rawdata:"",
        }
    },
    props:["current_notes"],
    methods:{
        submitData(){
            this.$emit("update_notes",this.notes);
        }
    },
    computed:{
        notes:{
            get(){
                return this.rawdata||this.current_notes||"";
            },
            set(v){
                this.rawdata =v;
            }
        }
    }
}
</script>