<template lang="pug">
div
    div.d-flex.justify-content-between.align-items-end.flex-wrap.mb-3
        b-button(to="/reminders/schedule/create")
            b-icon(icon="plus")
            | New Schedule
        b-form(inline)

            b-form.bg-light.border.rounded.p-1.mr-2(inline)
                b-form.mr-2.ml-1(inline)
                    label.mr-1(for="view") List:
                    b-form-select(id="view" v-model="isListAll" size="sm" @change="selectView()")
                        <option :value="0">All Schedule</option>
                        <option :value="1" v-if="$store.state.groups_enabled">Group's Schedule</option>
                b-overlay(v-if="isListAll==1" :show="isLoading" spinner-small)
                    b-form(inline v-if="isListAll==1")
                        label.mr-1(for="group") Groups:
                        b-form-select(id="group" v-model="selectedGroup" size="sm" :options="availableGroups")
            b-input-group
                b-form-input(id="filter-input" v-model="filter" type="search" placeholder="Search")
                <b-input-group-append is-text>
                    <b-icon icon="search"></b-icon>
                </b-input-group-append>
    
    div.d-flex.justify-content-center(v-if="isLoading")
        b-spinner
    div(v-else-if="filtered_reminder_list.length")
        b-table(:items="filtered_reminder_list", :fields="fields" :filter="filter" :per-page="perPage" :current-page="currentPage" stacked='md')
            template(#cell(trigger_schedule)="data")
                b-badge(variant="secondary" v-if="data.item.visibility==1") Private
                b-badge(variant="success" v-if="data.item.visibility==2") Public

                h5.mb-1(v-if="data.item.name") {{ data.item.name }}
                h5.mb-1(v-else) Untitled

                div(v-if="data.item.trigger_schedule.sms && data.item.trigger_schedule.sms.length > 0") <b>SMS: </b>{{ data.item.trigger_schedule.sms.join(", ") }} days before meeting
                div(v-if="data.item.trigger_schedule.phone && data.item.trigger_schedule.phone.length > 0") <b>Phone: </b>{{ data.item.trigger_schedule.phone.join(", ") }} days before meeting
            template(#cell(actions)="data")
                div(v-if="data.item.user_id == user.user_id || user.is_admin || userOwnedMainGroupType.includes(data.item.group_id)")
                    b-button.mr-1.mb-1(:to="'/reminders/schedule/'+(data.item.id)+'/edit'")
                        b-icon.mr-1(icon="pencil-square")
                        | Edit
                    b-button.mr-1.mb-1(v-b-modal="'delete-schedule-modal'+data.item.id" variant="outline-danger") Delete

                    b-modal(:id="'delete-schedule-modal'+data.item.id" title="Delete Reminder Schedule?" @ok="deleteReminderSchedule(data.item.id)" cancel-variant="outline-secondary" ok-variant="danger" ok-title="Delete")
                        p This will remove {{data.item.name?data.item.name:"Untitled"}} reminder schedule from your list.

                        div(v-if="data.item.trigger_schedule.sms && data.item.trigger_schedule.sms.length > 0") <b>SMS: </b>{{ data.item.trigger_schedule.sms.join(", ") }} days before meeting
                        div(v-if="data.item.trigger_schedule.phone && data.item.trigger_schedule.phone.length > 0") <b>Phone: </b>{{ data.item.trigger_schedule.phone.join(", ") }} days before meeting
                div(v-else)
                    b-button.mr-1.mb-1(:to="'/reminders/schedule/'+(data.item.id)+'/edit'")
                        b-icon.mr-1(icon="eye-fill")
                        | View
                    p.text-muted Read Only.<br>You're not the creator.

        b-pagination.m-0(
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
        )
    div(v-else)
        p No schedule found
</template>

<script>
export default {
    name: 'ReminderScheduleList',
    data(){
        let fields = [
                { key: 'trigger_schedule', name: 'Trigger Schedule', sortable: true },
                { key: 'owner', name: 'Owner', sortable: true },
                'actions'
            ];
            if(this.$store.state.groups_enabled){
                fields.splice(1,0,{ key: 'group', name: 'Group', sortable: true, sortByFormatted:true, filterByFormatted:true, formatter:v=>v?.group_name||"-"})
            }
        return{
            reminderScheduleList: null,
            isLoading: true,
            filter: "",
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            fields,
            isListAll: 0,
            selectedGroup:null
        }
    },
    computed: {
        user(){
            return this.$store.state.user;
        },
        reminder_schedules(){
            return this.$store.state.reminder_schedules;
        },
        filtered_reminder_list(){
            let output = this.reminderScheduleList;
            if(this.selectedGroup){
                output = this.reminderScheduleList.filter(c=>c.group_id == this.selectedGroup);
            }
            return output;
        },
        availableGroups(){
            let setCheck = new Set();
            return this.reminderScheduleList.filter(c=>{
                if(!c.group_id){
                    return false;
                }
                if(setCheck.has(c.group_id)){
                    return false;
                }
                setCheck.add(c.group_id);
                return true;
            }).map(c=>{
                return {
                   text: c.group.group_name,
                   value:c.group_id
                }
            })
        },
        userOwnedMainGroupType() {
            if(this.user.user_owned_groups_w_details && this.user.user_owned_groups_w_details.length) {
                let groups = this.user.user_owned_groups_w_details;
                if(this.$store.state.group_main_types && this.$store.state.group_main_types.length) {
                    groups = this.user.user_owned_groups_w_details.filter(grp=>this.$store.state.group_main_types.includes(grp.group_type));
                }
                return groups.map(grp=>grp.id);
            }
            return [];
        }
    },
    mounted() {
        this.fetchReminderSchedules();
        
    },
    methods: {
        selectView() {
            this.isListAll === 0 ? this.fetchReminderSchedules() : this.fetchGroupReminderSchedule();
        },
        async fetchReminderSchedules() {
            this.isLoading = true;
            await this.$store.dispatch('fetchAllReminderSchedule').then((result)=>{
                this.reminderScheduleList = result;
                this.totalRows = this.reminder_schedules.length;
                this.isListAll = 0;
                this.selectedGroup =null;
            })
            .catch(()=>{
                // do something
            });
            this.isLoading = false;
        },
        async fetchGroupReminderSchedule() {
            this.isLoading = true;
            await this.$store.dispatch('fetchGroupReminderSchedule').then((result)=>{
                this.reminderScheduleList = result;
                this.totalRows = this.reminder_schedules.length;
                this.isListAll = 1;
                this.selectedGroup =null;
            })
            .catch(()=>{
                // do something
            });
            this.isLoading = false;
        },
        deleteReminderSchedule(reminderScheduleId) {
            this.$store.dispatch('deleteReminderSchedule', reminderScheduleId).then(()=>{
                this.fetchReminderSchedules();
            }).catch(()=>{
                
                // todo: error message
            })
        }
    }
}
</script>

<style>

</style>