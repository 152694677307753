<template lang="pug">
b-overlay(:show="loading", variant="white")
  b-form(@submit.prevent="emitAppointment")
    b-form-group(
      :label="'Client(s)' + ($store.state.optional_appointment_client ? ' (Optional)' : '')",
      label-for="clients"
    )
      b-overlay(
        :show="loadingClients",
        no-center,
        rounded,
        opacity="0",
        spinner-small
      )
        template(#overlay)
          .d-flex.h-100.justify-content-end.p-2
            b-spinner(small)

        v-select.my-1(
          multiple,
          v-model="selectedClient",
          @search="searchClient",
          :options="clientOptions",
          :filterable="false",
          :components="{ OpenIndicator }",
          @option:selected="setSubjectByClient(); expandClientGroup()",
          @option:deselected="setSubjectByClient()"
        )
          template(#search="{ attributes, events }")
            input.vs__search(
              :required="selectedClient.length < 1 && !$store.state.optional_appointment_client",
              v-bind="attributes",
              v-on="events",
              :placeholder="selectedClient.length ? '' : 'Start typing to search for clients...'"
            )
          template(v-slot:option="option")
            span(v-if="option.isGroup")
              b-icon.mr-2(
                icon="people-fill",
                title="Client Group",
                alt="Client Group"
              )
            span(v-else)
              b-icon.mr-2(icon="person-fill", title="Client", alt="Client")
            span {{ option.label }}

          span(slot="no-options")
            b-button.border(
              v-b-modal.new-client,
              variant="light",
              size="sm",
              block
            )
              b-icon(icon="plus", alt="Add Client")
              | Add client

        <!-- Create Client Group -->
        b-button(
          @click="createClientGroup = true",
          v-if="selectedClient.length > 1 && !createClientGroup && !appointmentId",
          size="sm"
        ) Create a new {{ $.clientGroup }} from this clients
        .form-inline(v-if="selectedClient.length > 1 && createClientGroup")
          b-form-input#clientGroupName.mr-1(
            v-model="clientGroupName",
            type="text",
            :placeholder="`Enter new ${$.clientGroup}`",
            size="sm",
            required
          )
          b-button(@click="createClientGroup = false", size="sm") Cancel

    b-form-group(
      label="Attendees (Optional)",
      v-if="$store.state.allow_graph_attendees"
    )
      .d-flex.flex-wrap.border.rounded(style="padding: 0.125rem")
        span.border.rounded.bg-light.px-1(
          v-for="(attendee, index) in attendees",
          :key="attendee",
          style="margin: 0.125rem"
        )
          | {{ attendee }}
          b-icon(
            icon="x",
            alt="Remove",
            @click="removeAttendees(index)",
            style="cursor: pointer"
          )
        input.flex-grow-1.border-0.p-1.px-2(
          type="email",
          v-model="attendee",
          placeholder="Add attendees e-mail",
          @keydown.enter.prevent,
          @keyup.enter="addToAttendees()",
          @keyup.space="addToAttendees()",
          v-on:blur="addToAttendees()"
        )
      p.m-0.text-danger(v-if="attendeeEmailInvalid && attendee != ''") Please enter a valid email address

    // Hide this field since it's already auto-populate from client M365 group
    //b-form-group(:label="$.assignToM365Group" label-for="groupsharing" v-if="$store.state.groups_enabled")
      v-select.my-1(id="groupsharing" v-model="event.group_id" :options="groupOptions" :reduce="grp => grp.id" label="label")
          template(#search="{attributes, events}")
              input(
                  class="vs__search"
                  :required="!event.group_id"
                  v-bind="attributes"
                  v-on="events"
              )

    b-form-group(label="Subject", label-for="subject")
      b-form-input#subject(
        v-model="event.subject",
        type="text",
        placeholder="Add a title",
        maxlength="100",
        required
      )

    b-form-group(label="Time")
      b-overlay(:show="loadingTime", variant="white", rounded, spinner-small)
        .form-row.align-items-center
          .col
            .d-flex.flex-wrap <!-- only wrap when using modal in calender picker -->
              b-form-input#startDate(
                v-model="startDate",
                type="date",
                @change="populateStartTimeSlot(); getRoomAvailability()",
                required
              )
              b-form-select#startTime(
                v-model="startTime",
                @change="getRoomAvailability(); endTime = null",
                :disabled="startTimeSlots.length < 1",
                required
              )
                b-form-select-option(:value="null", disabled) Select time
                b-form-select-option(
                  v-for="timeSlot in startTimeSlotsFiltered",
                  :key="timeSlot.time",
                  :value="timeSlot.time",
                  :disabled="timeSlot.selectedRoomStatus > 0"
                ) {{ timeSlot.displayTime }}{{ timeSlot.status > 0 ? " (busy)" : "" }}{{ timeSlot.selectedRoomStatus > 0 ? " (room occupied)" : "" }}
          .col-1.hide-mobile(align="center") to
          .col-12.py-1.show-mobile(align="center") to
          .col
            .d-flex.flex-wrap <!-- only wrap when using modal in calender picker -->
              b-form-input#endDate(
                v-model="endDate",
                type="date",
                :min="startDate",
                @change="populateEndTimeSlot(); getRoomAvailability()",
                required
              )
              b-form-select#endTime(
                v-model="endTime",
                @change="getRoomAvailability()",
                :disabled="endTimeSlots.length < 1",
                required
              )
                b-form-select-option(:value="null", disabled) Select time
                b-form-select-option(
                  v-for="timeSlot in endTimeSlotsFiltered",
                  :key="timeSlot.time",
                  :value="timeSlot.time",
                  :disabled="timeSlot.time <= startTime || timeSlot.selectedRoomStatus > 0"
                ) {{ timeSlot.displayTime }}{{ timeSlot.status > 0 ? " (busy)" : "" }}{{ timeSlot.selectedRoomStatus > 0 ? " (room occupied)" : "" }}

    b-form-group(v-if="$store.state.enable_recurring")
      b-form-checkbox(v-model="isRecurrence") Is a recurring meeting

    .border.rounded.p-2(v-if="isRecurrence && $store.state.enable_recurring")
      b-form-group(label="Repeat Every")
        b-row
          b-col(cols="4")
            b-form-input(
              type="number",
              v-model.number="recurrence.interval",
              min="1"
            )
          b-col
            b-form-select(v-model="recurrence.type")
              b-form-select-option(value="day") Day
              b-form-select-option(value="week") Week
              b-form-select-option(value="month") Month
              b-form-select-option(value="year") Year
      b-form-group(label="End")
        b-form-input(type="date", v-model="recurrence.end")
      b-form-group
        | Occurs every ...

    b-form-group(
      label="Mode of Contact",
      label-for="modeofcontact",
      v-if="$store.state.mode_of_contact"
    )
      b-form-select#modeofcontact(
        v-model="event.mode_of_contact",
        required,
        @change="clearFieldBasedOnModeOfContact(); populateBothTimeSlot()"
      )
        b-form-select-option(:value="null", disabled) Select mode of contact
        b-form-select-option(value="telephone_conversation") Telephone Conversation
        b-form-select-option(value="session_in_agency") Session in Agency
        b-form-select-option(value="home_visit") Home Visit
        b-form-select-option(value="other_session_outside_agency") Other Session Outside Agency
        b-form-select-option(value="online") Online
        b-form-select-option(value="case_conference") Case Conference

    <!-- Room Booking -->
    template(v-if="!event.is_zoom_meeting")
      div(
        v-if="!$store.state.mode_of_contact || (event.mode_of_contact && !['telephone_conversation', 'home_visit', 'other_session_outside_agency', 'online'].includes(event.mode_of_contact))"
      )
        b-form-group(
          v-if="$store.state.roomlist_mode",
          :label="`${$.roomLocation} (Optional)`",
          label-for="roomlist"
        )
          b-overlay(:show="loadingRooms", rounded, spinner-small)
            b-form-select#roomlist(
              v-model="roomlist",
              :options="availableRoomListDropdown",
              @change="populateRoomSelections(event)"
            ) <!-- :disabled="(!startTime || !endTime) || availableRoomListDropdown.length < 2" -->

        //- b-form-group(
        //-   :label="`${$.roomLocation} (Optional)`",
        //-   label-for="roomlist"
        //- )
        //-   b-overlay(:show="loadingRooms", rounded, spinner-small)
        //-     b-form-select#roomlist(
        //-       v-model="roomlistnew",
        //-       :options="availableRoomListDropdownNew",
        //-       @change="event.room = null"
        //-     ) <!-- :disabled="(!startTime || !endTime) || availableRoomListDropdown.length < 2" -->

        b-form-group(
          v-if="showrooms",
          :label="`${$.roomAvailable} (Optional)`",
          label-for="room"
        )
          b-overlay(:show="loadingRooms", rounded, spinner-small)
            b-form-select#room(
              v-model="event.room",
              @change="setLocationByRoom(); populateBothTimeSlot()"
            ) <!-- :disabled="(!startTime || !endTime) || availableRoomDropdown.length < 2" -->
              b-form-select-option(:value="null") Select a {{ $.roomAvailable }}
              b-form-select-option(
                v-for="place in availableRoomDropdownNew",
                :key="'room_' + place.id",
                :value="place.emailAddress",
                :disabled="place.isAvailable === false"
              ) {{ place.displayName }} {{ place.isAvailable === false ? "(occupied)" : "" }}

      b-form-group(
        label="Location (Optional)",
        label-for="location",
        v-if="!$store.state.mode_of_contact || (event.mode_of_contact && !['telephone_conversation', 'session_in_agency'].includes(event.mode_of_contact))"
      )
        b-form-input#location(
          v-model="event.location",
          type="text",
          placeholder="Add a location"
        )

    div(
      v-if="!$store.state.mode_of_contact || (event.mode_of_contact && !['telephone_conversation', 'session_in_agency', 'home_visit', 'other_session_outside_agency'].includes(event.mode_of_contact))"
    )
      b-form-group(v-if="teams_integration", :disabled="event.is_zoom_meeting")
        b-form-checkbox(v-model="event.is_online_meeting") {{ appointmentId ? "Is a Teams Online Meeting" : "Set as Teams Online Meeting" }}
      b-form-group(
        v-if="zoom_integration",
        :disabled="event.is_online_meeting"
      )
        b-form-checkbox(v-model="event.is_zoom_meeting", v-if="!appointmentId") Set as Zoom Online Meeting
        b-form-checkbox(v-model="event.is_zoom_meeting", disabled, v-else) Is a Zoom Online Meeting
      b-overlay(
        :show="loadingZoom",
        rounded,
        spinner-small,
        v-if="zoom_integration_global && event.is_zoom_meeting && !appointmentId"
      )
        b-form-group(
          label="Zoom Account",
          label-for="zoomacc",
          v-if="zoom_integration_global && event.is_zoom_meeting && !appointmentId",
          description="Set the meeting time first to view available Zoom Accounts"
        )
          b-form-select#zoomacc(
            v-model="event.zoom_account",
            :disabled="!startTime || !endTime || availableZoom.length < 1"
          )
            option(v-for="zoom in availableZoom", :key="zoom", :value="zoom") {{ zoom }}
        b-form-group(
          label="Zoom Account",
          label-for="zoomacc",
          v-if="zoom_integration_global && event.zoom_account && appointmentId"
        )
          b-form-input#zoomacc(v-model="event.zoom_account", disabled)

    b-form-group(label="Description (Optional)", label-for="description")
      b-form-textarea#description(
        v-model="event.description",
        placeholder="Add a description"
      )

    b-form-group
      b-form-checkbox(v-model="useClientReminder") Use client's default reminder

    b-form-group(
      label="Reminder Schedule (Optional)",
      label-for="reminder_schedule"
    )
      v-select#reminder_schedule.my-1(
        v-model="event.reminder_schedule_id",
        :options="reminderScheduleOptions",
        :reduce="(reminderSchedule) => reminderSchedule.content",
        label="label",
        :disabled="useClientReminder"
      )
      b-alert.mt-1(
        variant="primary",
        :show="event.reminder_schedule_id != null"
      )
        b Client(s) will be notified on: <br/>{{ displayNotifyDate }}

    b-form-group(
      label="Reminder Template (Required)",
      label-for="reminder_template",
      required
    )
      v-select#reminder_template.my-1(
        v-model="event.reminder_template_id",
        :options="reminderTemplateOptions",
        :reduce="(reminderTemplate) => reminderTemplate.content",
        label="name",
        :disabled="useClientReminder"
      )
      b-alert.mt-1(
        variant="primary",
        :show="event.reminder_template_id != null"
      )
        p(
          v-if="event.reminder_template_id != null && reminderTemplateOptions.length"
        ) {{ reminderTemplateOptions.find((rt) => rt.content == event.reminder_template_id).label }}
        | <b>Preview: </b><br>
        TemplatePreview(
          v-if="event.reminder_template_id != null && reminderTemplateOptions.length",
          :raw-reminder-template="reminderTemplateOptions.find((rt) => rt.content == event.reminder_template_id).label",
          :appointment-data="getTemplatePreviewData",
          :key="getTemplatePreviewData.id"
        )

    b-form-group
      b-form-checkbox(v-model="event.notify", v-if="!appointmentId") Notify clients on appointment creation
      b-form-checkbox(v-model="event.notify", v-else) Notify clients of changes in appointment

    b-overlay.d-inline-block(:show="submitting", rounded)
      b-button(
        type="submit",
        :disabled="submitting || loadingTime || loadingRooms || loadingClients"
      ) Save

    <!-- Create client modal -->
    b-modal#new-client(title="Create New Client", hide-footer)
      CreateClientForm(@client="addClient($event)")
</template>

<script>
import dayjs from "dayjs";
import CreateClientForm from "@/components/clients/create.vue";
import TemplatePreview from "@/components/reminders/reminder_template_preview.vue";
const teams_integration = !!process.env.VUE_APP_TEAMS_INTEGRATION;
export default {
  props: [
    "appointmentId",
    "submitting",
    "hidenonworking",
    "setStart",
    "setEnd",
    "setRoomId",
  ],
  data() {
    return {
      roomlistnew: [],
      loading: true,
      loadingRooms: false,
      loadingZoom: false,
      loadingTime: false,
      loadingClients: false,
      teams_integration,
      roomlist: null,
      // Office hours (hours only, in 24 hours format)
      workingHourStart: 0,
      workingHourEnd: 24, // (*set as 24 if workingHourEnd is at midnight 00 hour (12AM))
      startTimeSlots: [],
      endTimeSlots: [],
      clientTimeout: null, //debounce timeout
      clientOptions: [],
      reminderScheduleOptions: [],
      reminderTemplateOptions: [],
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null,
      attendee: "",
      attendees: [],
      event: {
        subject: "",
        description: "",
        start: "",
        end: "",
        room: null,
        room_id: null,
        location: "",
        reminder_schedule_id: null,
        reminder_template_id: null,
        clients: [],
        notify: true,
        is_online_meeting: false,
        is_zoom_meeting: false,
        online_meeting_link: false,
        zoom_meeting_link: false,
        zoom_account: "",
        group_id: null,
        mode_of_contact: null,
      },
      selectedClient: [],
      isRecurrence: false,
      recurrence: {
        interval: null, //number
        type: "", //daily,weekly,monthly
        end: "", //date
      },
      OpenIndicator: {
        render: (createElement) => createElement("span", ""),
      },
      createClientGroup: false,
      clientGroupName: "",
      availableRoom: [],
      availableZoom: [],
      useClientReminder: false,
      attendeeEmailInvalid: false, //use to show error message when invalid email is inputted
      originalApptObj: null, //use to not disable time slot that was booked by this appointment
    };
  },
  components: {
    CreateClientForm,
    TemplatePreview,
  },
  computed: {
    localisation() {
      return this.$store.state.localisation;
    },
    availableRoomListDropdownNew() {
      let list = [];

      for (let room of this.availableRoomDropdown) {
        let name = room.displayName.split("_")[0];
        if (!list.find((l) => l.text === name)) {
          list.push({
            text: name,
            value: name,
          });
        }
      }
      return list || [];
    },
    availableRoomDropdownNew() {
      let list = [];

      for (let room of this.availableRoomDropdown) {
        let name = room.displayName;
        if (name.includes(this.roomlistnew)) {
          list.push(room);
        }
      }
      return list || [];
    },
    $() {
      let output = {
        clientGroup: "Client Group",
        roomLocation: "Room Location",
        roomAvailable: "Room Available for Booking",
        assignToM365Group: "Assign to M365 Group",
      };

      Object.assign(output, this.localisation);
      return output;
    },
    startTimeSlotsFiltered() {
      if (this.hidenonworking) {
        return this.startTimeSlots.filter((r) => {
          let hr = Number(r.displayTime.substring(0, 2));
          if (hr < 8) {
            return false;
          }
          if (hr > 21) {
            return false;
          }
          return true;
        });
      }
      return this.startTimeSlots;
    },
    endTimeSlotsFiltered() {
      if (this.hidenonworking) {
        return this.endTimeSlots.filter((r) => {
          let hr = Number(r.displayTime.substring(0, 2));
          let min = Number(r.displayTime.substring(3, 5));

          if (hr < 8) {
            return false;
          }
          if (hr > 22) {
            return false;
          }
          if (hr == 22 && min > 0) {
            return false;
          }
          return true;
        });
      }
      return this.endTimeSlots;
    },
    userGroups() {
      return this.$store.state?.user_groups || [];
    },
    groupOptions() {
      let groups = this.userGroups;
      if (this.$store.state.group_main_types) {
        groups.filter((g) =>
          this.$store.state.group_main_types.includes(g.group_type)
        );
      }
      return (
        this.groups?.map?.((grp) => {
          return {
            id: grp.id,
            label: grp.group_name,
          };
        }) || []
      );
    },
    availableRoomListDropdown() {
      let output = [];
      let unique = new Set();

      this.places.forEach((room) => {
        if (!room.roomListId) {
          return;
        }
        if (unique.has(room.roomListId)) {
          return;
        }
        unique.add(room.roomListId);
        output.push({
          value: room.roomListId,
          text: room.roomListInfo.name,
        });
      });

      // sort a-z
      output.sort(function (a, b) {
        var textA = a.text.toUpperCase();
        var textB = b.text.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      output.unshift({
        text: `Select a ${this.$.roomLocation}`,
        value: null,
      });
      return output;
    },
    availableRoomDropdown() {
      if (!this.$store.state.roomlist_mode || !this.roomlist) {
        return this.places;
      }

      let output = this.places.filter(
        (room) => room.roomListId == this.roomlist
      );

      // sort a-z
      output.sort(function (a, b) {
        var textA = a.displayName.toUpperCase();
        var textB = b.displayName.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

      return output;
    },
    showrooms() {
      if (this.$store.state.roomlist_mode) {
        return !!this.roomlist;
      }
      return true;
    },
    displayNotifyDate() {
      let smsText = "";
      let phoneText = "";
      let today = dayjs().hour("00").minute("00");

      if (this.startDate && this.event.reminder_schedule_id) {
        let rs = this.reminderScheduleOptions.find(
          (rs) => rs.content == this.event.reminder_schedule_id
        );
        if (!rs) {
          return "";
        }

        if (rs.sms.length > 0) {
          for (let i = 0; i < rs.sms.length; i++) {
            let start = this.toDayJS("00:00", this.startDate);

            let date = start.subtract(rs.sms[i], "day");

            if (date.diff(today, "hour") >= 23) {
              smsText += date.format("DD/MM/YYYY") + ", ";
            }
          }
          if (smsText.length > 0) {
            smsText = smsText.substring(0, smsText.length - 2);
            smsText = "SMS: " + smsText;
          }
        }

        if (rs.phone.length > 0) {
          for (let i = 0; i < rs.phone.length; i++) {
            let start = this.toDayJS("00:00", this.startDate);

            let date = start.subtract(rs.phone[i], "day");

            if (date.diff(today, "hour") >= 23) {
              phoneText += date.format("DD/MM/YYYY") + ", ";
            }
          }
          if (smsText.length > 0) {
            phoneText = phoneText.substring(0, phoneText.length - 2);
            phoneText = " | Phone: " + phoneText;
          }
        }
      }
      return (
        smsText + (!this.$store.state.disable_phone_reminder ? phoneText : "")
      );
    },
    getTemplatePreviewData() {
      let data = {
        id: new Date().valueOf(),
        name: "",
        contact_number: "",
        organiser: "",
        subject: this.event.subject,
        date:
          this.startTime && this.startDate
            ? this.toDayJS(this.startTime, this.startDate).format("DD/MM/YYYY")
            : "",
        time:
          this.startTime && this.startDate && this.endTime && this.endDate
            ? this.toDayJS(this.startTime, this.startDate).format("h:mm A") +
              " to " +
              this.toDayJS(this.endTime, this.endDate).format("h:mm A")
            : "",
        start_time:
          this.startTime && this.startDate
            ? this.toDayJS(this.startTime, this.startDate).format("h:mm A")
            : "",
        end_time:
          this.endTime && this.endDate
            ? this.toDayJS(this.endTime, this.endDate).format("h:mm A")
            : "",
        location: this.event.location,
        description: this.event.description,
      };

      if (this.selectedClient.length > 0) {
        data.name = this.selectedClient[0].name;
        data.contact_number = this.selectedClient[0].contact_number;
      }

      return data;
    },
    zoom_integration() {
      return this.$store.state.zoom_enabled;
    },
    zoom_integration_global() {
      return this.$store.state.zoom_global;
    },
    user_info() {
      return this.$store.state.user_info;
    },
    appointment() {
      return this.$store.state.appointment;
    },
    schedule() {
      return this.$store.state.schedule;
    },
    clients() {
      return this.$store.state.clients;
    },
    client_groups() {
      return this.$store.state.client_groups;
    },
    reminder_schedules() {
      return this.$store.state.reminder_schedules;
    },
    reminder_templates() {
      return this.$store.state.reminder_templates;
    },
    places() {
      return this.$store.state.places || [];
    },
    zoom_raw() {
      return this.$store.state.zoom || [];
    },
    zoom() {
      if (this.zoom_integration_global) {
        //console.log(this.places,this.zoom_raw);
        return this.places
          .map((p) => p.emailAddress)
          .filter((val) => this.zoom_raw.includes(val));
      }
      return this.zoom_raw;
    },
  },
  created() {
    //if creating new appointment
    if (!this.appointmentId) {
      // Set date input to today by default
      this.startDate = dayjs().format("YYYY-MM-DD");
      this.endDate = this.startDate;
      this.$emit("dateset", this.startDate);
    }
  },
  async mounted() {
    if (this.appointmentId || this.$route.query.duplicate) {
      await this.getAppointment();
    }

    // emit subject for breadcrumb nav etc.
    if (this.event.subject) {
      this.$emit("subject", this.event.subject);
    }

    // set data from props if any (only when creating appointment, don't do this for edit cause it will overwrite it)
    if (!this.appointmentId) {
      this.startDate = this.setStart
        ? dayjs(this.setStart).format("YYYY-MM-DD")
        : this.startDate;
      this.endDate = this.setEnd
        ? dayjs(this.setEnd).format("YYYY-MM-DD")
        : this.endDate;
      this.startTime = this.setStart
        ? dayjs(this.setStart).format("HH:mm:ss")
        : this.startTime;
      this.endTime = this.setEnd
        ? dayjs(this.setEnd).format("HH:mm:ss")
        : this.endTime;
      if (this.setRoomId) {
        let setRoom = await this.getPlace(this.setRoomId);

        if (setRoom) {
          this.event.room = setRoom.emailAddress; //email address
          this.event.room_id = setRoom.id; //uuid
          this.event.location = setRoom.displayName;

          if (this.$store.state.mode_of_contact) {
            this.event.mode_of_contact = "session_in_agency";
          }
        }
      }
    }

    this.fetchAllPlaces();

    this.loadingTime = true;
    //this.loadingClients = true;
    this.loading = false;

    // loading clients options
    //await Promise.all([this.fetchClients(), this.fetchClientGroups()]);
    //this.loadingClients = false;

    // if there client parameters in url, set client
    if (this.$route.query.client) {
      this.prefillClient(this.$route.query.client);
    }
    if (this.$route.query.group) {
      this.prefillGroup(this.$route.query.group);
    }
    //populateTimeSlot is depends on user_info (need to load getUserInfo() first)
    //await this.getUserInfo(); //already got user_info in populateTimeSlot()
    await this.populateTimeSlot();
    this.loadingTime = false;

    this.fetchReminderSchedules();
    this.fetchReminderTemplates();

    //for when time is pre-filled from props and getAppointment() function, to get room availability at that time
    this.getRoomAvailability();
  },
  methods: {
    dayjs,
    populateRoomSelections() {
      this.getRoomAvailability();
      this.populateBothTimeSlot();
      //event.room=null;
      this.setLocationByRoom();
    },
    toDayJS(time, date) {
      if (!time || !date) {
        return false;
      }

      let timeSplit = time.split(":");
      let hour = timeSplit[0];
      let minute = timeSplit[1];

      return dayjs(date).hour(hour).minute(minute);
    },
    emitAppointment() {
      if (!this.event.reminder_template_id && !this.useClientReminder) {
        alert("Please key in the reminder template");
        return;
      }
      this.emitAppointment = true;
      // Process time to ISO
      const startTime = this.startTime.split(":");
      const startHour = startTime[0];
      const startMinute = startTime[1];

      const endTime = this.endTime.split(":");
      const endHour = endTime[0];
      const endMinute = endTime[1];

      let finalStart = dayjs(this.startDate)
        .hour(startHour)
        .minute(startMinute)
        .tz("Asia/Singapore", true);

      let finalEnd = dayjs(this.endDate)
        .hour(endHour)
        .minute(endMinute)
        .tz("Asia/Singapore", true);

      finalEnd = finalEnd.utc();
      finalStart = finalStart.utc();

      this.event.start = finalStart;
      this.event.end = finalEnd;

      // Process selected clients
      for (let i = 0; i < this.selectedClient.length; i++) {
        this.event.clients = this.event.clients.concat(
          this.selectedClient[i].value
        );
      }
      // remove duplicates in clients
      this.event.clients = [...new Set(this.event.clients)];

      // use client default reminder option chosen
      if (this.useClientReminder) {
        delete this.event.reminder_schedule_id;
        delete this.event.reminder_template_id;
      }

      // remove room in payload if it meant to patch
      if (this.appointmentId) {
        delete this.event.notifyOnCreate;
      }

      // create client geroup
      if (this.createClientGroup) {
        this.addClientGroup();
      }

      // process attendees
      if (this.attendees && this.attendees.length > 0) {
        this.event.attendees = [];
        for (let attendeeEmail of this.attendees) {
          this.event.attendees.push({
            emailAddress: {
              address: attendeeEmail,
            },
          });
        }
      }

      // remove field based on mode of contact
      if (this.event.mode_of_contact) {
        switch (this.event.mode_of_contact) {
          case "telephone_conversation":
            this.event.location = null;
            this.event.is_online_meeting = null;
            this.event.is_zoom_meeting = null;
            this.event.zoom_account = null;
            this.event.room = null;
            this.event.room_id = null;
            break;
          case "session_in_agency":
            this.setLocationByRoom(); // autofill location data
            this.event.is_online_meeting = null;
            this.event.is_zoom_meeting = null;
            this.event.zoom_account = null;
            break;
          case "home_visit":
            this.event.is_online_meeting = null;
            this.event.is_zoom_meeting = null;
            this.event.zoom_account = null;
            this.event.room = null;
            this.event.room_id = null;
            break;
          case "other_session_outside_agency":
            this.event.is_online_meeting = null;
            this.event.is_zoom_meeting = null;
            this.event.zoom_account = null;
            this.event.room = null;
            this.event.room_id = null;
            break;
          case "online":
            this.event.room = null;
            this.event.room_id = null;
            break;
        }
      }

      // emit the processed appointment data
      this.$emit("appointment", this.event);
    },
    async getAppointment() {
      await this.$store
        .dispatch(
          "getAppointment",
          this.appointmentId ? this.appointmentId : this.$route.query.duplicate
        )
        .then((result) => {
          this.originalApptObj = result;

          this.event.subject = result.subject;
          this.event.room = result.room?.emailAddress || null;
          this.event.room_id = result.room_id;
          this.event.location = result.location;
          this.event.description = result.description;
          this.event.group_id = result.group_id || null;
          this.event.reminder_schedule_id = result.reminder_schedule_id;
          this.event.reminder_template_id = result.reminder_template_id;
          this.event.online_meeting_link = result.online_meeting_link;
          this.event.is_online_meeting = !!result.online_meeting_link;
          this.event.zoom_meeting_link = result.zoom_meeting_link;
          this.event.is_zoom_meeting = !!result.zoom_meeting_link;
          this.event.zoom_account = result.zoom_account;
          this.event.mode_of_contact = result.mode_of_contact;
          if (this.appointmentId) {
            this.startDate = dayjs(result.start).format("YYYY-MM-DD");
            this.endDate = dayjs(result.end).format("YYYY-MM-DD");
          }
          // assign roomlist
          if (this.event.room) {
            let places = this.places.filter(
              (place) => place.emailAddress === this.event.room
            );

            if (places.length > 0) {
              this.roomlist = places[0]?.roomListId || null;
            }
          }

          // populate clients field
          for (let i = 0; i < result.clients.length; i++) {
            let value = {
              label:
                result.clients[i].name +
                " (" +
                result.clients[i].contact_number +
                ")",
              value: result.clients[i].id,
              name: result.clients[i].name,
              contact_number: result.clients[i].contact_number,
              isGroup: false,
            };

            this.selectedClient.push(value);
          }

          // populate external attendees list
          if (result.attendees && result.attendees.length > 0) {
            this.attendees = [];
            for (let attendeeEmail of result.attendees) {
              if (attendeeEmail.type === "resource") {
                continue;
              }
              this.attendees.push(attendeeEmail.emailAddress.address);
            }
          }

          // populate time
          this.startTime = dayjs(this.appointment.start).format("HH:mm:ss");
          this.endTime = dayjs(this.appointment.end).format("HH:mm:ss");

          if (this.$route.query.duplicate) {
            this.getRoomAvailability();
          }
        });
    },
    async getUserInfo() {
      await this.$store.dispatch("getUserInfo");
      await this.$store.dispatch("getUserGroups");
    },
    async populateTimeSlot() {
      this.loadingTime = true;
      // load user graph principal name
      await this.$store
        .dispatch("getUserInfo")
        .then(() => {
          let pr = [];
          // Populate time slot based on user availability
          if (this.startDate) {
            pr.push(this.populateStartTimeSlot());
          }
          if (this.endDate) {
            pr.push(this.populateEndTimeSlot());
          }
          return Promise.all(pr);
        })
        .catch(() => {
          // do something
        });
      this.loadingTime = false;
    },
    async searchClient(search) {
      // debounce
      if (this.clientTimeout) clearTimeout(this.clientTimeout);

      this.clientTimeout = setTimeout(async () => {
        // your action
        this.clientOptions = [];
        if (!search) {
          return;
        }

        await this.fetchClients(search);
        await this.fetchClientGroups(search);
      }, 500); // delay
    },
    // prefill client if any
    async prefillClient(clientId) {
      this.loadingClients = true;
      await this.$store.dispatch("getClient", clientId).then((result) => {
        let option = {
          label:
            result.name +
            (result.contact_number ? `(${result.contact_number})` : ""),
          name: result.name,
          contact_number: result.contact_number,
          value: result.id,
          group_id: result.group_id,
          isGroup: false,
        };
        this.selectedClient.push(option);
        this.setSubjectByClient();
      });
      this.loadingClients = false;
    },
    async prefillGroup(groupId) {
      this.loadingClients = true;
      await this.$store
        .dispatch("getClientGroup", groupId)
        .then(({ name, clients }) => {
          for (let client of clients) {
            let option = {
              label:
                client.name +
                (client.contact_number ? `(${client.contact_number})` : ""),
              name: client.name,
              contact_number: client.contact_number,
              value: client.id,
              group_id: client.group_id,
              isGroup: false,
            };
            this.selectedClient.push(option);
          }
          this.event.subject = name + " Appointment";
        });
      this.loadingClients = false;
    },
    async fetchClients(filter = null) {
      this.loadingClients = true;

      let params = {};
      if (filter) {
        params = {
          top: 10,
          filterby: "name",
          filter,
        };
      }
      await this.$store
        .dispatch("fetchAllClients", params)
        .then((result) => {
          let clientObjs = result.value;
          // Prepare option for attendees select
          for (var i = 0; i < clientObjs.length; i++) {
            let option = {
              label:
                clientObjs[i].name +
                (clientObjs[i].contact_number
                  ? `(${clientObjs[i].contact_number})`
                  : ""),
              name: clientObjs[i].name,
              contact_number: clientObjs[i].contact_number,
              value: clientObjs[i].id,
              group_id: clientObjs[i].group_id,
              isGroup: false,
            };

            this.clientOptions.push(option);
          }
        })
        .catch(() => {
          // do something
        });

      this.loadingClients = false;
    },
    async fetchClientGroups(filter = null) {
      this.loadingClients = true;

      let params = {};
      if (filter) {
        params = {
          top: 10,
          filterby: "name",
          filter,
        };
      }
      await this.$store
        .dispatch("fetchAllClientGroups", params)
        .then((result) => {
          let cg = result.value;
          // Prepare option for attendees select
          for (var i = 0; i < cg.length; i++) {
            let clientIds = [];

            for (let j = 0; j < cg[i].clients.length; j++) {
              clientIds.push({
                label:
                  cg[i].clients[j].name +
                  (cg[i].clients[j].contact_number
                    ? `(${cg[i].clients[j].contact_number})`
                    : ""),
                name: cg[i].clients[j].name,
                contact_number: cg[i].clients[j].contact_number,
                value: cg[i].clients[j].id,
                group_id: cg[i].clients[j].group_id,
                isGroup: false,
              });
            }

            let option = {
              label: cg[i].name,
              name: cg[i].name,
              contact_number: cg[0].contact_number,
              value: clientIds,
              isGroup: true,
            };

            this.clientOptions.push(option);
          }
        })
        .catch(() => {
          // do something
        });

      this.loadingClients = false;
    },
    async populateBothTimeSlot(usersArray = null) {
      if (!usersArray && !this.attendees?.length) {
        usersArray = this.user_info.graph_user_principal_name;
      }
      await Promise.allSettled([
        this.populateStartTimeSlot(usersArray),
        this.populateEndTimeSlot(usersArray),
      ]);
    },
    async populateStartTimeSlot(usersArray = null) {
      if (!usersArray) {
        usersArray = this.user_info.graph_user_principal_name;
      }
      this.startTimeSlots = [];

      // To make sure endDate is always larger than startDate
      if (this.startDate > this.endDate) {
        this.endDate = this.startDate;
        // Update endTime time slots
        this.populateEndTimeSlot();
      }

      if (!this.user_info) {
        await this.getUserInfo();
      }

      // Get user availability through MS Graph API
      var { availabilityView, scheduleItems } = await this.getSchedule(
        usersArray,
        this.startDate
      );
      this.$emit("startschedule", scheduleItems);

      // Get room availability through MS Graph API
      let selectedRoomAvailability = null;
      if (this.event.room) {
        let roomSchedule = await this.getSchedule(
          this.event.room,
          this.startDate
        );
        selectedRoomAvailability = roomSchedule.availabilityView;
      }

      // Populate time slot based on user availability
      var availabilityViewIndex = 0;
      for (var i = this.workingHourStart; i < this.workingHourEnd; i++) {
        for (var j = 0; j < 60; j += 15) {
          let hh = ("0" + i).slice(-2);
          let mm = ("0" + j).slice(-2);

          // make all selectedRoomStatus in this time range, 0(available)
          let roomStatus = selectedRoomAvailability
            ? selectedRoomAvailability.charAt(availabilityViewIndex)
            : 0;
          if (
            this.originalApptObj?.room &&
            this.event.room === this.originalApptObj.room.emailAddress
          ) {
            if (
              this.startDate ===
              dayjs(this.originalApptObj.start).format("YYYY-MM-DD")
            ) {
              if (
                `${hh}${mm}` >=
                  dayjs(this.originalApptObj.start).format("HHmm") &&
                `${hh}${mm}` < dayjs(this.originalApptObj.end).format("HHmm")
              ) {
                roomStatus = 0;
              }
            }
          }

          this.startTimeSlots.push({
            time: hh + ":" + mm + ":00", //HH:mm:ss
            displayTime: hh + ":" + mm,
            status: availabilityView.charAt(availabilityViewIndex),
            selectedRoomStatus: roomStatus,
          });
          availabilityViewIndex++;
        }
      }
    },
    // Populate time slot based on user availability
    async populateEndTimeSlot(usersArray = null) {
      if (!usersArray && !this.attendees?.length) {
        usersArray = this.user_info.graph_user_principal_name;
      }
      this.endTimeSlots = [];

      if (!this.user_info) {
        await this.getUserInfo();
      }

      // Get user availability through MS Graph API
      var { availabilityView } = await this.getSchedule(
        usersArray,
        this.endDate
      );

      // Get room availability through MS Graph API
      let selectedRoomAvailability = null;
      if (this.event.room) {
        let roomSchedule = await this.getSchedule(
          this.event.room,
          this.endDate
        );
        selectedRoomAvailability = roomSchedule.availabilityView;
      }

      // Populate time slot based on user availability
      var availabilityViewIndex = 0;
      for (var i = this.workingHourStart; i < this.workingHourEnd; i++) {
        for (var j = 0; j < 60; j += 15) {
          let hh = ("0" + i).slice(-2);
          let mm = ("0" + j).slice(-2);

          if (this.hidenonworking && i == 22 && !!j) {
            break;
          }

          // make all selectedRoomStatus in this time range, 0(available)
          let roomStatus = selectedRoomAvailability
            ? selectedRoomAvailability.charAt(availabilityViewIndex)
            : 0;
          if (
            this.originalApptObj?.room &&
            this.event.room === this.originalApptObj.room.emailAddress
          ) {
            if (
              this.endDate ===
              dayjs(this.originalApptObj.end).format("YYYY-MM-DD")
            ) {
              if (
                `${hh}${mm}` >=
                  dayjs(this.originalApptObj.start).format("HHmm") &&
                `${hh}${mm}` < dayjs(this.originalApptObj.end).format("HHmm")
              ) {
                roomStatus = 0;
              }
            }
          }

          this.endTimeSlots.push({
            time: hh + ":" + mm + ":00", //HH:mm:ss
            displayTime: hh + ":" + mm,
            status: availabilityView.charAt(availabilityViewIndex),
            selectedRoomStatus: roomStatus,
          });
          availabilityViewIndex++;
        }
      }
    },
    // This is to check user availability in a certain range of time
    async getSchedule(id, date) {
      var availabilityView = "";
      var scheduleItems = "";
      if (!Array.isArray(id)) {
        id = [id];
      } else {
        id = [...id, this.user_info.graph_user_principal_name];
      }

      if (this.attendees.length) {
        id = [...this.attendees, this.user_info.graph_user_principal_name];
      }

      const payload = {
        Schedules: id,
        StartTime: {
          dateTime:
            date + "T" + ("0" + this.workingHourStart).slice(-2) + ":00:00",
          timeZone: "Asia/Singapore",
        },
        EndTime: {
          dateTime:
            date + "T" + ("0" + (this.workingHourEnd - 1)).slice(-2) + ":59:00",
          timeZone: "Asia/Singapore",
        },
        availabilityViewInterval: "15",
      };

      const allScheduleItems = [];
      await this.$store
        .dispatch("getSchedule", { payload: payload })
        .then((result) => {
          //console.log(id, result.value);

          result.value.forEach((i) => {
            if (!i.error) {
              i.scheduleItems.forEach((s) => {
                allScheduleItems.push({
                  ...s,
                  who: i.scheduleId,
                });
              });
            }
          });

          availabilityView = result.value[0].availabilityView;
          scheduleItems = allScheduleItems; //result.value[0].scheduleItems;
        })
        .catch(() => {
          // do something
          //console.log("fail");
        });

      //console.log(availabilityView,scheduleItems);

      return { availabilityView, scheduleItems };
    },
    async fetchReminderSchedules() {
      await this.$store
        .dispatch("fetchAllReminderSchedule")
        .then((result) => {
          for (let schedule of result) {
            let name = schedule.name ? schedule.name : "Untitled";

            let option = {
              //The Phone options is disabled from the list of option
              label:
                name +
                " (SMS: " +
                schedule.trigger_schedule.sms.join(", ") +
                (!this.$store.state.disable_phone_reminder
                  ? " | Phone: " + schedule.trigger_schedule.phone.join(", ")
                  : "") +
                ")",
              content: schedule.id,
              sms: schedule.trigger_schedule.sms,
              phone: schedule.trigger_schedule.phone,
            };

            this.reminderScheduleOptions.push(option);
          }
        })
        .catch(() => {
          // do something
        });
    },
    async fetchReminderTemplates() {
      await this.$store
        .dispatch("fetchAllReminderTemplate")
        .then((result) => {
          for (let template of result) {
            let option = {
              label: template.content.reminder,
              name: template.name ? template.name : template.content.reminder,
              content: template.id,
            };

            this.reminderTemplateOptions.push(option);
          }
        })
        .catch(() => {
          // do something
        });
    },
    async fetchAllPlaces() {
      this.loadingRooms = true;
      await this.$store.dispatch("fetchAllPlaces");

      // fill up roomlist value if it edit or duplicate form
      if (this.event.room) {
        let places = this.places.filter(
          (place) => place.emailAddress === this.event.room
        );

        if (places.length > 0) {
          this.roomlist = places[0]?.roomListId || null;
        }
      }

      this.loadingRooms = false;
      this.roomlist = "all_rooms";
      this.populateRoomSelections();
      if (this.zoom_integration_global) {
        await this.$store.dispatch("fetchAllZoom");
      }
    },
    // Autofill subject field
    setSubjectByClient() {
      if (this.appointmentId) {
        return;
      }

      this.event.subject = "";
      if (this.selectedClient.length < 1) {
        return;
      }
      this.setM365GroupbyClient();
      for (let i = 0; i < this.selectedClient.length; i++) {
        this.event.subject +=
          i == this.selectedClient.length - 1
            ? this.selectedClient[i].name
            : this.selectedClient[i].name + ", ";
      }
      this.event.subject += " Appointment";
    },
    // expand any selected client group to individual client from its members
    expandClientGroup() {
      let indexToSplice = [];
      for (let [index, client] of this.selectedClient.entries()) {
        if (client.isGroup === true) {
          indexToSplice.push(index);

          for (let clientId of client.value) {
            this.selectedClient.push(clientId);
          }
        }
      }
      // remove group selected
      for (let i of indexToSplice) {
        this.selectedClient.splice(i, 1);
      }
      // remove any duplicate selection
      this.selectedClient = this.selectedClient.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.value === value.value)
      );
    },
    setM365GroupbyClient() {
      if (!this.$store.state.groups_enabled) {
        return;
      }
      let group_id = this.selectedClient?.[0]?.group_id;
      if (group_id && !this.event.group_id) {
        this.event.group_id = group_id;
      }
    },
    // Autofill location field
    setLocationByRoom() {
      this.event.location = "";
      this.event.room_id = null;

      if (!this.event.room) {
        return;
      }

      for (let place of this.places) {
        if (place.emailAddress == this.event.room) {
          this.event.location = place.address?.street;
          this.event.room_id = place.id;
          break;
        }
      }
    },
    async getRoomAvailability() {
      if (!this.startTime || !this.endTime) {
        return;
      }
      // return if roomlist is not selected yet
      if (this.$store.state.roomlist_mode && !this.roomlist) {
        return;
      }

      this.$emit("tempevent", {
        subject: this.event.subject,
        start: this.startDate + " " + this.startTime,
        end: this.endDate + " " + this.endTime,
      });

      this.availableRoom = [];
      let roomId = [];

      if (!this.places) {
        await this.fetchAllPlaces();
      }

      for (let place of this.availableRoomDropdown) {
        roomId.push(place.emailAddress);
      }

      let diff = dayjs(this.endDate + "T" + this.endTime + ".000Z").diff(
        this.startDate + "T" + this.startTime + ".000Z",
        "m"
      );
      if (diff < 5) {
        diff = 5;
      }
      if (diff > 1440) {
        diff = 1440;
      }

      // split roomId array to chunks with 20 item each chunk (to accomodate graph api limitation; 20 entity per request)
      let roomIdChunks = [];
      for (let [index, room] of roomId.entries()) {
        // split array by chunck (20 per chunk)
        let chunkIndex = Math.floor(index / 20);
        if (!roomIdChunks[chunkIndex]) {
          roomIdChunks[chunkIndex] = []; // start a new chunk
        }
        roomIdChunks[chunkIndex].push(room);
      }
      this.loadingZoom = true;
      // Get user availability through MS Graph API
      let availableRoom = [];
      console.log("chunk", roomIdChunks);
      for (let roomIdChunk of roomIdChunks) {
        const payload = {
          Schedules: roomIdChunk,
          StartTime: {
            dateTime: this.startDate + "T" + this.startTime,
            timeZone: "Asia/Singapore",
          },
          EndTime: {
            dateTime: this.endDate + "T" + this.endTime,
            timeZone: "Asia/Singapore",
          },
          availabilityViewInterval: 15,
        };

        this.loadingRooms = true;

        try {
          const result = await this.$store.dispatch("getSchedule", {
            payload: payload,
          });
          // Replace availability to available if original room is same as selected room
          for (let schedule of result.value) {
            if (this.originalApptObj && this.originalApptObj.room) {
              let room = this.originalApptObj.room.emailAddress;
              let originalStart = dayjs(this.originalApptObj.start);
              let originalEnd = dayjs(this.originalApptObj.end);
              let currentStart = dayjs.tz(
                this.startDate + "T" + this.startTime,
                "Asia/Singapore"
              );

              if (schedule.scheduleId === room) {
                for (let i = 0; i < schedule.availabilityView.length; i++) {
                  let currDatetime = currentStart.add(i * 15, "minute");

                  //if currDateTime is in range of originalStart and originalEnd
                  if (
                    originalStart.isSame(currDatetime) ||
                    originalEnd.isSame(currDatetime) ||
                    (currDatetime.isAfter(originalStart) &&
                      currDatetime.isBefore(originalEnd))
                  ) {
                    schedule.availabilityView =
                      schedule.availabilityView.substring(0, i) +
                      "0" +
                      schedule.availabilityView.substring(i + 1);
                  }
                }
              }
            }

            if (!schedule.availabilityView) {
              continue;
            }
            if (
              schedule.availabilityView.indexOf("1") >= 0 ||
              schedule.availabilityView.indexOf("2") >= 0
            ) {
              continue;
            }
            console.log("AVAILABLE", schedule.scheduleId);
            availableRoom.push(schedule.scheduleId);
          }
        } catch (err) {
          console.log(err);
        }
      }

      // empty option
      this.availableRoom = [{ id: 0, displayName: "", emailAddress: null }];

      console.log("AR", availableRoom);
      for (let place of this.places) {
        console.log("CHECKING", place);
        if (availableRoom.indexOf(place.emailAddress) >= 0) {
          place.isAvailable = true;
          this.availableRoom.push(place);
        } else {
          place.isAvailable = false;
        }
      }
      this.loadingRooms = false;

      if (!this.zoom_integration_global) {
        return;
      }

      if (!this.zoom) {
        await this.fetchAllPlaces();
      }
      const payload2 = {
        Schedules: this.zoom,
        StartTime: {
          dateTime: this.startDate + "T" + this.startTime,
          timeZone: "Asia/Singapore",
        },
        EndTime: {
          dateTime: this.endDate + "T" + this.endTime,
          timeZone: "Asia/Singapore",
        },
        availabilityViewInterval: diff,
      };

      let availableZoom = [];
      await this.$store
        .dispatch("getSchedule", { payload: payload2 })
        .then((result) => {
          for (let schedule of result.value) {
            if (
              schedule?.availabilityView?.indexOf?.("1") >= 0 ||
              schedule?.availabilityView?.indexOf?.("2") >= 0
            ) {
              continue;
            }
            availableZoom.push(schedule.scheduleId);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // empty option
      this.availableZoom = [""];

      for (let zoom of this.zoom) {
        if (availableZoom.indexOf(zoom) >= 0) {
          this.availableZoom.push(zoom);
        }
      }
      this.loadingZoom = false;
    },
    async addClient(client) {
      // map client group to ids
      let payload = { ...client };
      payload.client_groups = payload.client_groups
        ? payload.client_groups.map((cg) => cg.content)
        : [];

      await this.$store
        .dispatch("addClient", { payload })
        .then((result) => {
          let option = {
            label: result.name + " (" + result.contact_number + ")",
            name: result.name,
            value: result.id,
            isGroup: false,
          };

          //this.clientOptions.push(option);
          this.selectedClient.push(option);
          this.setSubjectByClient();

          this.$bvModal.hide("new-client");
        })
        .catch(() => {
          // show error message
        });
    },
    async addClientGroup() {
      let payload = {
        name: this.clientGroupName,
        clients: this.event.clients,
      };

      await this.$store.dispatch("addClientGroup", { payload: payload });
    },
    async getPlace(placeId) {
      return await this.$store
        .dispatch("getPlace", placeId)
        .then((result) => {
          return result;
        })
        .catch(() => {
          return false;
        });
    },
    // add attendee email to attendees array
    addToAttendees() {
      this.attendeeEmailInvalid = false;

      //check for valid email address
      let emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      if (!this.attendee.match(emailRegex)) {
        this.attendeeEmailInvalid = true;
        return false;
      }

      if (!this.attendees.includes(this.attendee))
        this.attendees.push(this.attendee);

      this.attendee = "";
      return true;
    },
    removeAttendees(index) {
      this.attendees.splice(index, 1);
    },
    // remove field based on mode of contact
    clearFieldBasedOnModeOfContact() {
      switch (this.event.mode_of_contact) {
        case "telephone_conversation":
          this.event.location = null;
          this.event.is_online_meeting = null;
          this.event.is_zoom_meeting = null;
          this.event.zoom_account = null;
          this.roomlist = null;
          this.event.room = null;
          this.event.room_id = null;
          break;
        case "session_in_agency":
          this.event.is_online_meeting = null;
          this.event.is_zoom_meeting = null;
          this.event.zoom_account = null;
          break;
        case "home_visit":
          this.event.is_online_meeting = null;
          this.event.is_zoom_meeting = null;
          this.event.zoom_account = null;
          this.roomlist = null;
          this.event.room = null;
          this.event.room_id = null;
          break;
        case "other_session_outside_agency":
          this.event.is_online_meeting = null;
          this.event.is_zoom_meeting = null;
          this.event.zoom_account = null;
          this.roomlist = null;
          this.event.room = null;
          this.event.room_id = null;
          break;
        case "online":
          this.event.location = null;
          this.roomlist = null;
          this.event.room = null;
          this.event.room_id = null;
          break;
      }
    },
  },
  watch: {
    startDate(v) {
      this.$emit("dateset", v);
    },
    "event.zoom_account"(acc) {
      if (acc) {
        let selRoom = this.availableRoom.find(
          (room) => room.emailAddress == acc
        );
        if (selRoom) {
          this.event.room = acc;
          this.event.room_id = selRoom.id;
          this.event.location = selRoom.displayName;
        } else {
          this.event.room = null;
          this.event.room_id = null;
          this.event.location = "Zoom";
        }
      }
    },
    attendees: {
      async handler(val) {
        console.log(val);
        const domain = this.$store?.state?.user_info?.graph_user_principal_name
          ?.split("@")?.[1]
          ?.toLowerCase();
        const toCheck = val.filter((mail) =>
          mail?.toLowerCase()?.includes(domain)
        );

        await this.populateBothTimeSlot(toCheck);
      },
      deep: true,
    },
  },
};
</script>

<style>
.hide-mobile {
  display: inline;
}
.show-mobile {
  display: none;
}
@media only screen and (max-width: 768px) {
  .hide-mobile {
    display: none;
  }
  .show-mobile {
    display: inline;
  }
}
</style>