<template lang="pug">
  .about
    div(style="color:grey")
      router-link(to="/clients") Clients
      span.mx-1 ›
      span {{$.clientGroup}}
    h2 {{$.clientGroup}}

    div
      b-nav(tabs)
        b-nav-item(to="/clients") Clients
        b-nav-item(to="/client-group" :active="true") {{$.clientGroup}}

      b-card.shadow.border-0
        ClientGroup
</template>
<script>
import ClientGroup from '@/components/client_group/list.vue';

export default {
  name: 'ClientGroups',
  components: {
    ClientGroup
  },
  computed: {
    localisation(){
      return this.$store.state.localisation;
    },
    $(){
      let output ={
        "clientGroup": "Client Group",
      };
              
      Object.assign(output,this.localisation);
      return output;
    },
  }
}
</script>