import axios from 'axios';

const $ = axios.create({
    baseURL:window.location.origin+'/api/'
});

export default {
    state: {
        client_group: {},
        client_groups: []
    },
    mutations: {
        client_group(state, client_group) {
            state.client_group = client_group;
        },
        client_groups(state, client_groups) {
            state.client_groups = client_groups;
        }
    },
    actions: {
        async fetchUserClientGroups(store,payload={}) {
            let queryParams = new URLSearchParams(payload);
            const {data:{result}} = await $.get(`user/client_group?${queryParams.toString()}`);
            store.commit('client_groups',result);
            return result;
        },
        async fetchAllClientGroups(store,payload={}) {
            let queryParams = new URLSearchParams(payload);
            const {data:{result}} = await $.get(`client_group?${queryParams.toString()}`);
            store.commit('client_groups',result);
            return result;
        },
        async getClientGroup(store, clientGroupId) {
            const {data:{result}} = await $.get(`client_group/${clientGroupId}`);
            store.commit('client_group',result);
            return result;
        },
        async addClientGroup(store, {payload}) {
            const {data: {result}} = await $.post(`client_group`, payload);
            
            return result;
        },
        async patchClientGroup(store, { payload, client_group_id }){
            const {data:{result}} = await $.patch(`client_group/${client_group_id}`, payload);
            store.commit('client_group', result);
            return result;

        },
        async deleteClientGroup(store, clientGroupId) {
            const {data:{result}} = await $.delete(`client_group/${clientGroupId}`);

            return result;
        }

    }
}