<template lang="pug">
    div
        //Breadcrumb navigation
        div(style="color:grey")
            router-link(:to="{ name: 'Appointments'}") Appointments
            span.mx-1 ›
            span(v-if="appointmentSubject")
                router-link(:to="'/appointments/'+$route.params.appointment_id") {{appointmentSubject}}
                span.mx-1 ›
                span Edit Appointment
        //router-link(:to="'/appointments/'+$route.params.appointment_id") Back to Appointment
        h2 Edit Appointment

        b-card.border-0(style="max-width: 1280px")
            <b-alert variant="danger" :show="isError">{{errorMessage}}</b-alert>

            AppointmentForm(:appointment-id="$route.params.appointment_id" @appointment="editAppointment($event)" @subject="setSubject($event)" :submitting="submitting")

            hr
            div.d-flex.align-items-center.my-4
                b-button.mr-2(v-b-modal.delete-modal variant='outline-danger') 
                    b-icon.mr-2(icon="trash" title="Time")
                    | Delete Appointment
                span Caution! This will permanently delete this appointment. If you want client to be notified, you can cancel the appointment instead. Deleting an appointment won't notify your client.

            b-modal(id="delete-modal" title="Delete Appointment?" @ok="deleteAppointment" cancel-variant="outline-secondary" ok-variant="danger" ok-title="Delete")
                p This will permanently delete this appointment.
    
</template>

<script>
import AppointmentForm from '@/components/appointments/form.vue';

export default {
    name: 'EditAppointment',
    data() {
        return {
            appointmentSubject: '',
            submitting: false,
            isError: false,
            errorMessage: ""
        };
    },
    components: {
        AppointmentForm
    },
    methods: {
        async editAppointment(appointment) {
            this.submitting = true;
            await this.$store.dispatch('patchAppointment',{payload: appointment, appointment_id: this.$route.params.appointment_id}).then(result=>{
                window.location.href = `/appointments/${result.id}`;
            }).catch((err)=>{
                this.isError = true;
                this.errorMessage = err;
            })
            this.submitting = false;
        },
        async deleteAppointment() {
            this.submitting = true;
            await this.$store.dispatch('deleteAppointment', this.$route.params.appointment_id).then(()=>{
                window.location.href = `/appointments`;
            }).catch((err)=>{
                this.isError = true;
                this.errorMessage = err;
            })
            this.submitting = false;
        },
        setSubject(subject) {
            this.appointmentSubject = subject;
        }
    }
}
</script>

<style>

</style>