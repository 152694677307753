<template lang="pug">
    div
        //Breadcrumb navigation
        div(style="color:grey")
            router-link(to="/clients") Clients
            span.mx-1 ›
            span Add Client
        //router-link(to="/clients") Back to Clients List
        h2 Add Client

        b-card.border-0(style="max-width: 1280px")
            <b-alert variant="danger" :show="isError" ref="errorMsg">{{errorMessage}}</b-alert>

            CreateClientForm(@client="addClient($event)")
    
</template>

<script>
import CreateClientForm from '@/components/clients/create.vue';

export default {
    name: 'NewClient',
    data() {
        return {
            isError: false,
            errorMessage: ""
        };
    },
    components: {
        CreateClientForm
    },
    methods: {
        async addClient(client) {
            this.isError = false;
            this.errorMessage = "";
            if(!this.$store.state.enable_client_nickname) { delete client.nickname; }

            // regex check phone no
            let regex = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;
            let contact_number = client.contact_number.replace(/\s/g, '');
            //Ensure that the invalid contact number is executed when it does not match regex & is not empty
            if(!contact_number.match(regex) && !(this.$store.state.optional_client_contact_number && contact_number==='')) {
                this.isError = true;
                this.errorMessage = "Invalid contact number, expected format +XXXXXXXXXX";
                //Vue updates the dom asynchronously, To reflect changes immediately use vm.$nextTick(callback)
                this.$nextTick(() => {this.$refs.errorMsg.$el.scrollIntoView();});
                return false;
            }

            // map client group to ids
            let payload = { ...client }
            payload.client_groups = payload.client_groups ? payload.client_groups.map(cg=>cg.content) : [];

            await this.$store.dispatch('addClient',{payload}).then((result)=>{
                window.location.href = `/clients/${result.id}`;
            }).catch((err)=>{
                this.isError = true;
                this.errorMessage = err;
                this.errorMessage += ". Contact number may already exist in your client list."
                this.$nextTick(() => {this.$refs.errorMsg.$el.scrollIntoView();});
            })
        }
    }
}
</script>

<style>

</style>