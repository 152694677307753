<template lang="pug">
    div(v-if="user.is_admin")
        h2 Admin Panel

        b-tabs(lazy)
            b-tab.bg-white.p-4.border.border-top-0.rounded-bottom(title="Audit Log" v-if="$store.state.audit_log")
                AuditLog
    div(v-else)
        b-alert(show variant="danger") Unauthorised!
    
</template>
<script>
import AuditLog from '@/components/admin/audit_log.vue';

export default {
  name: 'Admin',
  components: {
    AuditLog
  },
  data() {
    return {
    }
  },
  computed: {
    user(){
      return this.$store.state.user;
    },
  },
  created() {
    
  },
  mounted() {
    
  },
  methods: {
    
  }
}
</script>
<style lang="scss">

</style>