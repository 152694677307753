<template lang="pug">
  div
    div.mb-3
      div.d-flex.justify-content-between.align-items-end.flex-wrap.my-3
        <!-- Left side of the flex div -->
        b-button(to="/appointments/create" role="button")
          b-icon(icon="plus" aria-hidden="true")
          | New Appointments
        div
          b-form-select(v-model="listBy" :options="listByOptions" @change="refreshAppointments()" style="background-color:transparent;max-width:12em")
          <!-- Right side of the flex div -->

      b-alert(
        @dismissed="recordDismiss"
        dismissible
        :show="hasDeclineds"
        ) 
          p(
            v-for="(declined, i) in declineds" 
            :key="'decline_'+ i"
          ) {{declined.name}} has declined to join the "{{declined.subject}}" appointment.
      h2 Upcoming Appointments
      div(v-if="isLoading")
        div.d-flex.flex-wrap.w-100
          b-card.appointment-box-loading.border-0
          b-card.appointment-box-loading.border-0
      div(v-else)
        div.d-flex.flex-wrap.w-100(v-if="upcomingAppointments.length > 0")
          router-link.appointment-box.border-0(
            v-for="appointment in upcomingAppointments",
            :key="appointment.id",
            :to="{ name: 'SingleAppointment', params: { appointment_id: appointment.id }}",
            tag="b-card",
            :aria-label="`${appointment.subject} at ${dayjs(appointment.start).format('DD/MM/YYYY')} ${dayjs(appointment.start).format('HH:mm')} to ${dayjs(appointment.start).isSame(appointment.end, 'day') ? dayjs(appointment.end).format('HH:mm') : dayjs(appointment.end).format('DD/MM/YYYY [at] HH:mm')}. ${appointment.location ? 'Location '+appointment.location+'.' : ''} Organiser ${appointment.organiser.graph_display_name}`",
            role="button"
          )
            div.d-flex.flex-column(aria-hidden="true")
              span
                b-badge.mr-1(v-if="appointment.cancelled" variant="danger") Cancelled
                b-badge.mr-1(v-if="appointment.no_show" variant="warning") No-show
                b {{appointment.subject}}
              span
                b-icon.mr-2(icon="calendar" title="Date")
                | {{ dayjs(appointment.start).format('DD/MM/YYYY') }}
              span
                b-icon.mr-2(icon="clock" title="Time")
                | {{ dayjs(appointment.start).format('h:mm A') }} to 
                <span v-if="dayjs(appointment.start).isSame(appointment.end, 'day')">{{ dayjs(appointment.end).format('h:mm A') }}</span>
                <span v-else>{{ dayjs(appointment.end).format('DD/MM/YYYY [at] h:mm A') }}</span>
              span(v-if="appointment.location" title="Location")
                b-icon.mr-2(icon="geo-alt" title="Location")
                | {{appointment.location}}
              span(v-if="$store.state.roomlist_mode && getPlace(appointment.room_id)" title="Room")
                b-icon.mr-2(icon="building" title="Room")
                | {{ getPlace(appointment.room_id).roomListInfo.name }}
              span(v-if="appointment.user_id")
                b-icon.mr-2(icon="person" title="Organiser")
                | {{appointment.organiser.graph_display_name}}
              span
                b-icon.mr-2(icon="people" title="Client")
                span.text-muted.font-italic(v-if="appointment.clients.length <= 0") No client specified
                span(v-for="attendee in appointment.clients" :key="attendee.id")
                  b-badge.border.mr-1.mb-1.p-1(v-if="attendee.declined" variant="danger") {{ attendee.name }}
                  b-badge.border.mr-1.mb-1.p-1(v-else variant="light") {{ attendee.name }}
              span.mt-3(v-if="appointment.description")
                | {{appointment.description}}
        div(v-else style="min-height:8em")
          p.pt-2.font-italic No upcoming appointments

    div
      h3 Recent Appointments

      div(v-if="isLoading")
        div.d-flex.flex-wrap.w-100
          b-card.appointment-box-loading.border-0
          b-card.appointment-box-loading.border-0
      div(v-else)
        div.d-flex.flex-wrap.w-100(v-if="recentAppointments.length > 0")
          router-link.appointment-box.border-0(
            v-for="appointment in recentAppointments",
            :key="appointment.id",
            :to="{ name: 'SingleAppointment', params: { appointment_id: appointment.id }}",
            tag="b-card",
            :aria-label="`${appointment.subject} at ${dayjs(appointment.start).format('DD/MM/YYYY')} ${dayjs(appointment.start).format('HH:mm')} to ${dayjs(appointment.start).isSame(appointment.end, 'day') ? dayjs(appointment.end).format('HH:mm') : dayjs(appointment.end).format('DD/MM/YYYY [at] HH:mm')}. ${appointment.location ? 'Location '+appointment.location+'.' : ''} Organiser ${appointment.organiser.graph_display_name}`",
            role="button"
          )
            div.d-flex.flex-column(aria-hidden="true")
              span
                b-badge.mr-1(v-if="appointment.cancelled" variant="danger") Cancelled
                b-badge.mr-1(v-if="appointment.no_show" variant="warning") No-show
                b {{appointment.subject}}
              span
                b-icon.mr-2(icon="calendar" title="Date")
                | {{ dayjs(appointment.start).format('DD/MM/YYYY') }}
              span
                b-icon.mr-2(icon="clock" title="Time")
                | {{ dayjs(appointment.start).format('h:mm A') }} to 
                <span v-if="dayjs(appointment.start).isSame(appointment.end, 'day')">{{ dayjs(appointment.end).format('h:mm A') }}</span>
                <span v-else>{{ dayjs(appointment.end).format('DD/MM/YYYY [at] h:mm A') }}</span>
              span(v-if="appointment.location" title="Location")
                b-icon.mr-2(icon="geo-alt" title="Location")
                | {{appointment.location}}
              span(v-if="$store.state.roomlist_mode && getPlace(appointment.room_id)" title="Room")
                b-icon.mr-2(icon="building" title="Room")
                | {{ getPlace(appointment.room_id).roomListInfo.name }}
              span(v-if="appointment.user_id")
                b-icon.mr-2(icon="person" title="Organiser")
                | {{appointment.organiser.graph_display_name}}
              span
                b-icon.mr-2(icon="people" title="Client")
                span.text-muted.font-italic(v-if="appointment.clients.length <= 0") No client specified
                span(v-for="attendee in appointment.clients" :key="attendee.id")
                  b-badge.border.mr-1.mb-1.p-1(v-if="attendee.declined" variant="danger") {{ attendee.name }}
                  b-badge.border.mr-1.mb-1.p-1(v-else variant="light") {{ attendee.name }}
              span.mt-3(v-if="appointment.description")
                | {{appointment.description}}
        div(v-else style="min-height:8em")
          p.pt-2.font-italic No recent appointments
</template>
<script>
import dayjs from 'dayjs';
import Loading from '@/components/loader/Loading.vue';
const declinedsKey = 'dismissedDeclineds';
export default {
  name: 'AppointmentOverview',
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: true,
      listBy: 'own',
      sortBy: 0,
      sortByOptions: [
        { value: 0, text: 'Date' },
        { value: 1, text: 'Subject' }
      ],
      fetchedAppointments: []
    }
  },
  computed: {
    hasDeclineds() {
      return !!this.declineds.length
    },
    declineds() {
      let dismissed = localStorage.getItem(declinedsKey) || [];
      if (typeof dismissed === 'string') {
        dismissed = JSON.parse(dismissed);
      }
      const futureAppointments = this.fetchedAppointments.filter(a => dayjs(a.start).isAfter(dayjs()));

      let declinedClients = []
      for (let a of futureAppointments) {
        for (let c of a.clients) {
          if (c.declined) {
            declinedClients.push({
              subject: a.subject,
              name: c.name,
              appointment_id: a.id,
              client_id: c.id 
            });
          }
        }
      }
      return declinedClients.filter(dis => !dismissed.find(d => dis.appointment_id === d.appointment_id && dis.client_id === d.client_id)) || [];
    },
    user(){
      return this.$store.state.user;
    },
    appointments(){
      // use this instead of from this.$store to avoid clashing with other request (i.e. from Calendar menu)
      //return this.fetchedAppointments.value;

      let output = [];
      this.fetchedAppointments.forEach(appt=>{
        if(this.listBy === 'own' && appt.user_id !== this.user.user_id) {
          return;
        }
        if(this.listBy?.type =='group' && appt.group_id !== this.listBy?.id){
          return;
        }

        output.push(appt);
      });

      return output;
    },
    places(){
      return this.$store.state.places;
    },
    listByOptions() {
      let output = [
        { value: null, text: 'All' },
        { value: 'own', text: 'My' }
      ];

      let userOwnedGroups = this.$store.state.group_view_all_appointment ? this.user.user_groups_w_details : this.user.user_owned_groups_w_details;
      if(userOwnedGroups && userOwnedGroups.length) {
          let allowedGroups = userOwnedGroups;
          let mainGroup = this.$store.state.group_main_types;
          let supervisorGroup = this.$store.state.group_supervisor_types;
          let allowedGroupTypes = [];

          if(supervisorGroup && supervisorGroup.length > 0) {
            let groups = userOwnedGroups.filter(group=>supervisorGroup.includes(group.group_type) && group.owner);
            if(groups.length) {
              output.push({ value: 'supervisee', text: 'Supervisee' })
            }
          }

          if(mainGroup && mainGroup.length > 0) { allowedGroupTypes.push(...mainGroup); }

          if(allowedGroupTypes.length) {
              allowedGroups = userOwnedGroups.filter(group=>allowedGroupTypes.includes(group.group_type));
          }

          if(allowedGroups.length) {
              output.push({
                  label: "Groups / Teams",
                  options: allowedGroups.map(grp=>{ return {value:{type:"group", id:grp.id},text:grp.group_name} })
              });
          }
      }

      return output;
    },
    upcomingAppointments() {
      let today = new Date();

      let filtered = this.appointments
        .filter(function(a){
          
          return new Date(a.start) >= today;
        });

      // sort by date
      return filtered
        .sort(function compare(a, b) {
          if (a.start < b.start)
            return -1;
          if (a.start > b.start)
            return 1;
          return 0;
        });
    },
    recentAppointments() {
      let filtered = this.appointments.filter(
        function(a){
          let maxDate = dayjs().subtract(1, 'month');
          
          return dayjs(a.start).isBefore(dayjs()) && dayjs(a.start).isAfter(maxDate);
        }
      );

      // sort by date
      let sorted = filtered
        .sort(function compare(a, b) {
          if (a.start < b.start)
            return 1;
          if (a.start > b.start)
            return -1;
          return 0;
        });

      //sorted = sorted.splice(0, 10);

      return sorted;
    }
  },
  async mounted() {
    await Promise.allSettled([
      this.fetchAppointments(),
      this.fetchPlaces()
    ]);

    this.isLoading = false;
  },
  methods: {
    recordDismiss() {
      let dismissed = localStorage.getItem(declinedsKey) || [];

      if (typeof dismissed == "string") {
        dismissed = JSON.parse(dismissed);
      }
        for (let d of this.declineds) {
          if (!dismissed.find(dis => dis.appointment_id === d.appointment_id && dis.client_id === d.client_id))
          dismissed.push(d);
        }

        localStorage.setItem(declinedsKey, JSON.stringify(dismissed));   
    },
    dayjs,
    async refreshAppointments() {
      this.isLoading = true;
      await this.fetchAppointments();
      this.isLoading = false;
    },
    async fetchAppointments() {
      // fetchGroupAppointments - fetch all appointment including groups members appt
      // fetchMyAppointments - fetch all logged in user appointment, exclude groups members appt
      let action = (this.listBy===1) ? "fetchMyAppointments" : "fetchGroupAppointments";

      let params = {
        start: dayjs().subtract(1, 'month').toISOString()
      }
      // list by supervisor group
      if(this.listBy === 'supervisee') {
        params = { ...params, grouptype: 'supervisor' }
      }
      // filter by group
      if(this.listBy?.type == 'group') {
        params = { ...params, group_id: this.listBy.id }
      }

      await this.$store.dispatch(action,params).then((result)=>{
        this.fetchedAppointments = result.value;
      }).catch(()=>{
        // do something
      })
    },
    async fetchPlaces() {
      await this.$store.dispatch('fetchAllPlaces');
    },
    getPlace(roomId) {
      if(!this.places) { return null }
      return this.places.find(room => room.id === roomId);
    }
  }
}
</script>
<style lang="scss">
.hide-item {
  display: none !important;
}

.appointment-box, .appointment-box-loading{
  width: calc(50% - .3em);
  margin: .3em .6em .3em 0;
}

.appointment-box {
  cursor: pointer;
}

.appointment-box:nth-child(2n), .appointment-box-loading:nth-child(2n){
  margin-right: 0;
}

.appointment-box-loading {
  min-height: 8em;
  animation: loading 1s infinite;
}

@keyframes loading {
  0% {background-color: rgb(236, 240, 247);}
  50% {background-color: rgb(224, 229, 238);}
  100% {background-color: rgb(236, 240, 247);}
}

@media only screen and (max-width: 768px) {
  .appointment-box, .appointment-box-loading {
    width: 100%;
    margin: .3em 0;
  }
}
</style>