import axios from 'axios';

const $ = axios.create({
    baseURL:window.location.origin+'/api/'
});

export default {
    state: {
        reminder_template: {},
        reminder_templates: []
    },
    mutations: {
        reminder_template(state, reminder_template) {
            state.reminder_template = reminder_template;
        },
        reminder_templates(state, reminder_templates) {
            state.reminder_templates = reminder_templates;
        }
    },
    actions: {
        async fetchAllReminderTemplate(store) {
            const {data:{result}} = await $.get(`reminder_template`);
            store.commit('reminder_templates',result);
            return result;
        },
        async fetchGroupReminderTemplate(store) {
            const {data:{result}} = await $.get(`reminder_template?group=1`);
            store.commit('reminder_templates',result);
            return result;
        },
        async getReminderTemplate(store, reminderTemplateId) {
            const {data:{result}} = await $.get(`reminder_template/${reminderTemplateId}`);
            store.commit('reminder_template',result);
            return result;
        },
        async addReminderTemplate(store, {payload}) {
            const {data: {result}} = await $.post(`reminder_template`, payload);
            
            return result;
        },
        async patchReminderTemplate(store, { payload, reminder_template_id }){
            const {data:{result}} = await $.patch(`reminder_template/${reminder_template_id}`, payload);
            store.commit('reminder_template', result);
            return result;

        },
        async deleteReminderTemplate(store, reminderTemplateId) {
            const {data:{result}} = await $.delete(`reminder_template/${reminderTemplateId}`);

            return result;
        }

    }
}