<template lang="pug">
    div
        b-card.border-0(v-if="uuidIsInvalid")
            h3 Invalid appointment
            p The appointment may have been deleted. Contact the organiser for more info.
        b-card.border-0(v-else)
            div(v-if="respondSuccessful")
                h3 Success
                p We've received your appointment response. You may now close this window.
            div(v-else)
                h3 Decline Appointment
                p Are you sure you want to decline this appointment invite?
                div
                    b-button.mr-2.mb-2.px-5(@click="response(false)") Yes
                    b-button.mb-2(@click="response(true)" variant="outline-secondary") No, I want to accept the invitation
                    
    
</template>

<script>
export default {
    data() {
        return {
            responseData: {
                uuid: this.$route.query.id,
                declined: false
            },
            uuidIsInvalid: false,
            respondSuccessful: false,
        }
    },
    computed: {
    },
    mounted() {
        // check uuid validity
        this.declineAppointment({ uuid: this.$route.query.id });
    },
    methods: {
        response(response) {
            this.responseData.declined = response ? false : true; 

            this.declineAppointment(this.responseData);
        },
        async declineAppointment(payload) {
            await this.$store.dispatch('declineAppointment', {payload:payload}).then(()=>{
                this.respondSuccessful = true;
            })
            .catch((err)=>{
                
                if(err.response.status == "404") {
                    this.uuidIsInvalid = true;
                    // show 404 page is better
                }
            })
        }
    }
}
</script>

<style>

</style>