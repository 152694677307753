import axios from 'axios';

const $ = axios.create({
    baseURL:window.location.origin+'/api/'
});

export default {
    state: {
        audit_logs: []
    },
    mutations: {
        audit_logs(state, audit_logs) {
            state.audit_logs = audit_logs;
        }
    },
    actions: {
        async fetchAuditLogs(store, payload) {
            const pl = new URLSearchParams(payload);
            const {data:{result}} = await $.get(`audit_log?${pl.toString()}`);
            store.commit('audit_logs',result);
            return result;
        },
    }
}