import axios from 'axios';

const $ = axios.create({
    baseURL:window.location.origin+'/api/'
});

export default {
    state: {
        client: {},
        clients: [],
        client_appointments: [],
        client_client_groups: [],
    },
    mutations: {
        client(state, client) {
            state.client = client;
        },
        clients(state, clients) {
            state.clients = clients;
        },
        client_appointments(state, client_appointments) {
            state.client_appointments = client_appointments;
        },
        client_client_groups(state, client_client_groups) {
            state.client_client_groups = client_client_groups;
        },
    },
    actions: {
        async fetchAllClients(store, payload={}) {
            let queryParams = new URLSearchParams(payload);

            const {data:{result}} = await $.get(`client?${queryParams.toString()}`);
            store.commit('clients',result);
            return result;
        },
        async fetchUserClients(store, payload={}) {
            let queryParams = new URLSearchParams(payload);

            const {data:{result}} = await $.get(`user/client?${queryParams.toString()}`);
            
            store.commit('clients',result);
            return result;
        },
        async getClient(store, clientId) {
            const {data:{result}} = await $.get(`client/${clientId}`);
            store.commit('client',result);
            return result;
        },
        async addClient(store, {payload}) {
            const {data: {result}} = await $.post(`client`, payload);
            
            return result;
        },
        async getClientAppointments(store, clientId) {
            const {data:{result}} = await $.get(`client/${clientId}/appointment`);
            store.commit('client_appointments',result);
            return result;
        },
        async getClientClientGroups(store, clientId) {
            const {data:{result}} = await $.get(`client/${clientId}/client_group`);
            store.commit('client_client_groups',result);
            return result;
        },
        async patchClient(store, { payload, client_id }){
            const {data:{result}} = await $.patch(`client/${client_id}`, payload);
            store.commit('client', result);
            return result;

        },
        async deleteClient(store, clientId) {
            const {data:{result}} = await $.delete(`client/${clientId}`);

            return result;
        },
        async sendBroadcast(store, {payload}) {
            const {data: {result}} = await $.post(`broadcast`, payload);
            return result;
        },

    }
}