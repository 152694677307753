<template lang="pug">
div
    div.d-flex.justify-content-between.align-items-end.flex-wrap.mb-3
        b-button(to="/clients/create")
            b-icon(icon="plus")
            | Add Client

        b-form(inline @submit.prevent)
            b-form.ml-3(inline @submit.prevent)
                label.mr-1 List:
                b-form-select(v-model="listBy" :options="listByOptions" @change="updateTable()" style="max-width:10em")

            b-form.ml-3(inline @submit.prevent)
                label.mr-1(for="status") Status:
                b-form-select(id="status" v-model="isListActive" @change="updateTable()")
                    <option value="">All</option>
                    <option value="active">{{$.active}}</option>
                    <option value="inactive">{{$.inactive}}</option>
            
            b-form.ml-3.border.rounded(inline @submit.prevent="updateTable()")
                //label.mr-1(for="status") Status:
                b-form-select.border-0(v-model="filterby" style="border-radius: .25rem 0 0 .25rem")
                    option(value="name") {{$.clientName}}
                    option(value="contact_number") Contact No.

                b-form-input.rounded-0.border-0(v-if="filterby==='name'" type="text" v-model="filter" placeholder="Search")
                vue-tel-input.rounded-0.border-0(v-if="filterby==='contact_number'" v-model="filter")
                b-button(type="submit" style="border-radius: 0 .25rem .25rem 0")
                    b-icon(icon="search")
    
    div.d-flex.justify-content-center.align-items-center(v-if="loading" style="min-height:10em")
        b-spinner
    div(v-else)
        b-overlay(:show="this.loadingTable" variant="white")
            b-table(:items="fetchedClients" :fields="fields" ref="table" :per-page="perPage" :current-page="currentPage" show-empty)
                template(#cell(name)="data")
                    span {{data.item.name}}
                        b-badge.ml-1(variant="secondary" v-if="data.item.visibility===1") Private
                        b-badge.ml-1(variant="success" v-if="data.item.visibility===2") Public
                        b-badge.ml-1(variant="danger" v-if="data.item.visibility===0") {{$.inactive}}
                template(#cell(client_groups)="data")
                    div(v-for="cg of data.item.client_groups") {{cg.name}}
                template(#cell(actions)="data")
                    b-button(class="mr-2 mb-1" :to="'/clients/'+(data.item.id)") More Info
                    b-button(class="mr-2 mb-1" :to="'/appointments/create?client='+(data.item.id)" variant="outline-secondary")
                        b-icon(icon="plus")
                        | Appointment
                    b-button(v-if="data.item.user_id == user.user_id || (listBy.type==='group' && $store.state.group_member_can_edit_client)" class="mr-2 mb-1" :to="'/clients/'+(data.item.id)+'/edit'" variant="outline-secondary")
                        b-icon.mr-1(icon="pencil-square")
                        | Edit
            
            b-pagination.m-0(
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
            )
</template>

<script>
import dayjs from 'dayjs';

export default {
    name: 'ClientList',
    data() {
        let fields = [
                { key: 'name', name: 'Name', sortable: true },
                { key: 'contact_number', name: 'Contact Number', sortable: true },
                { key: 'owner', name: 'Owner', sortable: false},
                'actions'
            ];
            if(this.$store.state.groups_enabled){
                fields.splice(3,0,{ key: 'group', name: 'Group', sortable: false, formatter:v=>v?.group_name||"-"});
            }
        return {
            loading: false,
            loadingTable: true,
            totalRows: 0,
            currentPage: 1,
            perPage: 20,
            listBy: 1,
            isListActive: 'active',
            filterby: 'name',
            filter: "",
            fields
        }
    },
    computed: {
        user(){
            return this.$store.state.user;
        },
        localisation(){
            return this.$store.state.localisation;
        },
        $(){
            let output ={
                "clientGroup": "Client Group",
                "active": "Active",
                "inactive": "Inactive",
                "clientName": "Name"
            };
                    
            Object.assign(output,this.localisation);
            return output;
        },
        listByOptions() {
            let output = [
                { value: 0, text: 'All' },
                { value: 1, text: 'My' }
            ];

            let userOwnedGroups = this.$store.state.group_view_all_client ? this.user.user_groups_w_details : this.user.user_owned_groups_w_details;
            if(userOwnedGroups && userOwnedGroups.length) {
                let allowedGroups = userOwnedGroups;
                let mainGroup = this.$store.state.group_main_types;
                let supervisorGroup = this.$store.state.group_supervisor_types;
                let allowedGroupTypes = [];

                if(supervisorGroup && supervisorGroup.length > 0) {
                  let groups = userOwnedGroups.filter(group=>supervisorGroup.includes(group.group_type) && group.owner);
                  if(groups.length) {
                    output.push({ value: 2, text: 'Supervisee' })
                  }
                }

                if(mainGroup && mainGroup.length > 0) { allowedGroupTypes.push(...mainGroup); }

                if(allowedGroupTypes.length) {
                    allowedGroups = userOwnedGroups.filter(group=>allowedGroupTypes.includes(group.group_type));
                }

                if(allowedGroups.length) {
                    output.push({
                        label: "Groups / Teams",
                        options: allowedGroups.map(grp=>{ return {value:{type:"group", id:grp.id},text:grp.group_name} })
                    });
                }
            }

            return output;
        },
    },
    created() {
        this.fields.splice(0,0,{key: "name", label: this.$.clientName, sortable:true});
        this.fields.splice(2,0,{ key: 'client_groups', label: this.$.clientGroup, sortable: false});
    },
    mounted() {
    },
    methods: {
        dayjs,
        updateTable() {
            if(this.loading) { return; }
            this.$refs.table.refresh();
            this.currentPage = 1;
        },
        async fetchedClients(ctx) {
            this.loadingTable = true;

            // fetchAllClients - fetch all client including groups members client
            // fetchUserClients - fetch all logged in user client, exclude groups members client
            let action = (this.listBy===1) ? "fetchUserClients" : "fetchAllClients";

            // prepare url params
            let params = {
                top: ctx.perPage,
                skip: ctx.perPage*(ctx.currentPage-1),
                orderby: ctx.sortBy,
                order: ctx.sortDesc ? 'desc' : 'asc',
                status: this.isListActive
            };
            // list by supervisor group
            if(this.listBy === 2) {
              params = { ...params, grouptype: 'supervisor' }
            }
            //if(this.listBy===1) {
            //    params = { ...params, status: this.isListActive }
            //}
            // filter by group
            if(this.listBy?.type == 'group') {
                params = { ...params, group_id: this.listBy.id }
            }
            // search function
            if(this.filterby && this.filter) {
                params = { ...params, filterby: this.filterby, filter: this.filter }
            }

            // request
            let items = await this.$store.dispatch(action,params).then((result)=>{
                this.totalRows = result.count;
                return result.value;
            }).catch(()=>{
                return [];
            });
            this.loadingTable = false;

            return items;
        },
    }
}
</script>

<style>

</style>