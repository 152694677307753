<template lang="pug">
  .home.text-center
    img(alt="Vue logo" src="@/assets/logo.png" style="max-height:100px;")
    HelloWorld(:msg="'Welcome to ' + appName" submsg="app_type")
    b-alert(show v-if="isTestEnv") This is a Test Environment. Do not put real client data.
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomePublic',
  components: {
    HelloWorld
  },
  computed:{
      appName() {
        return process.env.VUE_APP_NAME ? process.env.VUE_APP_NAME : 'Certainty';
      },
      app_type(){
          return process.env.VUE_APP_TYPE;
      },
      isTestEnv() {
        return process.env.VUE_APP_IS_DEV
      },

  }

}
</script>
