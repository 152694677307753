<template lang="pug">
    div
        //Breadcrumb navigation
        div(style="color:grey")
            router-link(:to="{ name: 'Appointments'}") Appointments
            span.mx-1 ›
            span Create New Appointment
        //router-link(to="/appointments") Back to Appointments List
        h2 Create New Appointment
        div.mb-3
            b-checkbox(v-model="hidenonworking" switch) Hide non-working hours


        b-card-group(deck)
            b-card.border-0.rounded(style="max-width: 800px")
                <b-alert variant="danger" :show="isError">{{errorMessage}}</b-alert>

                AppointmentForm(@appointment="createAppointment($event)" :submitting="submitting" @dateset="dateset" @startschedule="setstartschedule" :hidenonworking="hidenonworking" ref="apptform" @tempevent="setTempEvent")
            b-card.rounded.hide-narrow
                p.lead.text-center {{displayDate}}
                vue-cal(
                    :events="events" 
                    :selectedDate="selectedDay" 
                    active-view="day" 
                    :disable-views="['years', 'year','month', 'week']" 
                    disable-date-prototypes 
                    hide-title-bar 
                    hide-view-selector 
                    :dblclick-to-navigate="false"
                    :timeFrom="timebounds.start" 
                    :timeTo="timebounds.end" 
                    :time-cell-height="hidenonworking?80:40"
                )
</template>

<script>
import AppointmentForm from '@/components/appointments/form.vue';
import dayjs from 'dayjs';
export default {
    name: 'NewAppointment',
    data() {
        return {
            submitting: false,
            isError: false,
            errorMessage: "",
            selectedDay:"",
            startschedule:[],
            hidenonworking: true,
            tempEvent:null
        };
    },
    components: {
        AppointmentForm
    },
    methods: {
        setstartschedule(v){
            this.startschedule = v;
        },
        setTempEvent(v){
            this.tempEvent = v;
        },
        dateset(v){
            this.selectedDay = v;
        },
        async createAppointment(appointment) {
            this.submitting = true;
            await this.$store.dispatch('createAppointment',{payload: appointment}).then((result)=>{
                window.location.href = `/appointments/${result.id}`;
            }).catch((err)=>{
                this.isError = true;
                this.errorMessage = err;
            })
            this.submitting = false;

        }
    },
    computed:{
        displayDate(){
            return dayjs(this.selectedDay).format("dddd MMMM Do, YYYY")
        },
        events(){
            let output = [];
            this.startschedule.forEach(ev => {
                output.push({
                    title:ev.subject,
                    content: `<p>Status: ${ev.status}</p><p>${ev.who}</p>`,
                    start:dayjs(ev.start.dateTime).tz("Asia/Singapore",true).format("YYYY-MM-DD HH:mm"),
                    end:dayjs(ev.end.dateTime).tz("Asia/Singapore",true).format("YYYY-MM-DD HH:mm"),
                    class:"bg-cal-split1 text-dark font-weight-bold rounded border border-light"

                })
            });
            if(this.tempEvent){
                 output.push({
                    title:this.tempEvent.subject,
                    start:dayjs(this.tempEvent.start).tz("Asia/Singapore",true).format("YYYY-MM-DD HH:mm"),
                    end:dayjs(this.tempEvent.end).tz("Asia/Singapore",true).format("YYYY-MM-DD HH:mm"),
                    class:"bg-dark text-light font-weight-bold rounded border border-light"

                })
            }
            return output;
        },
        timebounds(){
            return !this.hidenonworking ? {start:0, end:24*60}:{start:8*60, end:22*60}
        },
    }

  
}
</script>

<style>
    .card.hide-narrow{
        max-width: 500px;
    }
    @media (max-width:1200px) {
        .card.hide-narrow{
            display:none;
        }
 
    }
</style>