import { render, staticRenderFns } from "./:appointment_id.vue?vue&type=template&id=769af7a0&lang=pug&"
import script from "./:appointment_id.vue?vue&type=script&lang=js&"
export * from "./:appointment_id.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports