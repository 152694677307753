
/**
 * Automatic loader for EVERYTHING (vue files) in the public view folder
 * Home.vue defaults to '/'
 * Public routes do not have a sidebar navigation
 * 
 */
 import Base from '@/components/base/base.vue';

 const context = require.context('../views/PublicRoutes', true, /\.(vue)$/);
 const files={};
 
 context.keys().forEach((filename)=>{
   files[filename] = context(filename);
 });
 
 const children = [];
 
 for(let fn in files){
     let filename = fn.substring(2,fn.length - 4);
     let path
     switch(filename){
         case 'Home':
             path = '/'
             break;
         default:
             path = '/'+filename.toLowerCase()
         
     }
 
     children.push({
         path,
         name:files[fn].default.name||filename,
         component: files[fn].default,
         meta: { 
            public: true 
        }
     })
 }
 






export default [
    {
        path: '/public',
        name: 'publicView',
        component:Base,
        props: {
            hideSidebar: true
        },
        meta: { 
            public: true 
        },
        children
    }
];