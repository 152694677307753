<template lang="pug">
  #app(:class="{notfound}")
    loader(v-if="!loaded")
    div(v-else)
      router-view(v-if="isPublic")
      baseTemplate(v-else-if="login")
      login(v-else)
</template>
<script>
import login from '@/components/login/login.vue';
import baseTemplate from '@/components/base/base.vue';


export default{
  name:"CertaintyBase",
  components:{
   login,baseTemplate
  },
  mounted(){
    this.$store.dispatch('init');
  },
  computed:{
    notfound(){
      return this.$store.state.notfound;
    },
    isPublic(){
      return this.$route?.meta?.public
    }
  }

}

</script>
<style lang="scss">
  #app.notfound{
    filter:invert(1);
  }
  #app{
    transition: filter 1s ease;
  }

</style>


