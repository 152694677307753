<template lang="pug">
  .about
    //Breadcrumb navigation
    div(style="color:grey")
      router-link(to="/clients") Clients
      span.mx-1 ›
      span {{client.name}}
    //router-link(to="/clients") Back to Clients List
    div.d-flex.justify-content-between.align-items-center.flex-wrap
      h2 {{ client.name }}

      router-link(v-if="client.user_id == user.user_id || (userGroups.includes(client.group_id) && $store.state.group_member_can_edit_client)" :to="{ name: 'EditClient', params: { client_id: $route.params.client_id } }" tag="b-button")
        b-icon.mr-2(icon="pencil-square")
        | Edit Client

    b-card.border-0
      b-overlay(:show="loading" variant="white" opacity="1")
        div.mb-4
          h5
            b-icon.mr-2(icon="telephone" title="Contact Number")
            | {{ client.contact_number }}
          div(v-if="$store.state.enable_client_nickname && client.nickname")
            b-icon.mr-2(icon="person" title="Nickname")
            | {{ client.nickname }}
          div(v-if="$store.state.groups_enabled")
            b-icon.mr-2(icon="people" title="Group")
            | {{ client.group ? client.group.group_name : '-' }}

        b-tabs()
          <!-- Appointments tab -->
          b-tab(title="Appointments" active)
            div
              <b-button class="my-2" :to="'/appointments/create?client='+$route.params.client_id">
                b-icon(icon="plus")
                | New Appointment
              </b-button>
            b-overlay(:show="loadingAppointment" variant="white" opacity="1")
              div(v-if="client_appointments.length > 0")
                div.d-flex.flex-wrap.w-100
                  router-link.bg-light.border-0.mr-2.mb-2.w-100(v-for="appointment in client_appointments" :key="appointment.id" :to="{ name: 'SingleAppointment', params: { appointment_id: appointment.id }}" tag="b-card" style="cursor:pointer")
                    div.d-flex.flex-column
                      b {{appointment.subject}}
                      span
                        b-icon.mr-2(icon="calendar" title="Date")
                        | {{ dayjs(appointment.start).format('DD/MM/YYYY') }}
                      span
                        b-icon.mr-2(icon="clock" title="Time")
                        | {{ dayjs(appointment.start).format('h:mm A') }} to 
                        <span v-if="dayjs(appointment.start).isSame(appointment.end, 'day')">{{ dayjs(appointment.end).format('h:mm A') }}</span>
                        <span v-else>{{ dayjs(appointment.end).format('DD/MM/YYYY [at] h:mm A') }}</span>
                      span(v-if="appointment.location")
                        b-icon.mr-2(icon="geo-alt" title="Location")
                        | {{appointment.location}}
                      span.mt-3(v-if="appointment.description")
                        | {{appointment.description}}
              div.p-2(v-else)
                p This client has no appointment booked

          <!-- Client Group tab -->
          b-tab(:title="$.clientGroup")
            b-overlay(:show="loadingClientGroup" variant="white" opacity="1")
              div(v-if="client_client_groups.length > 0")
                b-table.mt-2(:items="client_client_groups" :fields="fields" stacked='md' :borderless="true")
                    template(#cell(date_created)='data')
                        | {{ dayjs(data.item.date_created).format('DD/MM/YYYY') }}
                    template(#cell(actions)="data")
                        b-button(:to="'/client-group/'+(data.item.id)") More Info
              div.p-2(v-else)
                p This client is not in any group
      
      
</template>
<script>
import dayjs from 'dayjs';

export default {
  name: 'SingleClient',
  data() {
    return {
      loading: true,
      loadingAppointment: true,
      loadingClientGroup: true,
      fields:[
        { key: 'name', name: 'Name', sortable: true },
        { key: 'date_created', name: 'Date Created', sortable: true },
        'actions'
      ]
    }
  },
  computed: {
    client(){
      return this.$store.state.client;
    },
    client_appointments(){
      return this.$store.state.client_appointments;
    },
    client_client_groups(){
      return this.$store.state.client_client_groups;
    },
    localisation(){
      return this.$store.state.localisation;
    },
    $(){
      let output ={
        "clientGroup": "Client Group",
      };
              
      Object.assign(output,this.localisation);
      return output;
    },
    userGroups() {
      if(this.user.user_groups_w_details && this.user.user_groups_w_details.length) {
          let groups = this.user.user_groups_w_details;
          if(this.$store.state.group_main_types && this.$store.state.group_main_types.length) {
            groups = this.user.user_groups_w_details.filter(grp=>this.$store.state.group_main_types.includes(grp.group_type));
          }
          return groups.map(grp=>grp.id);
      }
      return [];
    }
  },
  async mounted() {
    await Promise.allSettled([
      this.getClient(),
      this.getClientAppointments(),
      this.getClientClientGroups()
    ]);
  },
  methods: {
    dayjs,
    async getClient() {
      this.loading = true;
      await this.$store.dispatch('getClient',this.$route.params.client_id)
      .catch((err)=>{
        if(err.response.status == 404) {

          console.log(err);

          // todo: display error 404

        }
      });
      this.loading = false;
    },
    async getClientAppointments() {
      this.loadingAppointment = true;
      await this.$store.dispatch('getClientAppointments',this.$route.params.client_id).catch(err => {
        console.log(err);
      });
      this.loadingAppointment = false;
    },
    async getClientClientGroups() {
      this.loadingClientGroup = true;
      await this.$store.dispatch('getClientClientGroups',this.$route.params.client_id);
      this.loadingClientGroup = false;
    },
  }
}
</script>

<style>

</style>