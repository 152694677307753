<template lang="pug">
  .home.text-center
    img(alt="Vue logo" src="@/assets/logo.png" style="max-height:100px;")
    HelloWorld(:msg="app_type" :submsg="welcome")
    b-card 
      p To begin, click on the tabs on the left sidebar. 
      p You may set appointments, define clients and adjust reminder alerts. 
    b-alert(show v-if="isTestEnv" variant="warning") This is a Test Environment. Do not put real client data.
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  computed:{
      appName() {
        return process.env.VUE_APP_NAME ? process.env.VUE_APP_NAME : 'Certainty';
      },
      app_type(){
          return this.appName + " " + process.env.VUE_APP_TYPE;
      },
      welcome(){
        return process.env.VUE_APP_AGENCY||"Welcome";
      },
      isTestEnv() {
        return process.env.VUE_APP_IS_DEV
      },
      

  }
}
</script>
