<template lang="pug">
    b-form(@submit.prevent="submitData")
        b-form-group(label="Task Name/Short Description")
            b-input(v-model="title" required)
        b-form-group(label="Due Date" )
            b-input(type="date" v-model="rawdue" required)
        hr
        b-button(type="submit") Save
</template>
<script>
import dayjs from "dayjs";
export default {
    name:"assign_task",
    data(){
        return {
            title:"",
            rawdue:""
        }
    },
    methods:{
        submitData(){
            let {title,due} = this;
            this.$emit("add_task",{title,due,dateCompleted:null});
        }
    },
    computed:{
        due:{
            get(){
                return dayjs(this.rawdue);
            }
        }
    }
}
</script>