<template lang="pug">
    b-form(@submit.prevent="submitData")
        h5 Current ACP Date: {{current_acp.format("DD-MMM-YYYY")}}
        b-form-group(label="ACP Date" )
            b-input(type="date" v-model="rawdate" required :max='today')
        hr
        b-button(type="submit") Save
</template>
<script>
import dayjs from "dayjs";
export default {
    name:"update_acp",
    data(){
        return {
            rawdate:"",
            today:dayjs().format("YYYY-MM-DD")
        }
    },
    props:["current_acp"],
    methods:{
        submitData(){
            this.$emit("update_acp",this.date);
        }
    },
    computed:{
        date:{
            get(){
                return dayjs(this.rawdate);
            }
        }
    }
}
</script>